import React, {useEffect, useState} from 'react';
import base64 from 'base-64';
import ReactDOM from 'react-dom';
import {connect, Provider, useDispatch, useSelector} from 'react-redux';
import {Link, RouteComponentProps, Router, withRouter} from 'react-router-dom';
import {propOr} from 'ramda';
import * as color from 'color';
import {ToastContainer} from 'react-toastify';

import {history, runWatchers, store} from './stores/store';
import {userBilling} from './stores/reducers/billing';
import ModalHeader from './components/menus/modal/modal-header';
import {GmapPreview, GmapComponent} from './components/gmaps';
import {clientDb, fbapp} from './shared/firebase';
import {getAcl, getSubUsers} from './shared/db/people-db';
import {IconChooser} from './components/modals/icon-chooser';
import {localStorage} from './shared/storage';
import Layout from './components/pages/header-only-layout';
import DashboardBlock from './components/DashboardBlock';
import {Fa} from './components/elements/fa';
import MinimalTile from './components/general/MinimalTile';
import {clientDefaultSettings, isMobile} from './shared/helpers';
import {Row} from './components/elements/flex';
import {AuthAC} from './stores/reducers/auth-reducer';
import {fullCloseDashboard} from './stores/reducers/dashboardInfo/AC';
import {useConnected} from './stores/reducers/general-reducers';
import {useRedux} from './states/redux-state';
import {StandardDesktopLayout} from './standard-desktop-layout';
import {StandardMobileLayout2} from './standard-mobile-layout';
import LoginMobile from './components/pages/Login/login-mobile';
import {useSelectIcon} from './shared/hooks/useSelectIcon';
import {selectIconContext} from './shared/contexts/selectIcon.context';
import Portal from './components/Portal';
import DefaultSettingsModal from './components/DefaultSetingsModal/DefaultSettingsModal'
import Login from './components/pages/Login/login';
import {ACL, defaultSettingsTemplate, UserCan} from './shared/constants';
import {AclFb, DefaultAclFb, iDefaultSettings, iFullStoreState, iPerson, UserAuth} from './shared/interfaces';
import {getDefaultAcl} from './shared/db/users-access';
import {presetDefaultDeviceSettings} from './shared/db/devices-db';
import instance from './api/instance';
import SessionTimeout from './BemComponents/SessionTimeout';
import {authenticateWithCustomToken, setDefaultValueFromEnv} from './index.helpers';

import './assets/styles/fonts.scss';
import './index.scss';
import {faClock} from '@fortawesome/fontawesome-free-regular';
import {faHome} from '@fortawesome/fontawesome-free-solid';
import 'react-toastify/dist/ReactToastify.css';


let loggedTimeToRnder = false;
const App = () => {
    const IS_PUBLIC_VIEW = window.location.href.includes('public-view');
    const [isConnected] = useConnected();
    const auth = useRedux((s) => s.auth);
    const fullyInitialized = useRedux((s) => s.general.initComplete);
    const dispatch = useDispatch();
    const search = history.location.search;
    const params = new URLSearchParams(search);
    const token = params.get('token');
    const authToken = params.get('authToken');
    const tokenType = params.get('token_type');
    const accessToken = params.get('access_token');
    const refreshToken = params.get('refresh_token');
    const allValuesFromUrl = {
        token_type: tokenType,
        access_token: accessToken,
        refresh_token: refreshToken,
        path: history.location.pathname
    }

    const mapApiToken = useSelector<iFullStoreState, string>(state => state.auth.mapApiToken);
    const person = useSelector<iFullStoreState, iPerson>(state => state.general.people[state.auth.user?.uid]);
    const url = window.location;

    const showResetPasswordPage = url.href.indexOf('app-reset-password') !== -1;

    if (!loggedTimeToRnder)
        console.log('time to render:', (Date.now() - window['startTime']));

    loggedTimeToRnder = true;

    useEffect(() => {
        if (authToken) {
            authenticateWithCustomToken({ authToken }).then(() => {
                const { token_type, access_token, refresh_token, path } = allValuesFromUrl;
                dispatch(userBilling(allValuesFromUrl));
                dispatch(AuthAC.setFbToken(access_token));
                dispatch(AuthAC.setMapApiToken({ mapApiToken: access_token }));
                history.replace(path);
            }).catch(() => {
                history.replace(history.location.pathname);
            });
        }
        if (IS_PUBLIC_VIEW) {
            const publicToken = params.get('token');
            const firebaseId = params.get('tag');
            // active-client
            instance.post(`${process.env.REACT_APP_API_URL}/api/tag/verify-share-link?firebaseId=${firebaseId}&token=${publicToken}`)
            .then(({ data }) => {
                if (isMobile) dispatch(fullCloseDashboard());
                dispatch(AuthAC.setFbToken(data?.access_token));
                dispatch(AuthAC.setMapApiToken({ mapApiToken: data?.access_token }));

                localStorage.forget('login-init-be-token');
                localStorage.forget('login-init-user');
                localStorage.forget('cached-user');
                localStorage.forget('active-client');
                localStorage.forget('lastTimeStamp');

                history.push(`/public-view/${firebaseId}/?client=${data?.client_id}`);
            })
            .catch((err) => {
                console.error(err);
            });
        }
    }, [authToken, IS_PUBLIC_VIEW])


    useEffect(() => {
        setDefaultValueFromEnv();
    }, []);

    useEffect(() => {
        if (auth.fbToken) {
            fbapp.auth().signInWithCustomToken(auth.fbToken);
        }
    }, [auth.fbToken]);

    useEffect(() => {
        if (IS_PUBLIC_VIEW) return;
        const device =

            (window as any).Beacon('identify', {
                isLogged: person ? true : false,
                mapApiToken: mapApiToken,

                name: person?.displayName ?? '',
                email: person?.email ?? '',
                firstName: auth.user?.firstName ?? '',
                lastName: auth.user?.lastName ?? '',
                company: auth.user?.company ?? '',
                organizationId: auth.user?.organizationId ?? ''
            });
    }, [person, mapApiToken])

    // todo create separate routes for login, sign up , reset/forgot password

    if (isMobile) {
        if (!auth.user && !auth.fbToken) {
            if(!window['cordova'] && !showResetPasswordPage) url.replace(`${url.origin}/#/`);
            return <LoginMobile token={IS_PUBLIC_VIEW ? null : token} />;
        }
    } else if (!isMobile) {
        if (!auth.user && !auth.fbToken) {
            if(!window['cordova'] && !showResetPasswordPage) url.replace(`${url.origin}/#/`);
            return <Login token={IS_PUBLIC_VIEW ? null : token} />;
        }
    } else {
        if (!auth.user) {
            if(!window['cordova'] && !showResetPasswordPage) url.replace(`${url.origin}/#/`);
            return <NotLoggedInModal />;
        }
    }

    if (!fullyInitialized || !isConnected || !auth.user) return (
        <div className="height-desktop-hundred">
            <Router history={history}>
                <Layout itemId={null} itemType={null}>
                    {/* <PanHelper />*/}
                    {/*<GMaps />*/}
                    <GmapPreview />
                    <div className="height-desktop-hundred" style={{ flex: 1, display: 'flex', flexDirection: 'column', paddingTop: '10%', backgroundColor: color('black').fade(.3), zIndex: 100 }}>
                        <div className="height-desktop-hundred" style={{ display: 'flex', justifyContent: 'center', color: '#fff', fontSize: 40, }}>
                            <div className="height-desktop-hundred" style={{ textAlign: 'center' }}>
                                <div><Fa icon={faClock} style={{ fontSize: 70, fontWeight: 100 }} /></div>
                                <div>Connecting</div>
                            </div>
                        </div>
                    </div>
                </Layout>
            </Router>
        </div>
    );

    return <AuthLayout />;
};

const AuthLayout = () => {
    const IS_PUBLIC_VIEW = window.location.href.includes('public-view');
    const selectIconFunctional = useSelectIcon();
    const user = useSelector<iFullStoreState, UserAuth>(s => s.auth.user);
    const can = user.acl.can;
    const superAdmin = ACL.check(UserCan.DO_ANYTHING, can);

    return (
        <Router history={history}>
            <selectIconContext.Provider value={selectIconFunctional}>
                {(!IS_PUBLIC_VIEW && superAdmin) && <DefaultSettings />}
                <IconChooser setSelectIcon={selectIconFunctional.setSelectIcon} />
                <GeneralErrorPage />
            </selectIconContext.Provider>
            {!IS_PUBLIC_VIEW && <SessionTimeout />}
        </Router>
    );
};

const DefaultSettings = () => {
    const [isNeedDefSettings, setIsNeedDefSettings] = useState(false)
    const [isCheckedDefSettings, setIsCheckedDefSettings] = useState(false)
    const [loadedSettings, setLoadedSettings] = useState<iDefaultSettings>()


    const checkDefaultSettings = (settings) => {
        const settingsCount = Object.values(settings).length
        const neededSettingsCount = Object.values(defaultSettingsTemplate).length
        if (settingsCount < neededSettingsCount) {
            setLoadedSettings(settings)
            setIsNeedDefSettings(true)
        }

        setIsCheckedDefSettings(true)
    }

    useEffect(() => {
        !isCheckedDefSettings && clientDefaultSettings(checkDefaultSettings)
    }, [])

    useEffect(() => {
        async function presetDefault() {
            await presetDefaultDeviceSettings();
        }
        isCheckedDefSettings && presetDefault();
    }, [isCheckedDefSettings])

    return (
        isNeedDefSettings && <DefaultSettingsModal
            isOpenModal={() => setIsNeedDefSettings(false)}
            loadedSettings={loadedSettings}
        />
    );


}

const GeneralErrorPage = withRouter(connect((s, { location: { key: locationKey } }: RouteComponentProps) => ({ locationKey }))
    (class extends React.Component<{ locationKey?}, any> {
        state = { error: false }

        static getDerivedStateFromProps(props, state) {
            return {
                error: propOr(false, 'location', props) === propOr(false, 'locationKey', state)
            };
        }

        componentDidCatch(error: Error, info) {

            console.groupCollapsed(error.message);
            console.log(error);
            console.log(info.componentStack);
            console.groupEnd();

            this.setState({ error: info.componentStack.split('\n')[2].split(' (')[0] } as any);
        }

        _renderError = () => (
            <DashboardBlock>
                <ModalHeader title="Problem encountered" />
                <MinimalTile style={{ maxWidth: 350 }}>
                    <div>
                        Unfortunately we have encountered an error. If you continue to have trouble please contact support.
                    </div>
                    <Row justify="center">
                        <Link className="btn btn-primary" to="/dashboard"><Fa icon={faHome} /> Home</Link>
                    </Row>
                </MinimalTile>
            </DashboardBlock>
        )

        render() {
            if (this.state && this.state.error) return this._renderError();

            return isMobile ? <StandardMobileLayout2 /> : <StandardDesktopLayout />;
        }
    }));

export const NotLoggedInModal = () => <>
    <Router history={history}>
        <GmapComponent />
    </Router>
    <Portal>
        <div className="modal-overlay">
            <div className="modal-window" tabIndex={-1}>
                <div className="modal-header">
                    <div className="modal-title">Not Authenticated</div>
                </div>
                <div className="modal-body">You are current not logged in</div>
            </div>
        </div>
    </Portal>
</>;

const extractBe = (t: string) => JSON.parse(base64.decode(t.split('.')[1]));

function deserializeAcl(uid: string, acl: AclFb, defaultAcl: DefaultAclFb): UserCan[] {
    const allowed = [];
    Object.entries(acl).forEach(([permission, users]) => {
        const isAllowed = users[uid] === true;
        const isDenied = users[uid] === false;
        const isDefaultPermission = defaultAcl[permission];
        if (isDefaultPermission && !isDenied) {
            allowed.push(permission);
        } else if (isAllowed) {
            allowed.push(permission);
        }
    });
    return allowed;
}

const defaultFirebaseObserver = async (user) => {
    if (!user) {
        store.dispatch({ type: 'USER_LOGOUT' });
        localStorage.forget('login-init-be-token');
        localStorage.forget('login-init-user');
        localStorage.forget('cached-user');
        localStorage.forget('active-client');
        localStorage.forget('lastTimeStamp');

        return;
    }

    const userFromHttp = localStorage.get('app-login');

    const loginUserData = {
        uid: user.uid,
        photoURL: user.photoURL,
        displayName: user.displayName,
        email: user.email,
        firstName: userFromHttp?.first_name,
        lastName: userFromHttp?.last_name,
        company: userFromHttp?.company,
        organizationId: userFromHttp?.organization_id,
    };

    localStorage.set('login-init-user', loginUserData);

    if (user && !localStorage.get('login-init-be-token')) {
        const rawToken = await fbapp.auth().currentUser.getIdToken(true);
        const beToken = await extractBe(rawToken);

        localStorage.set('login-init-be-token', beToken);
    }

    const cached = {
        beToken: localStorage.get('login-init-be-token'),
        user: localStorage.get('login-init-user'),
    };

    localStorage.set('active-client', cached.beToken.clientId);

    const userFirebaseLogoutTime = (await clientDb().child(`/people/people-details/${cached.user.uid}/logoutTime`).once('value')).val();
    const logoutTime = userFirebaseLogoutTime || 3600000;

    const acl = await getAcl(cached.beToken.clientId);

    const defaultAcl = await getDefaultAcl();
    const desAcl = deserializeAcl(user.uid, acl, defaultAcl);
    cached.user.acl = {
        can: desAcl
    };

    const isSubAdmin = desAcl.includes(UserCan.SUB_ADMIN);
    cached.beToken[UserCan.SUB_ADMIN] = isSubAdmin;
    localStorage.set('login-init-be-token', cached.beToken);

    const subUsers = cached.beToken[UserCan.SUB_ADMIN] ? await getSubUsers(cached.beToken.clientId, user.uid) : [];
    if(subUsers.length){
        loginUserData['subUsers'] = subUsers;
        localStorage.set('login-init-user', loginUserData);
    }

    store.dispatch({ type: 'USER_SET', details: cached.user });
    store.dispatch({ type: 'SET_BE_TOKEN', token: cached.beToken });
    store.dispatch({ type: 'SET_IS_DRIVER' });
    store.dispatch({ type: 'SET_LOGOUT_TIME', payload: { logoutTime: logoutTime } });

    runWatchers();
};

const publicViewFirebaseObserver = async (user) => {
    if (!user) {
        store.dispatch({ type: 'USER_LOGOUT' });
        localStorage.forget('login-init-be-token');
        localStorage.forget('login-init-user');
        localStorage.forget('cached-user');
        localStorage.forget('active-client');
        localStorage.forget('lastTimeStamp');

        return;
    }

    const rawToken = await fbapp.auth()?.currentUser?.getIdToken(true);
    const beToken = await extractBe(rawToken);
    const acl = await getAcl(beToken.clientId);
    const defaultAcl = await getDefaultAcl();
    const desAcl = deserializeAcl(user.uid, acl, defaultAcl);

    store.dispatch({ type: 'USER_SET', details: { acl: { can: desAcl }, uid: user.uid } });
    store.dispatch({ type: 'SET_BE_TOKEN', token: beToken });
    store.dispatch({ type: 'SET_IS_DRIVER' });

    runWatchers();
};

const firebaseObserver = (user) => {
    const IS_PUBLIC_VIEW = window.location.href.includes('public-view');

    if (!IS_PUBLIC_VIEW) defaultFirebaseObserver(user);
    else publicViewFirebaseObserver(user);
};

// update the user after official auth
fbapp.auth().onAuthStateChanged(async (user) => {
    // this is some loginc related to logut
   firebaseObserver(user);
});

const render = () =>
    ReactDOM.render(
        <Provider store={store}>
            <ToastContainer
                position="bottom-center"
                autoClose={4000}
                theme="colored"
            />
            <App />
        </Provider>,
        document.getElementById("app")
    );

const intId = setInterval(async () => {
    if (!document.getElementById('app')) return;
    clearInterval(intId);

    if (window['cordova']) {
        try {
            // await fbapp.auth().signOut()
            // localStorage.forget('login-init-be-token');
            // localStorage.forget('login-init-user');
            // localStorage.forget('cached-user');
            // localStorage.forget('active-client');
            // await logoutAPI();
        } catch (e) {
            console.log('logout error');
        }
        render();
    } else {
        render();
    }
}, 10);
