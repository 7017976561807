import React, { CSSProperties as css } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import Select from 'react-select';
import type { Props as ReactSelectProps } from 'react-select';
import { faArrowLeft, IconDefinition, faQuestionCircle } from '@fortawesome/fontawesome-free-solid';

import Constants from '../../../shared/constants';
import { BaseComponent } from '../../../shared/BaseComponent';
import { Fa } from '../../elements/fa';
import MinimalTile from '../../general/MinimalTile';

interface IHeaderAction {
    fa: IconDefinition,
    click: () => void,
    title: string,
    disabled?: string|false,
    faPdf?: IconDefinition,
    clickPdf?: () => void,
    titlePdf?: string,
    isDisabled?: boolean,
}

interface iProps {
    action?: IHeaderAction,
    help?: string,
    title: string|JSX.Element,
    style?: React.CSSProperties,
    role?: boolean,
    goBackCallback?: () => void;
    showSelect?: boolean;
    options?: ReactSelectProps,
    handleChange?: (option: ReactSelectProps) => void,
    option?: ReactSelectProps,
    selectDescription?: string;
    className?: string;
}

type GoBackProps = {
    children: React.ReactNode;
    goBackCallback?: () => void;
} & RouteComponentProps;

const GoBack = withRouter(({history, children, goBackCallback}: GoBackProps) => {
    const goBackHandler = () => {
        if (goBackCallback) {
            goBackCallback();
        }

        history.goBack();
    }

    return (
        <div onClick={goBackHandler}>
        {children}
    </div>
    )
});

class ModalHeaderComponent extends BaseComponent<iProps, {showHelp: boolean}> {
    state = {showHelp: false};

    debugRender = () => {
        const { action, help, style: userStyle = {}, role, goBackCallback, showSelect, options, handleChange, option, selectDescription, className } = this.props;
        const { showHelp } = this.state;

        return (
            <>
                <div className="no-print" style={{...style, ...userStyle}}>
                    <GoBack goBackCallback={goBackCallback}>
                        <span className="no-print btn-link" style={{paddingLeft: 10, marginRight: 20}}><Fa icon={faArrowLeft} /></span>
                    </GoBack>
                    <h3 style={{margin: 0, marginRight: 20, whiteSpace: 'nowrap'}}>{this.props.title}</h3>
                    {
                        !role && (
                            <div>
                                {!action
                                    ? null
                                    : ActionDisplay(action)
                                }
                                {
                                    !help
                                        ? null
                                        : <Fa icon={faQuestionCircle}
                                                style={{cursor: 'pointer'}}
                                                className="modal-help"
                                                title="Show instructions"
                                                onClick={() => this.setState(s => ({showHelp: !s.showHelp}))}
                                        />
                                }
                            </div>
                        )
                    }
                </div>
                {showSelect && (
                    <div style={{ marginBottom: 8 }}>
                        {selectDescription && (
                            <p style={{ margin: 0 }}>{selectDescription}</p>
                        )}
                        <Select
                            onChange={handleChange}
                            options={options}
                            menuPosition={'fixed'}
                            value={option}
                            className={className}
                        />
                    </div>
                )}
                <div className={`help-body no-print ${showHelp ? 'active' : ''}`}>
                    {this.state.showHelp &&
                        <MinimalTile>
                            <div style={{maxWidth: 325}}>{help}</div>
                        </MinimalTile>
                    }
                </div>
            </>
        );
    }
}

export default ModalHeaderComponent;

const style: css = {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 10,
    color: Constants.primaryText,
    minHeight: 33
}

const ActionDisplay = ({fa, click, title='', disabled = false, faPdf, clickPdf, titlePdf='', isDisabled}: IHeaderAction) => (
    <>
        {faPdf && <span title={disabled || titlePdf} className="btn-link" onClick={disabled ? () => {} : clickPdf} style={{fontSize: 20, color: disabled ? Constants.lightGray : "red", marginRight: 10}}>
            <Fa icon={faPdf} title={titlePdf}/>
        </span>}
        {(
            <span title={disabled || title} className="btn-link" onClick={disabled ? () => {} : click} style={{fontSize: 20, color: disabled ? Constants.lightGray : 'inherit' }}>
                <Fa style={isDisabled? { color: "white"} : {}} icon={fa} title={title} />
            </span>
        )}
    </>
);
