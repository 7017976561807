import React, {useEffect, useState} from 'react';
import {connect, useDispatch} from 'react-redux';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {useHistory} from 'react-router-dom';
import {path, pipe} from 'ramda';

import {iFullStoreState} from '../../../../shared/interfaces';
import {FilterType, IPropsFromStore} from './ReportPageFilters.interfaces';
import {
  alertsSelector,
  deviceSelector,
  labelsSelector,
  personSelector,
  tagsSelector,
  typeSelector
} from '../ReportSelectors';
import {Actions as ReportActions} from '../../../../stores/reducers/report-reducers';
import {ACL, UserCan} from '../../../../shared/constants';
import {localStorage} from '../../../../shared/storage';
import {ReportPageFilterSection} from '../ReportPageFilterSection';
import {isMobile, lEmpty} from '../../../../shared/helpers';
import {Collapsible} from '../../../general/Collapsible';
import {DaysChooser, RangeDateChooser} from '../DateChoosers';
import {GMapModalAC} from '../../../../stores/reducers/gMapModal/AC';
import {Button, ButtonSize, ButtonStyles} from '../../../Button';
import {ReactComponent as BookmarkSvg} from '../../../../assets/svg/bookmarks.svg';
import {ReactComponent as CalendarToday} from '../../../../assets/svg/calendar_today.svg';
import {ReactComponent as DevicesSvg} from '../../../../assets/svg/devices.svg';
import {ReactComponent as LayerSvg} from '../../../../assets/svg/layers.svg';
import {ReactComponent as PeopleSvg} from '../../../../assets/svg/people.svg';
import {ReactComponent as WarningAmberSvg} from '../../../../assets/svg/warning_amber.svg';

export const checkingSelectedFiltersCount = ({currentFilters}) => {
  const filtersItemCount = Object.entries(currentFilters).reduce((acc, [key, value]) => ({
    ...acc,
    [key]: Object.keys(value).length
  }), {});

  return filtersItemCount
}

const mapStateToProps = (state: iFullStoreState): IPropsFromStore => {
  const reportTypeRedux = typeof state.report.details.reportTypeFromRedux == 'undefined' ? 'travel' : state.report.details.reportTypeFromRedux
  return ({
    visDevices: deviceSelector(state),
    visTypes: typeSelector(state),
    visPeople: personSelector(state),
    visTags: tagsSelector(state),
    visAlerts: alertsSelector(state),
    visLabels: labelsSelector(state),
    dates: state.report.details.dates,
    reportKey: state.report.details.searchKey,
    dateError: state.report.dateError,
    labels: state.general.tripLabels,
    devicesDetails: state.devicesData.devicesDetails,
    people: state.general.people,
    tags: state.general.tags,
    filterStrs: state.report.filterFilters,
    currentFilters: state.report.details.filters,
    userCan: state.auth.user.acl.can,
    reportTypeFromRedux: reportTypeRedux,
  })
};

const ReportPageFilter = (props) => {
  const [multiDate, setMultiDate] = useState<boolean>(props.dates.length !== 1);
  const [deviceFilter, setDeviceFilter] = useState<string>('');
  const [startKey, setStartKey] = useState<string>(props.reportKey);
  const [startOfDateChange, setStartOfDateChange] = useState<moment.Moment>();
  const [endOfDateChange, setEndOfDateChange] = useState<moment.Moment>();

  const countStartEndDateSafe = () => {
    const {dates} = props;

    if (dates.length === 0) return null;

    let startDate, endDate;

    if (multiDate) {
      startDate = moment.min(dates.map(d => d.startDate));
      endDate = moment.max(dates.map(d => d.ended));
    } else {
      startDate = dates[0].startDate;
      endDate = dates[0].endDate;
    }

    return {startDate, endDate};
  }

  const handleClearAll = () => {
    props.dispatch(ReportActions.RESET_ALL_FILTERS());
    props.dispatch(ReportActions.RESET_DATES());

    setMultiDate(true);
  }

  const {
    visDevices,
    filterStrs,
    dispatch,
    visPeople,
    visTypes,
    dateError,
    visTags,
    visAlerts,
    visLabels,
    reportKey,
    currentFilters,
    devicesDetails,
    people,
    tags,
    labels,
    dates,
    userCan,
    reportTypeFromRedux
  } = props;
  const seePeopleFilter = ACL.check(UserCan.FILTER_REPORTS_BY_PEOPLE, userCan) && reportTypeFromRedux !== 'video' && reportTypeFromRedux !== 'activity';
  const seeDevicesFilter = reportTypeFromRedux !== 'permissions';
  const seeTagsFilter = reportTypeFromRedux !== 'permissions';
  const seeAlertsFilter = reportTypeFromRedux !== 'video' && reportTypeFromRedux !== 'permissions' && reportTypeFromRedux !== 'activity';
  const seeValuesFilter = reportTypeFromRedux === 'static';
  const seeLabelsFilter = reportTypeFromRedux !== 'video' && reportTypeFromRedux !== 'permissions' && reportTypeFromRedux !== 'activity';
  const seeDateFilter = reportTypeFromRedux !== 'permissions' && reportTypeFromRedux !== 'activity';

  const isAdmin = ACL.check(UserCan.DO_ANYTHING, userCan, true);
  const dateDisplay = countStartEndDateSafe();
  const countOfSelectedFilters = checkingSelectedFiltersCount({currentFilters});
  const loginToken = localStorage.get('login-init-be-token');

  return (
    <div className="report-filter-wraper">
      <PerfectScrollbar>
        <div className="report-filters" style={{display: 'flex', flexWrap: 'nowrap', flexDirection: 'column'}}>
          <div className="report-filters-header">
            <h3>Report filters</h3>
          </div>
          <ReportPageFilterSection
            Icon={BookmarkSvg}
            title="Report type"
            visible={isAdmin ? visTypes : visTypes.filter((type) => type.key !== 'audit-log')}
            filterType={FilterType.TYPES}
            countOfSelectedFilters={countOfSelectedFilters}
            RedirectToTabs={true}
          />
          {seeDevicesFilter &&
              <ReportPageFilterSection
                  disabled={!lEmpty(currentFilters[FilterType.TAGS])}
                  Icon={DevicesSvg}
                  title="Devices"
                  visible={visDevices}
                  filterType={FilterType.DEVICES}
                  countOfSelectedFilters={countOfSelectedFilters}
              />
          }
          {seePeopleFilter &&
              <ReportPageFilterSection
                  disabled={!lEmpty(currentFilters[FilterType.TAGS])}
                  Icon={PeopleSvg}
                  visible={visPeople}
                  title="People"
                  filterType={FilterType.PEOPLE}
                  countOfSelectedFilters={countOfSelectedFilters}
              />
          }
          {seeTagsFilter &&
              <ReportPageFilterSection
                  Icon={LayerSvg}
                  disabled={!lEmpty(currentFilters[FilterType.PEOPLE]) || !lEmpty(currentFilters[FilterType.DEVICES])}
                  title="Tags"
                  visible={visTags}
                  filterType={FilterType.TAGS}
                  countOfSelectedFilters={countOfSelectedFilters}
              />
          }
          {seeAlertsFilter &&
              <ReportPageFilterSection
                  Icon={WarningAmberSvg}
                  title="Alerts"
                  RedirectToStatic
                  visible={visAlerts}
                  filterType={FilterType.ALERTS}
                  countOfSelectedFilters={countOfSelectedFilters}
              />
          }
          {seeValuesFilter &&
              <ReportPageFilterSection
                  Icon={BookmarkSvg}
                  title="Values"
                  hasSelectionsGrid
                  filterType={FilterType.VALUES}
                  countOfSelectedFilters={countOfSelectedFilters}
              />
          }
          {seeLabelsFilter &&
              <ReportPageFilterSection
                  Icon={BookmarkSvg}
                  title="Labels"
                  visible={visLabels}
                  filterType={FilterType.LABELS}
                  countOfSelectedFilters={countOfSelectedFilters}
              />
          }
          {seeDateFilter && (
            <div className="date-choser-filter-section report-filter-section">
              <Collapsible
                isCollapsed={true}
                className="report-filter"
                headerClassName="report-filter-header"
                bodyClassName="report-filter-body"
                scrollIntoView
                header={
                  <div className="report-filter-labels-header">
                    <i className="report-filter-icon"><CalendarToday/></i>
                    <span>
										Date/Range {
                      dateDisplay && (
                        <span className="date weight-normal">
													({dateDisplay.startDate.format("MM/DD/YYYY")} - {dateDisplay.endDate.format("MM/DD/YYYY")})
												</span>
                      )
                    }
									</span>
                  </div>
                }
              >
                <div>
                  <div className={`date-filter-tabs`}>
										<span
                      onClick={() => {
                        dispatch(ReportActions.RESET_DATES());
                        setMultiDate(true);
                      }}
                      className={`date-tab ${multiDate ? "active" : ""}`}>
											Date
										</span>
                    <span
                      onClick={() => {
                        dispatch(ReportActions.RESET_DATES());
                        setMultiDate(false);
                      }}
                      className={`date-tab ${!multiDate ? "active" : ""}`}>
											Range
										</span>
                  </div>
                  {!dateError ? <div style={{height: 3}}/> : <div style={{
                    marginTop: 8,
                    color: 'darkred',
                    textAlign: 'center',
                    marginBottom: -25,
                    paddingBottom: 8
                  }}>{dateError}</div>}

                  <div style={{marginTop: 15}}>
                    {multiDate
                      ? <DaysChooser
                        dates={dates}
                        remove={pipe(ReportActions.REMOVE_DATE_GROUP_IN_DATES, dispatch)}
                        dispatch={dispatch}
                        startDateChange={(el) => {
                          setStartOfDateChange(el);
                        }}
                        endDateChange={(el) => {
                          setEndOfDateChange(el);
                        }}
                      />
                      : <RangeDateChooser
                        style={dateError ? {borderColor: 'darkred'} : {}}
                        initialStart={path([0, 'startDate'])(dates)}
                        initialEnd={path([0, 'endDate'])(dates)}
                        calendarHideable={false}
                        calendarTop
                        startDateChange={(el) => {
                          setStartOfDateChange(el);
                        }}
                        endDateChange={(el) => {
                          setEndOfDateChange(el);
                        }}
                      />
                    }
                  </div>
                </div>
              </Collapsible>
            </div>
          )}
        </div>
      </PerfectScrollbar>
      <FiltersActions
        handleClearAll={handleClearAll}
        multiDate={multiDate}
        reportTypeFromRedux={reportTypeFromRedux}
        startOfDateChange={startOfDateChange}
        endOfDateChange={endOfDateChange}
      />
    </div>
  )
}

export default connect(mapStateToProps)(ReportPageFilter);

const FiltersActions = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    const unique = {
      uniqueTripPath: [],
      isSelectedAreaMode: false,
    }

    dispatch(ReportActions.SET_TRIPS_FROM_SELECTED_AREA(unique));
  }, [props.startOfDateChange, props.endOfDateChange])

  const clearRecords = () => {
    dispatch({type: 'REPORT_SET_DISPLAY_RECORDS', displayRecords: {}});
  }

  const handleClose = () => dispatch(GMapModalAC.hideModal());

  const handleApply = () => {
    history.push(`/reports/new/${props.reportTypeFromRedux}`);

    if (props.startOfDateChange || props.endOfDateChange) {
      if (!props.startOfDateChange.isValid() && !props.endOfDateChange.isValid()) {
        dispatch(ReportActions.RESET_DATES());
      }
      else {
        dispatch(ReportActions.SET_START_DATE(props.startOfDateChange));
        dispatch(ReportActions.SET_END_DATE(props.endOfDateChange));
      }
    }

    dispatch(GMapModalAC.hideModal());
  }

  return (
    <div className="report-filters-actions">
      {!(isMobile || window['cordova']) &&
          <div>
              <Button
                  styleType={ButtonStyles.GRAY_INACTIVE}
                  size={ButtonSize.MD}
                  onClick={props.handleClearAll}>
                  Clear all
              </Button>
          </div>
      }
      <div className={(isMobile || window['cordova']) ? 'w100' : ''}>
        <Button
          styleType={ButtonStyles.WHITE_GRAY}
          size={(isMobile || window['cordova']) ? ButtonSize.LG : ButtonSize.MD}
          onClick={handleClose}>
          Cancel
        </Button>
        <Button
          style={{marginLeft: "5px"}}
          styleType={ButtonStyles.ORANGE_WHITE}
          size={(isMobile || window['cordova']) ? ButtonSize.LG : ButtonSize.MD}
          onClick={handleApply}
        >
          Apply
        </Button>
      </div>
    </div>
  )
}
