import React, {useState} from 'react';
import {connect} from 'react-redux';
import {equals, range} from 'ramda';
import moment from 'moment/moment';
import PerfectScrollbar from 'react-perfect-scrollbar';

import {
  iFileFormat,
  iFrequency,
  iFullStoreState,
  iList,
  iPerson,
  ItemType,
  UserAuth
} from '../../../../shared/interfaces';
import {
  selectAllEmailsToSendReport,
  selectCustomEmailsToSendReport,
  selectPeopleToSendEmail
} from '../../../../stores/reducers/report-selectors';
import {Actions as ReportActions, iReportDetails} from '../../../../stores/reducers/report-reducers';
import {DashboardSize, IReducerDashboardInfo} from '../../../../stores/reducers/dashboardInfo';
import {WEEK_DAYS} from '../../../../shared/constants';
import {OptionElement, SelectElement} from '../../../SelectElement';
import Dialog, {DialogConfigSetter} from '../../../Dialog';
import {history} from '../../../../stores/store';
import {addScheduledReport} from '../../../../shared/db/report-db';
import {isMobile} from '../../../../shared/helpers';
import {Button, ButtonSize, ButtonStyles} from '../../../Button';
import {ReportFiltersLabels} from '../../../ReportFiltersLabels';
import {FilterType} from '../ReportPageFilters/ReportPageFilters.interfaces';
import {GMapModalAC, GMapModalContent} from '../../../../stores/reducers/gMapModal/AC';
import SheduleFiltersEditor from '../../../SheduleFiltersEditor/SheduleFiltersEditor';
import {Calendar} from '../CreateReport/Calendar';
import {CreateReportActions} from './CreateReportActions';
import { ReactComponent as FilterListSvg } from '../../../../assets/svg/filter_list.svg';
import './CreateReport.scss';

type iPropsFromState = {
  peopleToSendReport: iList<iPerson>;
  customEmailsToSendReport: string[];
  emailsToSendReport: string[];
  details: iReportDetails;
  authUser: UserAuth;
  dashboardInfo: IReducerDashboardInfo
}

const mapStateToProps = (state: iFullStoreState): iPropsFromState => {
  return {
    peopleToSendReport: selectPeopleToSendEmail(state),
    customEmailsToSendReport: selectCustomEmailsToSendReport(state),
    emailsToSendReport: selectAllEmailsToSendReport(state),
    details: state.report.details,
    authUser: state.auth.user!,
    dashboardInfo: state.dashboardInfo
  }
}

const now = moment();
const months = range(0, 11).map(k => now.clone().month(k).format('MMMM'));
const getMonthDayMoments = monthId => range(0, now.month(monthId).daysInMonth()).map(d => now.clone().month(monthId).startOf('month').add(d, 'days'));

export const WeekDay = ({ title, on, onChange }) => (
  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
    <span>{title}</span>
    <input type="checkbox" checked={on} onChange={onChange} />
  </div>
)

export const WeekDays = ({selected, onChange}: { selected: number[]; onChange: (dayIdx) => void; }) => {
  return (
    <div style={{ display: 'flex', justifyContent: 'space-around' }}>
      {WEEK_DAYS.map((day, index) =>
        <WeekDay key={index} title={day} on={selected.some(equals(index))} onChange={() => onChange(index)} />
      )}
    </div>
  )
}

export const MonthDay = ({ month, day, onChange, hideMonth = false }) => {
  const monthsOptions = months.map((m, idx) => ({value: idx, text: m}));
  const daysOptions = [...getMonthDayMoments(month)
    .map((d, idx) =>
      ({
        value: Number(d.startOf("month").add(idx, "days").format("DD")),
        text: d.format("Do")
      })
    )];
  const handleMonthSelectClick = ({ target: { value } }) => onChange({ month: Number(value), day: 1 });
  const handleDaySelectClick = ({ target: { value } }) => onChange({ month, day: Number(value) });
  return (
    <div>
      <SelectElement
        options={monthsOptions}
        value={month}
        Component={OptionElement}
        onChange={handleMonthSelectClick}
      />
      <div style={{height: "10px"}}></div>
      <SelectElement
        options={daysOptions}
        value={day}
        Component={OptionElement}
        onChange={handleDaySelectClick}
      />
    </div>
  )
}

const CreateReport = (props) => {
  const [frequency, setFrequency] = useState<iFrequency>('daily');
  const [delivery, setDelivery] = useState<iFrequency>('monthly');
  const [frequencyDays, setFrequencyDays] = useState<{[day:number]: boolean}>({});
  const [frequencyMonths, setFrequencyMonths] = useState<{month: number, day: number}>({month: 0, day: 1});
  const [rangeDays, setRangeDays] = useState<number>(1);
  const [format, setFormat] = useState<iFileFormat>('pdf');
  const [filterEditModalIsOpen, setFilterEditModalIsOpen] = useState<boolean>();

  const dialogRef = React.useRef<DialogConfigSetter>();
  const dialog = dialogRef.current;
  const setupDialog = (callBack: () => DialogConfigSetter) =>
    (dialogRef.current = callBack());

  const editFrequency = ({target: {value}}) => {
    setFrequency(value);
  }

  const editDelivery = ({target: {value}}) => {
    setDelivery(value);
  }

  const saveReport = async () => {
    const {details: reportDeets, dispatch} = props;
    const reportType = ['static', 'activity'].includes(reportDeets.reportTypeFromRedux) ? reportDeets.reportTypeFromRedux : 'travel';

    const filters = reportType === 'activity' ? {
      device: props.details.filters.edit_device ?? {},
      tag: props.details.filters.edit_tags ?? {},
    } : {
      person: props.details.filters.edit_people ?? {},
      device: props.details.filters.edit_device ?? {},
      tag: props.details.filters.edit_tags ?? {},
      alerts: props.details.filters.edit_alerts ?? {},
      labels: props.details.filters.edit_labels ?? {},
    }

    addScheduledReport(props.authUser)({
      type: reportType,
      deliverySchedule: {
        schedule: delivery,
        frequencyDays,
        frequencyMonths,
      },
      filters,
      format,
      reportRangeDays: rangeDays,
      sendTo: {
        people: Object.keys(props.peopleToSendReport).length > 0 ? Object.values(props.peopleToSendReport).map((p: iPerson) => p?.id).filter(id => id !== undefined) : [],
        emails: props.customEmailsToSendReport
      }
    })

    dispatch(ReportActions.RESET_ALL_SEND_INFO());
    history.push('/reports/scheduled');
  }

  const openFilterEditModal = () => {
    setFilterEditModalIsOpen(true);
  }

  const closeFilterEditModal = () => {
    setFilterEditModalIsOpen(false);
  }

  const { details: reportDetails, dashboardInfo, dispatch, peopleToSendReport, customEmailsToSendReport } = props;
  const reportType = reportDetails.reportTypeFromRedux === 'travel' ? 'Travel' : reportDetails.reportTypeFromRedux === 'static' ? 'Static' : 'Activity';
  const isActivityReportType = reportDetails.reportTypeFromRedux === 'activity';
  const hasDeliveryOptions = ['anually', 'monthly', 'weekly'].some(equals(delivery));
  const hideMonth = delivery == 'monthly';

  return (
    <>
      <div className={`create-report-container ${dashboardInfo.size === DashboardSize.OPEN? "dashboard-open": ""}`}>
        <PerfectScrollbar>
          <div className={`create-report ${dashboardInfo.size === DashboardSize.OPEN? "dashboard-open": ""}`}>
            {(isMobile || window["cordova"]) && <h5>Create report</h5>}
            <div className={"create-report-col create-report-details"}>
              { !(isMobile || window["cordova"]) && dashboardInfo.size === DashboardSize.OPEN_FULL && (
                <div className="create-report-row">
                  <div className="create-report-header">
                    <h5>Create report:</h5>
                  </div>
                </div>
              )}
              <div className={"create-report-row"}>
                <div className="create-report-row-title">Type</div>
                <div className={"create-report-row-value"}>
                  <Button
                    styleType={
                      format === "pdf"
                        ? ButtonStyles.BLACK_WHITE
                        : ButtonStyles.GRAY_INACTIVE
                    }
                    size={ButtonSize.MD}
                    title={"PDF"}
                    onClick={() => setFormat('pdf')}
                  ><b>PDF</b>
                  </Button>
                  &nbsp;
                  <Button
                    styleType={
                      format === "csv"
                        ? ButtonStyles.BLACK_WHITE
                        : ButtonStyles.GRAY_INACTIVE
                    }
                    size={ButtonSize.MD}
                    title={"CSV"}
                    onClick={() => setFormat('csv')}
                  ><b>CSV</b>
                  </Button>
                </div>
              </div>
              {!isActivityReportType && <div className={"create-report-row"}>
                  <div className={"create-report-row-title"}>Device / Person</div>
                  <div
                      className={"create-report-row-value"}>{reportDetails.devicePerson == ItemType.device ? 'Device' : 'Person'}</div>
              </div>}
              <div className={"create-report-row"}>
                <div className={"create-report-row-title"}>Report Type</div>
                <div className={"create-report-row-value"}>{reportType}</div>
              </div>

              <div className="create-report-row">
                <div className="create-report-row-title">Filters</div>
                <div className="create-report-row-value">
                  <Button
                    styleType={ButtonStyles.BLACK_WHITE}
                    size={ButtonSize.SM}
                    onClick={openFilterEditModal}
                  >
                    <span className={"align-center"}><FilterListSvg className="icon-md" style={{ marginRight: "5px" }} />Set</span>
                  </Button>
                </div>
              </div>

              {!isActivityReportType && <div className="create-report-row">
                  <div className="create-report-row-title">Alerts</div>
                  <div className="create-report-row-value create-report-row-value--clickable"
                       onClick={openFilterEditModal}>
                      <ReportFiltersLabels
                          type={FilterType.EDIT_ALERTS}
                          render={({item}) => <p>{item}</p>}
                          defaultLabel={"All filters"}
                      />
                  </div>
              </div>}

              {!isActivityReportType && <div className="create-report-row">
                  <div className="create-report-row-title">Labels</div>
                  <div className="create-report-row-value create-report-row-value--clickable"
                       onClick={openFilterEditModal}>
                      <ReportFiltersLabels
                          type={FilterType.EDIT_LABELS}
                          render={({item}) => <p>{item}</p>}
                          defaultLabel={"All labels"}
                      />
                  </div>
              </div>}

              <div className="create-report-row">
                <div className="create-report-row-title">Tags</div>
                <div className="create-report-row-value create-report-row-value--clickable" onClick={openFilterEditModal}>
                  <ReportFiltersLabels
                    type={FilterType.EDIT_TAGS}
                    render={({item}) => <p>{item}</p>}
                    defaultLabel={"All tags"}
                  />
                </div>
              </div>

              <div className="create-report-row">
                <div className="create-report-row-title">Devices</div>
                <div className="create-report-row-value create-report-row-value--clickable" onClick={openFilterEditModal}>
                  <ReportFiltersLabels
                    type={FilterType.EDIT_DEVICES}
                    render={({item}) => <p>{item}</p>}
                    defaultLabel={"All devices"}
                  />
                </div>
              </div>

              {!isActivityReportType && <div className="create-report-row">
                  <div className="create-report-row-title">People</div>
                  <div className="create-report-row-value create-report-row-value--clickable"
                       onClick={openFilterEditModal}>
                      <ReportFiltersLabels
                          type={FilterType.EDIT_PEOPLE}
                          render={({item}) => <p>{item}</p>}
                          defaultLabel={"All persons"}
                      />
                  </div>
              </div>}

              <div className="create-report-row">
                <div className="create-report-row-title">Send to</div>
                <div className="create-report-row-value">
                  <div className="create-report-row-label">
                    {[...Object.values(peopleToSendReport).map((p: iPerson) => p?.displayName).filter(displayName => displayName !== undefined), ...customEmailsToSendReport].join(", ")}
                  </div>
                  <Button className="create-repport-add-send-to-btn" styleType={ButtonStyles.BLACK_WHITE} size={ButtonSize.MD} onClick={() =>{
                    dispatch(GMapModalAC.showModal({contentType: GMapModalContent.SELECT_SEND_TO}));
                  }}>Add</Button>
                </div>
              </div>
            </div>

            <div className="create-report-col create-report-select">
              { dashboardInfo.size === DashboardSize.OPEN && (
                <div className="create-report-row">
                  <div className="create-report-header">
                    <h5>Create report:</h5>
                  </div>
                </div>
              )}
              {!hideMonth ? null : (
                <div className={"create-report-note"}>
                  <b>Note:</b> Picking a day which a month doesn't contain, will cause that month's report to not be delivered. (eg. Feb 31st)
                </div>
              )}
              <div>
                <div className={"create-report-select-section-title"}>Delivery</div>
                <SelectElement
                  options={[
                    { value: "weekly", text: "Weekly"},
                    { value: "monthly", text: "Monthly" },
                    { value: "daily", text: "Daily" },
                    { value: "anually", text: "Anually"}
                  ]}
                  value={delivery}
                  Component={OptionElement}
                  onChange={editDelivery}
                />
              </div>

              {!hasDeliveryOptions ? null :
                <div className="create-report-select-section" >
                  <div className={"create-report-select-section-title"}>Send report on</div>
                  {delivery != 'weekly' ? null :
                    <WeekDays
                      selected={Object.keys(frequencyDays).filter(k => frequencyDays[k]).map(Number)}
                      onChange={(idx) => setFrequencyDays((prevFrequencyDays) => {
                        const newFrequencyDays = {...prevFrequencyDays};
                        newFrequencyDays[idx] = !newFrequencyDays[idx];
                        Object.keys(newFrequencyDays).forEach(key => {
                          if (!newFrequencyDays[key]) {
                            delete newFrequencyDays[key];
                          }
                        })
                        return newFrequencyDays;
                      })}
                    />
                  }

                  {delivery != 'anually' && delivery != 'monthly' ? null :
                    <MonthDay
                      day={frequencyMonths.day}
                      month={frequencyMonths.month}
                      hideMonth={delivery == 'monthly'}
                      onChange={(newValue) => {
                        console.log(newValue)
                        setFrequencyMonths(newValue);
                      }}
                    />
                  }
                </div>
              }
              <div title="Range" className="create-report-select-section">
                <div className={"create-report-select-section-title"}>Range</div>
                <SelectElement
                  options={[
                    { value: "weekly", text: "Weekly" },
                    { value: "monthly", text: "Monthly" },
                    { value: "daily", text: "Daily" },
                    { value: "anually", text: "Anually" }
                  ]}
                  value={frequency}
                  Component={OptionElement}
                  onChange={editFrequency}
                />
              </div>
              <div>
                <Calendar
                  type={frequency}
                  dayCount={rangeDays}
                  onChange={(newValue) => setRangeDays(newValue)}
                />
              </div>
            </div>

          </div>

        </PerfectScrollbar>
      </div>
      <Dialog setupConfig={setupDialog} />
      <CreateReportActions saveReport={saveReport}/>
      <SheduleFiltersEditor isOpen={filterEditModalIsOpen} onClose={closeFilterEditModal} isActivity={isActivityReportType}/>
    </>
  )
}

export default connect(mapStateToProps)(CreateReport);
