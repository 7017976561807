import React, {CSSProperties as css} from "react";
import Dialog, {DialogConfigSetter} from "../../../Dialog";
import DeleteIcon from "../../../SVG-components/delete_outline";
import {keyGen} from "../../../../shared/firebase";


// todo refactor this. ( Add interface and typization. also move logic for contact input here)
const AddContact = ({contacts, style, contact, onChange, onBlur, onAddContact, onDeleteContact}: {contacts: any, contact: string, style?: css, onChange: any, onBlur: any, onAddContact: any, onDeleteContact: any }) => {

    const isValidEmail = (str) => !/(\.{2}|-{2}|_{2})/.test(str) && /^[a-zA-Z0-9][a-zA-Z0-9-_\.]+@([a-z]|[a-z0-9]?[a-z0-9-]+[a-z0-9])\.[a-z0-9]{2,10}(?:\.[a-z]{2,10})?$/.test(str);
    const isValidPhoneNumber = (str) => /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/.test(str);

    const dialogRef = React.useRef<DialogConfigSetter>();
    const dialog = dialogRef.current;
    const setupDialog = (callBack: () => DialogConfigSetter) =>
        (dialogRef.current = callBack());

    const saveContact = () => {
        if (typeof(contact) === 'undefined' || contact === '') {
            dialog?.({
                title: 'Input is empty!',
                body: 'Please, enter your email or phone number.',
                type: 'NOTIFICATION',
            });

            return;
        } else if (isValidEmail(contact) || isValidPhoneNumber(Number(contact))) {
            // const contactID = keyGen();
            onAddContact(contact);
        } else {
            const body = (
              <div style={{display: 'flex', flexDirection: 'column'}}>
                  <p>Please enter a valid 10 digit phone number with no additional symbols. <b>eg. 5551235555</b></p>
                  <p>OR</p>
                  <p>Enter a valid Email address. <b>eg. myname@example.com</b></p>
              </div>
            );

            dialog?.({
                title: 'Incorrect input!',
                body,
                type: 'NOTIFICATION',
            });

            return;
        }
    }

    return (
        <div style={{display: 'flex', flexDirection: 'column', padding: 5, ...style}}>
            <p style={{margin: 0, fontSize: 14}}>Add custom recipients phone or email</p>
            <div style={{display: 'flex', justifyContent: 'space-between', marginBottom: 8}}>
                <input
                    value={contact}
                    name='contact'
                    className="mas-input"
                    style={{border: 'none', backgroundColor: '#F7F7F7', width: 125, flex: '0.55 0.55 0%', borderRadius: 2, fontSize:12, padding: 10, outline: 'none'}}
                    onChange={onChange}
                    onBlur={onBlur}
                />
                <button className="btn btn-sm btn-outline-primary"
                        style={{border: '1px solid black', flex: '0.3 0.3 0%', padding: '5px 10px', fontSize: 12, color: 'white', backgroundColor: 'black', borderRadius: 4}}
                onClick={() => saveContact()}>Add</button>
            </div>
            <div style={{display: 'flex', flexDirection: 'column', marginTop: 10, padding: '10px 5px 5px', backgroundColor: 'rgb(247, 247, 247)'}}>
                <p style={{fontWeight: 500, fontSize: 14, marginBottom: 5}}>Email/Phone</p>
                <div>
                    {contacts?.length > 0 && contacts.map((contact, index) => {
                        const isEmail = contact.includes('@');
                        const customRecipient = isEmail ? contact : contact.replace(/\D/g,'')
                        return (
                            <div
                                style={{display: 'flex', justifyContent: "space-between", marginTop: 5}}
                                key={index}>
                                <p title={contact} style={{overflow: "hidden", fontSize: 13, textOverflow: "ellipsis", whiteSpace: "nowrap", margin: 0}}>{customRecipient}</p>
                                <p style={{textAlign: 'right', marginLeft: 5, marginBottom: 0}}>
                                    <DeleteIcon className='delete-filter' onClick={() => onDeleteContact(index)} />
                                </p>
                            </div>
                        )
                    })}
                </div>
            </div>
            <Dialog setupConfig={setupDialog} />
        </div>
    );
};


export default AddContact;
