import React from 'react';
import {pipe, cond, equals, always, T} from 'ramda';

import C from '../../../../shared/constants';
import {targetChecked, targetVal} from '../../../../shared/helpers';
import {CheckboxElement} from '../../../elements/CheckboxElement';

interface iProps {
  onChange: ((dayCount: number) => any);
  dayCount: number;
  type: 'weekly' | 'monthly' | 'daily' | 'anually'
}
export const Calendar = ({ type, dayCount, onChange }: iProps) => {
  if (type == 'daily') {
    return <div><span className={"color-inactive"}>Report will include previous day's data</span></div>;
  }

  const typeTxt = cond([
    [equals('weekly'), always('week')],
    [equals('monthly'), always('month')],
    [equals('anually'), always('year')],
    [T, always('')],
  ])(type)

  return <div>
    <div> Include previous
      <input
        type="number"
        className="mas-input"
        style={{ ...C.inputCss, width: 55, lineHeight: '11px', marginLeft: 5, marginRight: 5 }}
        value={dayCount < 1 ? '' : dayCount}
        disabled={dayCount < 1}
        max={7}
        min={1}
        onChange={pipe(targetVal, onChange)}
      /> days in report.
    </div>
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <CheckboxElement
        id={'start'}
        checked={dayCount == 0}
        onChange={pipe(targetChecked, checked => onChange(checked ? 0 : 1))}
      />
      From start of {typeTxt} in report
    </div>
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <CheckboxElement
        id={'entire'}
        checked={dayCount == -1}
        onChange={pipe(targetChecked, checked => onChange(checked ? -1 : 1))}
      />
      Include one full {typeTxt} in report
    </div>
  </div>
}
