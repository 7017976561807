import React, {useEffect, useState} from 'react';
import {values} from 'ramda';
import moment from 'moment';
import {faSpinner} from '@fortawesome/fontawesome-free-solid';

import TableSimple from '../../../elements/TableSimple';
import {Fa} from '../../../elements/fa';
import {fetchDeviceCodes} from './utils';
import {Col, Row} from '../../../elements/flex';
import {Overlay} from '../../../menus/modal/modal';

export const CheckEngineOverlay = (props: {deviceId: string; dismiss: () => void; close: () => void}) => {
  const [details, setDetails] = useState<{
    code: string;
    date: moment.Moment;
    description: string;
  } | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchCodes = async () => {
      setIsLoading(true);

      const deviceCodes = values(await fetchDeviceCodes(props.deviceId));

      if (deviceCodes && deviceCodes.length) {
        setDetails(deviceCodes[0]);
      }

      setIsLoading(false);
    };

    fetchCodes();
  }, []);

  const {close, dismiss} = props;

  if (isLoading) return (
    <Row justify='center' align='center'>
      <Fa icon={faSpinner} spin />
    </Row>
  );

  return (
    <Overlay close={close} >
      <Col>
        {!details ? null :
          <TableSimple dark sm striped headers={['Date', 'Code', 'Desc.']} style={{fontSize: 11}}>
            <tr>
              <td>{details.date.format('l LT')}</td>
              <td>{details.code}</td>
              <td>{details.description}</td>
            </tr>
          </TableSimple>
        }
        <p>Silence Check Engine Notification?</p>
        <button className='btn btn-primary' onClick={dismiss}>Dismiss</button>
      </Col>
    </Overlay>
  )
};
