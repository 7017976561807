import React, { useEffect, useState } from 'react';
import StatusOnPNG from '../../../../../assets/png/accept.png';
import StatusOffPNG from '../../../../../assets/png/exclamation-circle.png';
import PlayIconPNG from '../../../../../assets/png/play-button.png';
import CalendarPNG from '../../../../../assets/png/calendar.png';
import { ReactComponent as VisibilityIcon } from '../../../../../assets/svg/visibility.svg';

import { IDevicesStatusNavigationProps } from '../interfaces';
import styles from './DevicesStatusNavigation.module.scss';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useDispatch, useSelector } from 'react-redux';
import {
  cameraAvailableTime,
  openModal,
  statusCameraOnline,
} from '../../../../../stores/reducers/videoCameras';
import { checkStatusCamera } from '../../../../../api/openApi';
import { iReducersState } from '../../../../../stores/reducers';
import Portal from '../../../../Portal';
import instance from '../../../../../api/instance';
import Modal from "../../../../Modal";

const INTERVAL_TIME: number = 30000;

const DevicesStatusNavigation = ({
  device,
}: IDevicesStatusNavigationProps): JSX.Element => {
  const [isModalShow, setIsModalShow] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [availableTime, setAvailableTime] = useState<number>(null);
  const [showOfflineModal, setShowOfflineModal] = useState<boolean>(false);
  const { openLiveViewPlayer, openHistoryPlayer }: any = useSelector(
    (state: iReducersState) => state.videoCameras.modals
  );
  const { isCamera, 'extra-info': serialNumber } = device || {};
  const serial = Object.values(serialNumber)[0].toString();

  const dispatch = useDispatch();
  const { cameraOnline } = useSelector(
    (state: iReducersState) => state.videoCameras
  );
  const CAMERA_ONLINE = cameraOnline === 1;
  const SAVE_MODE = cameraOnline === 2;

  const getStatus = async (): Promise<void> => {
    setIsLoading(true);
    if (isCamera && device.id) {
      const { onlineStatus, channels, cameraTime } = await checkStatusCamera({
        firebaseDeviceId: device.id,
      });
      dispatch(statusCameraOnline(onlineStatus));
      dispatch(cameraAvailableTime(cameraTime?.available_time));
      setAvailableTime(cameraTime?.available_time);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getStatus();
    let interval;
    if (openHistoryPlayer || openLiveViewPlayer) {
      clearInterval(interval);
    } else {
      interval = setInterval(getStatus, INTERVAL_TIME);
    }

    return () => clearInterval(interval);
  }, [openHistoryPlayer, openLiveViewPlayer]);

  const checkIfOnline = () => {
    if (!CAMERA_ONLINE) setShowOfflineModal(true);
  }

  const liveViewClick = (): void => {
    checkIfOnline();

    if (SAVE_MODE) {
      setIsModalShow(true);
    } else {
      if (CAMERA_ONLINE)
        dispatch(openModal({ key: 'openLiveViewModal', value: true }));
      if (CAMERA_ONLINE && availableTime === 0)
        dispatch(openModal({ key: 'openBuyMoreModal', value: true }));
    }
  };

  const history = () => {
    checkIfOnline();

    if (SAVE_MODE) {
      setIsModalShow(true);
    } else {
      if (CAMERA_ONLINE)
        dispatch(openModal({ key: 'openHistoryModal', value: true }));
      if (CAMERA_ONLINE && availableTime === 0)
        dispatch(openModal({ key: 'openBuyMoreModal', value: true }));
    }
  };

  const onCloseModal = () => {
    setIsModalShow(false);
  };

  const onAcceptModal = () => {
    setIsLoading(true);
    setIsModalShow(false);

    instance
      .get(`/api/device/change-cameras-state?uniqueId=${serial}&onlineState=2`)
      .then(() => {
        getStatus();
      });
  };

  const getStatusTitle = () => {
    if (cameraOnline === 1) {
      return 'Unit online';
    } else if (cameraOnline === 2) {
      return 'Power save mode';
    } else {
      return 'Unit is not online';
    }
  };

  const getStatusIcon = () => {
    if (cameraOnline === 1) {
      return <img src={StatusOnPNG} alt='status' />;
    } else if (cameraOnline === 2) {
      return (
        <VisibilityIcon
          className='icon-30px'
          style={{
            paddingTop: '3px',
            paddingBottom: '2px',
          }}
        />
      );
    } else {
      return <img src={StatusOffPNG} alt='status' />;
    }
  };

  return (
    <>
      {(showOfflineModal && !SAVE_MODE) && <Modal>
        <div className={styles.offlineModal}>
          <h3 style={{textAlign: 'center'}}>Device is offline</h3>
          <p>This camera is currently not connected to the network. The camera may be powered off, or out of cellular coverage. Please wait for the camera to connect before trying to view any video.</p>
          <button className={styles.closeButton} onClick={() => setShowOfflineModal(false)} style={{fontSize: '25px'}}>&#x2715;</button>
        </div>
      </Modal>}
      <div className={styles.cameraIcons} style={{maxHeight: '70px'}}>
        <div
          className={styles.deviceIcon}
          title={getStatusTitle()}
          onClick={checkIfOnline}
        >
          {isLoading ? (
            <CircularProgress size={25} color={'inherit'} />
          ) : (
            getStatusIcon()
          )}
          <p>Status</p>
        </div>

        <div
          className={styles.deviceIcon}
          title={'Live View'}
          onClick={liveViewClick}
        >
          {isLoading ? (
            <CircularProgress size={25} color={'inherit'} />
          ) : (
            <img src={PlayIconPNG} alt='live' />
          )}
          <p>View</p>
        </div>

        <div className={styles.deviceIcon} title={'History'} onClick={history}>
          {isLoading ? (
            <CircularProgress size={25} color={'inherit'} />
          ) : (
            <img src={CalendarPNG} alt='history' />
          )}
          <p>History</p>
        </div>
      </div>
      {isModalShow && (
        <Portal>
          <div
            className='device-status__modal-wrapper'
            style={{
              zIndex: 99,
              position: 'absolute',
              top: 0,
              left: 0,

              width: '100vw',
              height: '100vh',
              backdropFilter: 'brightness(0.3)',

              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
            }}
          >
            <div
              className='device-status__modal'
              style={{
                minHeight: '200px',
                maxHeight: '20%',

                minWidth: '300px',
                maxWidth: '50%',

                backgroundColor: '#fff',

                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-around',
              }}
            >
              <p
                style={{
                  padding: '20px',
                }}
              >
                This unit is asleep in power save mode. This is done to preserve
                battery life. To wake the unit up select yes. It may take up to
                2 minutes for the unit to wake up.
              </p>

              <div
                className='device-status__modal-footer'
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  alignItems: 'center',

                  width: '100%',
                  height: '50px',

                  borderTop: '1px solid black',

                  padding: '0 30px',
                }}
              >
                <button
                  className='but  but-white-gray but-md'
                  onClick={onCloseModal}
                >
                  No
                </button>
                <button
                  className='but  but-orange-white but-md'
                  style={{
                    marginLeft: '5px',
                  }}
                  onClick={onAcceptModal}
                >
                  Yes
                </button>
              </div>
            </div>
          </div>
        </Portal>
      )}
    </>
  );
};

export default DevicesStatusNavigation;
