import React, { FunctionComponent, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import LockIcon from '../../../SVG-components/lock';

import LocalPersonTile from '../LocalPeopleTile';
import InputBox from '../../../elements/InputBox';
import {HttpStatus, iFullStoreState} from '../../../../shared/interfaces';

import styles from './ResetPassword.module.scss';

interface ResetPasswordProps {}

const ResetPassword: FunctionComponent<ResetPasswordProps> = () => {
  const [isChanged, setIsChanged] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const accessToken = useSelector<iFullStoreState, string>(state => state.billing.token.access_token);

  const schema = yup.object().shape({
    password: yup
      .string()
      .required('Password is required')
      .matches(
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
        'Must Contain 8 characters, one uppercase, one lowercase, one number and one special case character'
      ),
    password2: yup
      .string()
      .oneOf([yup.ref('password'), null], 'Passwords must match')
      .required('Password confirm is required'),
  });

  const { register, handleSubmit, setError, errors } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = (fields) => {
    fetch(`${process.env.REACT_APP_API_URL}/api/user/app-reset-password`, { ///site/request-password-reset
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Access-Token': `${accessToken}`
      },
      body: JSON.stringify({
        token: accessToken,
        user_password_reset_type: 'api',
        newPassword: fields.password,
      }),
    }).then((res) => {
      if (res.status === HttpStatus.OK) {
        setIsChanged(true);
      }
    });
  };

  return (
    <LocalPersonTile
      collapsible
      initialCollapsed
      title='Reset password'
      action={undefined}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={styles.wrapper}>
          {!isChanged && (
            <>
              <InputBox
                label='Password'
                name='password'
                type='password'
                register={register}
                error={!!errors.password}
                helperText={
                  errors.password
                    ? errors.password?.message || 'Validation error'
                    : ''
                }
                icon={LockIcon}
              />
              <InputBox
                label='Password repeat'
                name='password2'
                type='password'
                register={register}
                error={!!errors.password2}
                helperText={
                  errors.password2
                    ? errors.password2?.message || 'Validation error'
                    : ''
                }
                icon={LockIcon}
              />

              <button type='submit'>
                {loading ? 'Wait please...' : 'Submit'}
              </button>
            </>
          )}

          {isChanged && (
            <>
              <div className={styles.completedMessageWrapper}>
                <div className={styles.icon}>
                  <svg
                    viewBox='0 0 24 24'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <g id='SVGRepo_bgCarrier' strokeWidth='0'></g>
                    <g
                      id='SVGRepo_tracerCarrier'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                    ></g>
                    <g id='SVGRepo_iconCarrier'>
                      <path
                        d='M19.5479 7.26651C20.2474 6.41162 20.1214 5.15157 19.2665 4.45212C18.4116 3.75266 17.1515 3.87866 16.4521 4.73355L8.66618 14.2497L6.2 12.4C5.31634 11.7373 4.06274 11.9164 3.4 12.8C2.73726 13.6837 2.91635 14.9373 3.8 15.6L7.03309 18.0248C8.31916 18.9894 10.137 18.7688 11.155 17.5246L19.5479 7.26651Z'
                        stroke='#ff7c02'
                        strokeWidth='2'
                        strokeLinecap='round'
                      ></path>
                    </g>
                  </svg>
                </div>
                <div className={styles.text}>
                  The password has been changed.
                </div>
              </div>
            </>
          )}
        </div>
      </form>
    </LocalPersonTile>
  );
};

export default ResetPassword;
