import React from 'react';

import DashboardBlock from '../DashboardBlock';
import ModalHeader from '../menus/modal/modal-header';
import MinimalTile from '../general/MinimalTile';
import {ToggleBtn} from '../elements/Buttons';
import {useFirebase} from '../../states/firebase-state';

export default () => {
    const [strictAccess, strictSetter] = useFirebase('config/strictAccess');

    const setStrict = () => strictSetter(!strictAccess);

    return (
        <DashboardBlock>
            <ModalHeader title="Settings" />
            <MinimalTile title="Require device whitelist for users">
                <ToggleBtn active={!!strictAccess} title="Strict Access" onClick={setStrict} />
            </MinimalTile>
        </DashboardBlock>
    );
};

// @connect(s => ({ authUser: s.auth.user }))
// export default class SettingsPage extends BaseComponent<{}, { config }, { authUser: UserAuth } > {

//     // constructor(props) {
//     //     super(props)
//     //     this.registerStateFirebaseWatchers([['config' , 'config']])
//     // }

//     _toggleStrict = () => {
//         const on = !pathOr(false, ['config', 'strictAccess'], this.state)

//         on
//             ? generaldb.setConfig(this.fullProps.authUser)('strictAccess', true)
//             : generaldb.unsetConfig(this.fullProps.authUser)('strictAccess')
//     }

//     debugRender = () => {
//         const { config = {}, connecting = false } = this.state || {};

//         return (

//         )
//     }
// }
