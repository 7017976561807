import React, {useEffect, useRef, useState} from 'react';
import {RouteComponentProps} from 'react-router-dom';
import {connect, DispatchProp} from 'react-redux';
import { path, pipe, keys, map, T, omit, values } from 'ramda';

import {iFenceDetails, iFullStoreState, iList, iTag, ItemType, UserAuth} from '../../../shared/interfaces';
import {DialogConfigSetter} from '../../Dialog';
import * as tagSelectors from '../../../shared/db/tags-labels-selectors';
import {SearchGridOld} from '../../SearchGridOld/SearchGridOld';
import {TagToElement} from './TagToElement';
import {StandardItem} from '../../general';
import {FenceBodyLayout} from '../../fence/tab-bodies/BodyLayout';
import DashboardBlock from '../../DashboardBlock';
import ActionRow from '../../fence/tab-bodies/ActionRow';
import ModalHeader from '../../menus/modal/modal-header';
import {idValArr, vals} from '../../../shared/helpers';
import {Actions} from '../../../stores/reducers/tag-item';
import * as tagsDb from '../../../shared/db/tags-db';
import {fenceHistory, history} from '../../../stores/store';

type IProps = RouteComponentProps<{
  type: ItemType;
  itemId: string;
  isFence?: string;
}>

type IPropsFromStore = {
  authUser?: UserAuth;
  tagFilter: string;
  tags: iList<iTag>;
  itemTags: iList<iTag>;
  fence?: iFenceDetails;
}

type IFullProps = IProps & IPropsFromStore & DispatchProp;

const mapStateToProps = (state: iFullStoreState, props: IProps): IPropsFromStore => {
  const {itemId, type} = props.match.params;

  return {
    authUser: state.auth.user!,
    tagFilter: state.tagItem.tagFilter || '',
    tags: state.general.tags,
    itemTags: tagSelectors.getItemTagsSelector(state, {itemId, itemType: type}),
    fence: (state.general.fences[itemId] || {} as any).details,
  };
}

const TagItem = (props: IFullProps) => {
  const [showWarn, setShowWarn] = useState<boolean>(false);
  const [alertChanged, setAlertChanged] = useState<boolean>(false);
  const [visTags, setVisTags] = useState({} as iList<true>);

  let timeoutForHide;

  const {tags, tagFilter, fence} = props;
  const {type, itemId, isFence} = props.match.params;
  const DynamicLayout = isFence ? FenceBodyLayout : DashboardBlock as any;
  const DynamicHeader = isFence ? <ActionRow title={(fence || {} as any).name} canBack actions={[]} /> : <ModalHeader title="Add Tag" />
  const dialogRef = useRef<DialogConfigSetter>();
  const dialog = dialogRef.current;
  const setupDialog = (callBack: () => DialogConfigSetter) =>
    (dialogRef.current = callBack());
  const filteredTags = vals(tags)
    .filter(t => t.details.name?.toLowerCase().indexOf(tagFilter.toLowerCase()) !== -1)
    .filter(t => type === ItemType.device || !t.details.isAlertType);

  useEffect(() => {
    const toMerge = pipe(
      omit(keys(visTags)),
      map(T)
    )(props.itemTags);

    setVisTags({...visTags, ...toMerge});

    return () => {
      props.dispatch({type: Actions.RESET_PAGE});
    }
  }, []);

  useEffect(() => {
    const { tags, itemTags } = props;
    const pre = values(itemTags).find(t => t.details.isAlertType);
    const post = idValArr(visTags).find(({ id }) => tags[id].details.isAlertType);
    const preId = path(['details', 'id'])(pre);
    const postId = path(['id'])(post);

    setAlertChanged(preId !== postId);
  }, [visTags]);

  const hideWarn = () => {
    clearTimeout(timeoutForHide);
    setShowWarn(false);
  }

  const showWarnHandler = () => {
    setShowWarn(true);
    timeoutForHide = setTimeout(hideWarn, 5000);
  }

  const filterChange = (filter) => {
    props.dispatch({type: Actions.CHANGE_FILTER, filter})
  }

  const toggleTag = (key) => {
    const match = props.tags[key];
    const visHasAlert = idValArr(visTags).find(({ id }) => props.tags[id].details.isAlertType);

    if (match.details.isAlertType && visHasAlert && !visTags[key]) {
      showWarnHandler();
      return;
    }

    const exists = visTags[key];

    if (exists) {
      const oldVisTags = {...visTags};
      delete oldVisTags[key];
      setVisTags(oldVisTags);

      setShowWarn(false);
    }
    else {
      setVisTags({...visTags, [key]: true});

      setShowWarn(false);
    }
  };

  const updateTags = async () => {
    const {authUser} = props;
    const {isFence, type, itemId} = props.match.params;

    try {
      const isComeFromDevicePage = true;
      await tagsDb.setItemTags(authUser)(isComeFromDevicePage, type, itemId, Object.keys(visTags));
    } catch (e) {
      console.log(e)
    }

    setVisTags({});

    isFence ? fenceHistory.goBack() : history.goBack();
  }

  if (!tags) return null;

  return (
    <DynamicLayout mapOnly={!itemId || !isFence}>
      {DynamicHeader}
      <SearchGridOld
        style={{maxWidth: 300}}
        list={filteredTags.map(tag => <TagToElement key={tag.details.id} tag={tag} isSelected={!!visTags[tag.details.id]} /> )}
        filterStr={tagFilter}
        placeholder="Search Tags"
        perPage={9}
        keyClicked={toggleTag}
        filterChange={filterChange}
      />

      <div style={{display: 'flex', flexWrap: 'wrap', marginTop: 15}}>
        {Object.keys(visTags).map(tagId =>
          <StandardItem
            showBell={tags[tagId].details.isAlertType}
            key={tagId}
            style={{margin: 4, order: tags[tagId].details.name.length}}
            remove={toggleTag}
            itemId={tagId}
            displayName={tags[tagId].details.name}
          />
        )}
      </div>

      <div className="alert alert-info" style={{display: alertChanged ? 'block' : 'none'}}>
        Saving these changes will cause device <span style={{whiteSpace: 'nowrap'}}>settings / alerts</span> to change.
      </div>

      <div className="alert alert-warning" style={{display: showWarn ? 'block' : 'none' }}>
        <span className="close btn-link" onClick={hideWarn} aria-label="close">&times;</span>
        Multiple alert type tags are not permitted.
      </div>

      <div className="text-center" style={{marginTop: 10}}>
        <button onClick={updateTags} className="btn btn-primary">Update Tags</button>
      </div>
    </DynamicLayout>
  )
}

export default connect(mapStateToProps)(TagItem);
