import React, {useState} from 'react';
import { mapObjIndexed, pipe, values } from 'ramda';

import { iList } from '../../../../../shared/interfaces';
import Dialog, { DialogConfigSetter } from '../../../../Dialog';
import DeleteIcon from '../../../../SVG-components/delete_outline';
import {isValidEmail, isValidPhoneNumber} from '../../../../../utils';

import './NonUserAlerts.styles.scss';

type NonUserAlertsProps = {
  style?: React.CSSProperties;
  recipients: iList<string>;
  toggleRecipient: (recipient: string, onOff: boolean) => any;
}

export const NonUserAlerts = ({style, recipients, toggleRecipient}: NonUserAlertsProps) => {
  const [newRecipient, setNewRecipient] = useState<string>('');
  const [inputIsEmpty, setInputIsEmpty] = useState<boolean>(false);

  const dialogRef = React.useRef<DialogConfigSetter>();
  const dialog = dialogRef.current;
  const setupDialog = (callBack: () => DialogConfigSetter) => (dialogRef.current = callBack());

  const submit = async () => {
    const recipientIsValidEmail = isValidEmail(newRecipient);
    const recipientIsValidNumber = isValidPhoneNumber(newRecipient);

    if (newRecipient === '') {
      setInputIsEmpty(true);

      return;
    }

    if (recipientIsValidEmail || recipientIsValidNumber) {
      toggleRecipient(newRecipient, true);
      setNewRecipient('');
    }
    else {
      const body = (
        <div style={{display: 'flex', flexDirection: 'column'}}>
          <p>Please enter a valid 10 digit phone number with no additional symbols. <b>eg. 5551235555</b></p>
          <p>OR</p>
          <p>Enter a valid Email address. <b>eg. myname@example.com</b></p>
        </div>
      )

      await dialog?.({
        title: 'Incorrect input!',
        body,
        type: 'NOTIFICATION',
      });
    }
  }

  const removeRecipient = (recipient) => {
    toggleRecipient(recipient, false);
  }

  const change = ({ target: { value } }) => {
    if (inputIsEmpty) setInputIsEmpty(false);
    setNewRecipient(value);
  }

  const keyDown = ({ keyCode }) => {
    if (keyCode === 13) {
      submit();
    }
  }

  return (
    <div
      className='non-user-alerts'
      style={{
        ...style,
      }}
    >
      <p className='non-user-alerts__header'>
        Add custom recipients phone or email
      </p>
      <div className='non-user-alerts__input'>
        <input
          value={newRecipient}
          className='mas-input'
          onChange={change}
          onKeyDown={keyDown}
        />
        <button
          className='btn btn-sm btn-outline-primary'
          onClick={submit}
        >
          Add
        </button>
      </div>
      {inputIsEmpty && <p style={{margin: '0', fontSize: '14px', color: 'red'}}>Input cannot be empty</p>}
      <div className='non-user-alerts__list'>
        <p>Email/Phone</p>
        <div>
          {pipe(
            mapObjIndexed((r, k) => {
              const isEmail = r.includes('@');
              const customRecipient = isEmail ? r : r.replace(/\D/g, '');

              return (
                <div className='non-user-alerts__list-item' key={k}>
                  <p className='non-user-alerts__list-item__email' title={r}>
                    {customRecipient}
                  </p>
                  <p className='non-user-alerts__list-item__icon'>
                    <DeleteIcon
                      className='delete-filter'
                      onClick={() => removeRecipient(k)}
                    />
                  </p>
                </div>
              );
            }),
            values
          )(recipients)}
        </div>
      </div>
      <Dialog setupConfig={setupDialog}/>
    </div>
  );
};
