import React from 'react';

import LocalPersonTile from '../LocalPeopleTile';
import { FaLink } from '../../../elements/fa';
import { faPencilAlt } from '@fortawesome/fontawesome-free-solid';
import TableSimple from '../../../elements/TableSimple';

const RFIDTagsTile = ({ personId, RFIDTag }) => {
    return (
        <LocalPersonTile
            collapsible
            initialCollapsed
            title='RFID Tag'
            action={
                (
                    <FaLink
                        to={`/person/${personId}/rfid-tags`}
                        icon={faPencilAlt}
                        style={{ color: '#fff' }}
                    />
                )
            }
        >
            <TableSimple
                sm
                striped
                headers={['Id', 'Tag label']}
                style={{ fontSize: 11 }}
            >
                <tr>
                    <td
                        style={{
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            minWidth: 30,
                        }}
                    >
                        {RFIDTag?.id ?? ''}
                    </td>
                    <td
                        style={{
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                        }}
                    >
                        {RFIDTag?.name ?? (<span style={{ color: 'darkred' }}>No tag attached</span>)}
                    </td>
                </tr>
            </TableSimple>
        </LocalPersonTile>
    )
};

export default RFIDTagsTile;
