import React, {CSSProperties as css, useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {Link, useLocation} from 'react-router-dom';
import {equals} from 'ramda';
import {
    faSpinner,
    faToggleOn,
    faToggleOff,
    faCog,
    faChevronRight,
    faChevronDown
} from '@fortawesome/fontawesome-free-solid';

import C from '../../shared/constants';
import {iDeviceEvent, iFullStoreState, ItemType, UserAuth} from '../../shared/interfaces';
import * as alertsdb from '../../shared/db/alerts-db';
import {Fa} from '../elements/fa';
import {withSentry} from '../../shared/hoc/withSentry';
import {useRedux} from '../../states/redux-state';
import {getReadOnlyStatus} from '../../shared/db/general-db';
import Dialog, {DialogConfigSetter} from '../Dialog';
import { useIsReadOnly } from '../../hooks/useIsReadOnly';
import { localStorage } from '../../shared/storage';
import {MenuPin} from '../elements/MenuPin';

interface iProps {
    eventKeys: string[];
    values: {[alertType: string]: {config?: string; on: boolean}}|'initializing';
    itemType: ItemType;
    subLayer?: string;
    itemId: string;
    style?: css;
    broadcast?: {itemType: ItemType; itemId: string}[];
    itemIdProps?: string;
    itemTypeProps?: ItemType;
    deviceId?: string;
    property?: string;
    isPinned?: boolean;
}

export const AlertToggles = withSentry((props: iProps) => {
    const authUser = useSelector<iFullStoreState, UserAuth>((s) => s.auth.user!, (l, r) => equals(l, r));
    const events = useSelector<iFullStoreState, Array<iDeviceEvent>>(
        // @ts-ignore
        (s) => props.eventKeys.map((k) => s.general.deviceEvents?.[k]).filter(Boolean),
        (l, r) => equals(l, r)
    );
    const mapType = useRedux((s) => s.gmap.mapType);
    const location = useLocation()

    const [collapsed, setCollapsed] = useState<boolean>(() => {
        return props.property ? !props.isPinned : true;
    });

    useEffect(() => {
        if (props.property) setCollapsed(!props.isPinned);
    }, [props.isPinned]);

    const on = (type: string) => props.values === 'initializing' ?
        false :
        !!((props.values || {})[type] || {} as any).on;

    const toggleOn = async (alertType: string, on: boolean) => {

        const {itemType, itemId, subLayer, broadcast = []} = props;
        const extra = broadcast.map((x) => ({...x, alertType}));

        alertsdb.toggleAlerts(authUser)([{itemType, itemId, alertType}, ...extra], on, subLayer);
    };

    const {values, itemType, itemId, style = {}, itemIdProps, itemTypeProps, deviceId, property, isPinned} = props;

    const toggleCollapsed = () => {
        setCollapsed(!collapsed);
    };

    const [readOnly, setReadOnly] = useState(false);

    useEffect(() => {
        getReadOnlyStatus(authUser.uid, itemId).then((res) => setReadOnly(res));
    }, []);

    useEffect(() => {
      const initialCollapsed = localStorage.get('fromAlertSettings', true)

      localStorage.forget('fromAlertSettings');

      setCollapsed(initialCollapsed);
    }, []);

    const isReadOnly = useIsReadOnly(itemIdProps, itemTypeProps)

    const dialogRef = React.useRef<DialogConfigSetter>();
    const setupDialog = (callBack: () => DialogConfigSetter) => dialogRef.current = callBack();

    const switcher = (event) => {
        if(readOnly) {
            const dialog = dialogRef.current;

            dialog?.({
                type: 'NOTIFICATION',
                title: 'Permissions ERROR!',
                body: 'You do not have permission to change these settings.',
            });
        } else {
            toggleOn(event.key, !on(event.key));
        }
    };

    return (
        <div style={{width: '100%', ...style, marginBottom: 10, border: `1px solid ${C.lightGray}`, position: 'relative'}} >
            {values !== 'initializing' ?  null : <div style={{position: 'absolute', top: 0, bottom: 0, left: 0, right: 0, backgroundColor: C.lightGray, opacity: .5, zIndex: 1, display: 'flex',justifyContent: 'center', alignItems: 'stretch'}} >
                <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', fontSize: 30}}><Fa icon={faSpinner} spin /></div>
            </div> }
            <div onClick={toggleCollapsed} style={{display: 'flex', cursor: 'pointer'}}>
                <div style={{flex: 1}}>
                    <div style={headerRowCss}>
                        <div>
                            <Fa icon={ collapsed ? faChevronRight : faChevronDown } onClick={toggleCollapsed} style={{...styleExtraInfo__toggle}} />
                            <span>Alerts</span>
                        </div>
                        {!collapsed && <MenuPin deviceId={deviceId} property={property} isPinned={isPinned}/>}
                    </div>
                </div>
                <div style={{borderLeft: `1px solid ${C.lightGray}`, width: '65px'}}>
                    <div style={headerRowCss}>Status</div>
                </div>
            </div>
            <div>
                {!collapsed ? (
                    <div style={{display: 'flex'}}>
                        <div style={{display: 'flex', flex: 1, flexDirection: 'column'}}>
                            <div style={colCss}>
                                {events.map((event, idx) => (
                                    <span key={event.key} style={rowCss(idx)} >
                                        {event.name}
                                        {console.log("is r o", isReadOnly)}
                                        <ConfigLink style={ isReadOnly? {color: "gray", pointerEvents: "none"} : {}} key={event.name} alertType={event.key} itemType={itemType} itemId={itemId} />
                                    </span>)
                                )}
                            </div>
                        </div>
                        <div style={{display: 'flex', flexDirection: 'column', borderLeft: `1px solid ${C.lightGray}`, width: '65px'}}>
                            <div style={colCss}>
                                {events
                                    .map((event) => <Fa key={event.key}
                                    onClick={() => switcher(event)}
                                    icon={on(event.key) ? faToggleOn : faToggleOff}
                                    style={{fontSize: 16,...(!on(event.key) ? {color: "black"} : {color: 'green'}), ...(isReadOnly? {color: "gray", pointerEvents: "none"} : {})}}
                                    />)
                                    .map((c,idx) => <div key={idx} className="text-center" style={rowCss(idx)}>{c}</div>)}
                            </div>
                        </div>
                    </div>
                ) : ''}
            </div>
            <Dialog setupConfig={setupDialog} />
        </div>
    );
});

const ConfigLink = ({alertType, itemType, itemId, style = {}}) => <Link style={style} to={`/${itemType}/${itemId}/alerts/${alertType}`}>
    <sup style={{fontSize: 12}} ><Fa icon={faCog} /></sup>
</Link>;

const styleExtraInfo__toggle: React.CSSProperties = {
    color: '#fff',
    marginRight: 10,
    cursor: 'pointer',
};

const headerRowCss: css = {
    backgroundColor: C.primaryColor,
    color: '#fff',
    padding: '3px 8px',
    display: 'flex',
    justifyContent: 'space-between'
};
const colCss: css = {
    display: 'flex',
    flexDirection: 'column',
};

const rowCss = (idx) => ({
    padding: 5,
    height: 32,
    textAlign: 'left',
    backgroundColor: idx % 2 ? C.lightGray : 'initial',
}) as any;
