import React from 'react';
import { useParams } from 'react-router-dom';

import LocalPersonTile from '../LocalPeopleTile';
import {FaLink} from '../../../elements/fa';
import {faPencilAlt} from '@fortawesome/fontawesome-free-solid';
import TableSimple from '../../../elements/TableSimple';

const GroupsTile = ({groups}) => {
  const { id: personId } = useParams();

  return (
    <LocalPersonTile
      collapsible
      initialCollapsed
      title='Groups'
      action={
        <FaLink
          to={`/person/${personId}/groups`}
          icon={faPencilAlt}
          style={{color: '#fff'}}
        />
      }
    >
      <TableSimple
        sm
        striped
        headers={['Groups']}
        style={{ fontSize: 11 }}
      >
        {groups.map((group, index) => {
          return (
            <tr key={index}>
              <td>
                {group.name}
              </td>
            </tr>
          )
        })}
      </TableSimple>
    </LocalPersonTile>
  );
};

export default GroupsTile;
