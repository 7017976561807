import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { faPencilAlt } from '@fortawesome/fontawesome-free-solid';

import { mapStateToProps } from './PersonEditPage.types';
import DashboardBlock from '../../DashboardBlock';
import ModalActions from '../../menus/modal/modal-actions';
import ModalHeader from '../../menus/modal/modal-header';
import {makeAudit, noSubmit} from '../../../shared/helpers';
import C from '../../../shared/constants';
import { Fa } from '../../elements/fa';
import Dialog, { DialogConfigSetter } from '../../Dialog';
import MinimalTile from '../../general/MinimalTile';
import { history, store } from '../../../stores/store';
import * as peopleDb from '../../../shared/db/people-db';
import {isValidEmail, isValidPhoneNumber } from '../../../utils';
import {clientDb} from "../../../shared/firebase";

import styles from './PersonEditPage.module.scss';
import DeleteIcon from '../../SVG-components/delete_outline';

interface IPersonEdits {
  displayName?: string;
  mainNumber?: string;
  email?: string;
  photoURL?: string;
}

enum ContactsCollectionType {
  EMAIL = 'emails',
  NUMBER = 'phoneNumbers'
}

const LocalTile = ({ title, children }) => (
  <MinimalTile title={title} small style={{ marginBottom: 10 }}>
    {children}
  </MinimalTile>
);

const PersonEditPage = (props) => {
  const { person } = props;
  const user = store.getState().auth.user;
  const [personEdits, setPersonEdits] = useState<IPersonEdits>({});
  const [contactEmail, setContactEmail] = useState<string>('');
  const [contactPhone, setContactPhone] = useState<string>('');
  const [isInvalidEmail, setIsInvalidEmail] = useState<boolean>(false);
  const [isInvalidPhone, setIsInvalidPhone] = useState<boolean>(false);
  const [personEmails, setPersonEmails] = useState<string[]>([]);
  const [personNumbers, setPersonNumbers] = useState<string[]>([]);

  useEffect(() => {
    const getUserContacts = async () => {
      const personContacts = (await (clientDb().child(`/people/people-contacts/${person.id}`).once('value'))).val();

      if(personContacts && personContacts.emails){
        setPersonEmails(personContacts.emails);
      }

      if(personContacts && personContacts.phoneNumbers){
        setPersonNumbers(personContacts.phoneNumbers);
      }
    }

    getUserContacts();
  }, []);

  const cancel = () => {
    setPersonEdits({});
    history.goBack();
  };

  const update = async () => {
    const { id: personId } = props.match.params;
    const { authUser } = props;

    if (authUser) {
      if (
        (isValidEmail(personEdits.email) || !personEdits.email) &&
        (isValidPhoneNumber(personEdits.mainNumber) || !personEdits.mainNumber)
      ) {
        await peopleDb.updatePersonDetails(authUser)(personId, personEdits);
        setPersonEdits({});
        history.goBack();
      } else {
        const body = (
          <div style={{display: 'flex', flexDirection: 'column'}}>
            <p>Please enter a valid 10 digit phone number with no additional symbols. <b>eg. 5551235555</b></p>
            <p>OR</p>
            <p>Enter a valid Email address. <b>eg. myname@example.com</b></p>
          </div>
        );

        await dialog?.({
          title: 'Incorrect input!',
          body,
          type: 'NOTIFICATION',
        });
      }
    } else {
      setPersonEdits({});
      history.goBack();
    }
  };

  const saveImage = async ({ target }) => {
    const { id: personId } = props.match.params;
    const url = await peopleDb.storeProfileImage(personId, target.files[0]);
    setPersonEdits({ ...personEdits, photoURL: url });
  };

  const editDisplayName = (e) => {
    setPersonEdits({ ...personEdits, displayName: e.target.value });
  };

  const editPhoneNumber = (e) => {
    setPersonEdits({ ...personEdits, mainNumber: e.target.value });
  };

  const editEmail = (e) => {
    setPersonEdits({ ...personEdits, email: e.target.value });
  };

  const editContactEmail = (e) => {
    setContactEmail(e.target.value);

    if(isInvalidEmail) setIsInvalidEmail(false);
  }

  const editContactPhone = (e) => {
    setContactPhone(e.target.value);

    if(isInvalidPhone) setIsInvalidPhone(false);
  }

  const updateContactCollection = async (value: string[], collection: string) => {
    await clientDb().update(makeAudit(user, {
      [`people/people-contacts/${person.id}/${collection}`]: value,
    }));
  }

  const addContactEmail = async () => {
    if(!isValidEmail(contactEmail)){
      setIsInvalidEmail(true);
    }
    else {
      if(!personEmails.includes(contactEmail)){
        setPersonEmails([...personEmails, contactEmail]);
        await updateContactCollection([...personEmails, contactEmail], ContactsCollectionType.EMAIL);
      }
    }

    setContactEmail('');
  }

  const addContactPhone = async () => {
    if(!isValidPhoneNumber(contactPhone)){
      setIsInvalidPhone(true);
    }
    else {
      if(!personNumbers.includes(contactPhone)){
        setPersonNumbers([...personNumbers, contactPhone]);
        await updateContactCollection([...personNumbers, contactPhone], ContactsCollectionType.NUMBER);
      }
    }

    setContactPhone('');
  }

  const removeContact = async (item, collection) => {
    if (collection === ContactsCollectionType.EMAIL) {
      const newEmails = personEmails.filter(email => email !== item);
      setPersonEmails(newEmails);
      await updateContactCollection(newEmails, ContactsCollectionType.EMAIL);
    }
    else {
      const newPersonNumbers = personNumbers.filter(number => number !== item);
      setPersonNumbers(newPersonNumbers);
      await updateContactCollection(newPersonNumbers, ContactsCollectionType.NUMBER);
    }
  }

  const dialogRef = React.useRef<DialogConfigSetter>();
  const dialog = dialogRef.current;
  const setupDialog = (callBack: () => DialogConfigSetter) =>
    (dialogRef.current = callBack());

  useEffect(() => {
    const newPerson: IPersonEdits = {
      displayName: person.displayName || '',
      mainNumber: person.mainNumber || '',
      email: person.email || '',
    };
    setPersonEdits(newPerson);
  }, []);

  if (!person) return null;

  const { photoURL = person.photoURL } = personEdits;

  return (
    <DashboardBlock
      overlay={
        Object.keys(personEdits).length ? (
          <ModalActions
            buttons={[
              { click: cancel, title: 'Cancel' },
              { click: update, title: 'Update', primary: true },
            ]}
          />
        ) : undefined
      }
    >
      <ModalHeader title="Edit Person" />
      <form onSubmit={noSubmit} className={styles.personEditForm}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginBottom: 10,
          }}
        >
          <span
            className="btn-link"
            onClick={(_) => {
              document.getElementById('user-file-upload')?.click?.();
            }}
          >
            <div
              style={{
                width: 90,
                height: 90,
                borderRadius: 90,
                backgroundImage: `url(${photoURL})`,
                backgroundSize: 'cover',
                border: `2px solid ${C.mediumGray}`,
                position: 'relative',
              }}
            >
              <Fa
                icon={faPencilAlt}
                style={{
                  position: 'absolute',
                  right: -7,
                  bottom: -2,
                  fontSize: 12,
                  color: C.primaryColor,
                }}
              />
            </div>
          </span>
        </div>
        <div>
          <input
            type="file"
            id="user-file-upload"
            name="image"
            onChange={saveImage}
            style={{display: 'none'}}
          />
        </div>
        <LocalTile title="Display Name">
          <input
            key="title-input"
            name="displayName"
            className="mas-input"
            style={{...C.inputCss, width: '100%'}}
            onChange={editDisplayName}
            value={personEdits.displayName}
          />
        </LocalTile>
        <LocalTile title="Main Phone">
          <input
            key="title-input"
            name="mainNumber"
            className="mas-input"
            style={{...C.inputCss, width: '100%'}}
            onChange={editPhoneNumber}
            value={personEdits.mainNumber}
          />
        </LocalTile>
        <LocalTile title="Main Email">
          <input
            key="title-input"
            name="email"
            className="mas-input"
            style={{...C.inputCss, width: '100%'}}
            onChange={editEmail}
            value={personEdits.email}
          />
        </LocalTile>
        <LocalTile title='Add Contact Email'>
          <input
            name="contactEmail"
            onChange={editContactEmail}
            style={{...C.inputCss, width: '100%'}}
            value={contactEmail}
          />
        </LocalTile>
        {isInvalidEmail && <span className={styles.invalidEmailPhoneMessage}>Invalid email</span>}
        <button onClick={addContactEmail} className={styles.personEditButton}>Add</button>
        <LocalTile title='Add Contact Phone'>
          <input
            name="contactPhone"
            onChange={editContactPhone}
            style={{...C.inputCss, width: '100%'}}
            value={contactPhone}
          />
        </LocalTile>
        {isInvalidPhone && <span className={styles.invalidEmailPhoneMessage}>Invalid phone number</span>}
        <button onClick={addContactPhone} className={styles.personEditButton}>Add</button>
        {(personEmails.length > 0 || personNumbers.length > 0) && <LocalTile title='Email/Phone'>
          {personEmails.map(email => {
            return (
              <div className={styles.contactListItem}>
                <span>{email}</span>
                <DeleteIcon className={styles.deleteIcon}
                            onClick={() => removeContact(email, ContactsCollectionType.EMAIL)}/>
              </div>
            )
          })}
          {personNumbers.map(number => {
            return (
              <div className={styles.contactListItem}>
                <span>{number}</span>
                <DeleteIcon className={styles.deleteIcon}
                            onClick={() => removeContact(number, ContactsCollectionType.NUMBER)}/>
              </div>
            )
          })}
        </LocalTile>}
      </form>
      <Dialog setupConfig={setupDialog}/>
    </DashboardBlock>
  );
};

export default connect(mapStateToProps)(PersonEditPage);