import React, { useState } from 'react';

import C from '../../shared/constants';
import { Fa } from '../elements/fa';
import MinimalTile from './MinimalTile';

import { faChevronDown } from '@fortawesome/fontawesome-free-solid';

interface iCollapseTileProps {
  children?: any,
  style?: React.CSSProperties,
  alwaysOpen?: boolean,
  disabled?: boolean,
  header?: JSX.Element,
  body: JSX.Element
}

const collapseBody: React.CSSProperties = {
  borderTop: `2px solid ${C.darkGray}`,
  marginTop: 2,
  paddingTop: 5,
}

const disabledOverlayCss: React.CSSProperties = {
  position: 'absolute',
  cursor: 'not-allowed',
  left: 0,
  top: 0,
  right: 0,
  bottom: 0,
  backgroundColor: 'rgba(200, 200, 200, .5)',
}

export const CollapseTile = (props: iCollapseTileProps) => {
  const { alwaysOpen, style = {}, disabled = false, header, body } = props;
  const [collapsed, setCollapsed] = useState<boolean>(true);

  if (alwaysOpen) {
    setCollapsed(false);
  }

  return (
    <MinimalTile style={{...style, position: 'relative'}}>
      <div style={disabled ? disabledOverlayCss : {} as any} />
      <div style={{display: 'flex', justifyContent: 'space-between' as any, alignItems: 'center', cursor:'pointer'}} onClick={() => setCollapsed(!collapsed)}>
        {header}
        {alwaysOpen ? null : <Fa icon={faChevronDown} style={{padding: 4, fontSize: 21}} />}
      </div>
      {collapsed ? null : <div style={collapseBody}>{body}</div>}
    </MinimalTile>
  )
};

export const CollapseHeader = (props) => {
  return (
    <div>
      props.children
    </div>
  )
};

export const CollapseBody = (props) => {
  return (
    <div>
      props.children
    </div>
  )
};