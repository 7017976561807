import React from 'react';

interface iProps {
  striped?: boolean;
  sm?: boolean;
  dark?: boolean;
  headers?: string[];
  style?: React.CSSProperties;
  onAllClick?: () => void;
  allBtn?: any;
  children?: React.ReactNode;
}

const TableSimple = (props: iProps) => {
  const { allBtn: AllBtn, onAllClick } = props;

  const getClassFromProps = () => {
    let classNameString = 'table';

    if(props.striped) classNameString += ' table-striped';
    if(props.sm) classNameString += ' table-sm';
    if(props.dark) classNameString += ' table-dark';

    return classNameString;
  }

  return (
    <table
      className={getClassFromProps()}
      style={{backgroundColor: props.dark ? '' : '#fff', opacity: 1, ...(props.style || {})}}
    >
      {!props.headers ? null : (
        <thead>
          <tr>
            {props.headers.map(h => <th key={h}>{h}</th>)}
            {props.allBtn && (
              <th>
                <AllBtn onAllClick={onAllClick}/>
              </th>
            )}
          </tr>
        </thead>)}
      <tbody>
        {props.children}
      </tbody>
    </table>
  )
}

export default TableSimple;
