import React, {useEffect, useState} from 'react';
import DashboardBlock from "../../DashboardBlock";
import {Fa} from "../../elements/fa";
import {faArrowLeft, faEye, faPencilAlt, faSpinner} from "@fortawesome/fontawesome-free-solid";
import styles from './styles.module.scss';
import {useHistory} from 'react-router-dom';
import {dvirCloseDashboard, dvirOpenDashboard} from "../../../stores/reducers/dashboardInfo/AC";
import {useDispatch, useSelector} from "react-redux";
import {localStorage} from "../../../shared/storage";
import instance from "../../../api/instance";
import {DvirPagination} from "./DvirPagination";
import {iFullStoreState, iPerson} from "../../../shared/interfaces";
import C from "../../../shared/constants";
import {Row} from "../../elements/flex";
import {dvirStatusMapping, formatDate} from "./utils";

interface iDvir {
  id: string,
  device_key: string,
  firebase_key: string,
  created_at: string,
  person_key: string,
  status: string,
  vehicle_name: string,
  vehicle_vin: string
}

const Dvir = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const clientId = localStorage.get('active-client');
  const [page, setPage] = useState<number>(1);
  // const [limit, setLimit] = useState<number>(10);
  const [dvirs, setDvirs] = useState<iDvir[]>();
  const [totalPages, setTotalPages] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const people = useSelector<iFullStoreState>(state => state.general.people);

  const fetchDvirs = async (page: number = 1, limit: number = 5) => {
    const URL = 'api/dvir/get-dvirs';
    const body = {
      organization_key: clientId,
      page,
      limit
    }

    try {
      setIsLoading(true);

      const {data} = await instance.post(URL, {...body});

      const {data: dvirs, total_pages: totalPages} = data;

      setTotalPages(totalPages);
      setDvirs(dvirs);

      setIsLoading(false);

      const dvir = dvirs[0];
    } catch ({message}) {
      console.log(message)
    }
  }

  const handlePageChange = (page) => {
    setCurrentPage(page);

    fetchDvirs(page);
  }

  useEffect(() => {
    dispatch(dvirOpenDashboard());

    fetchDvirs();

    return () => {
      dispatch(dvirCloseDashboard());
    }
  }, []);

  return (
    <DashboardBlock>
      <div className={styles.DvirHeader}>
        <span className="no-print btn-link" style={{paddingLeft: 10, marginRight: 20}} onClick={() => history.goBack()}><Fa icon={faArrowLeft}/></span>
        <h2>DVIR Overview</h2>
        <div></div>
      </div>
      <div className={styles.DvirButtons}>
        <button onClick={() => history.push('/dvir/new')}>New Dvir</button>
        <button onClick={() => history.push('/dvir-forms')}>Forms</button>
      </div>
      {isLoading && (
        <Row justify='center' align='center'>
          <Fa icon={faSpinner} spin style={{ fontSize: 30 }}/>
        </Row>
      )}
      {dvirs && dvirs.length > 0 && !isLoading && (
        <table className={styles.DvirTable}>
          <thead>
            <tr>
              <th>#</th>
              <th>DVIR ID</th>
              <th>Driver</th>
              <th>Date</th>
              <th>Vehicle Name</th>
              <th>Status</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {dvirs.map((dvir, index) => {
              const person = people[dvir.person_key];

              return (
                <tr key={dvir.firebase_key}>
                  <td>{index + 1}</td>
                  <td>{dvir.id}</td>
                  <td>{person?.displayName || ''}</td>
                  <td>{formatDate(dvir.created_at)}</td>
                  <td>{dvir.vehicle_name}</td>
                  <td>{dvirStatusMapping[dvir.status]}</td>
                  <td className={styles.DvirItemActions}>
                    <Fa icon={faEye} style={{color: C.primaryColor.toString()}} onClick={() => history.push(`/dvir/${dvir.firebase_key}`)}/>
                    <Fa icon={faPencilAlt} style={{color: C.primaryColor.toString()}}/>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      )}
      {!isLoading && <DvirPagination
        totalPages={totalPages}
        currentPage={currentPage}
        onPageChange={handlePageChange}
      />}
    </DashboardBlock>
  )
};

export default Dvir;
