import React, {CSSProperties as css, useState} from 'react';
import { Fa } from '../elements/fa';

import { faChevronLeft, faChevronRight, faSearch } from '@fortawesome/fontawesome-free-solid';
import styles from './SearchGridOld.module.scss';

interface iProps {
    placeholder,
    list: JSX.Element[],
    filterStr: string,
    filterChange: (filter) => any,
    keyClicked: ((keyId: string) => any),
    perPage: number
    page?: number,
    textual?: boolean,
    dispatchers?: any,
    style?: css,
    actions?: JSX.Element
}

export const SearchGridOld = (props: iProps) => {
    const { placeholder, list, style = {} } = props;
    const [page, setPage] = useState<number>(1);

    const displayItems = () => {
        const { perPage, textual } = props;

        return list
          .filter((i, idx) => idx >= perPage * (page - 1) && idx < perPage * (page - 1) + perPage)
          .map(i => <div key={i.key} className={`${!textual ? styles.containerItem : ''}`} onClick={_ => props.keyClicked(i.key.toString())}>{i}</div>)
    }

    const pageCount = () => {
        return Math.ceil(props.list.length / props.perPage);
    }

    const filterChange = ({ target }) => {
        props.filterChange(target.value);
    }

    const changePage = (amount) => {
        let newPage = page + amount;
        const count = pageCount();

        if (newPage < 1) newPage = 1;
        if (newPage > count) newPage = count;

        setPage(newPage);

        return { page: newPage };
    }

    const countNumberOfUnitsSelected = (list) => {
        let numberOfUnitsSelected = 0;

        list.forEach(obj => {
            if (obj.props && obj.props.children && obj.props.children.props && obj.props.children.props.active === true) {
                numberOfUnitsSelected++;
            }
        });

        return numberOfUnitsSelected;
    }

    const returnStringOfUnitsSelected = (numberOfUnitsSelected) => {
        if (numberOfUnitsSelected === 0) {
            return 'No units selected'
        } else if (numberOfUnitsSelected === 1) {
            return `${numberOfUnitsSelected} unit selected`
        } else {
            return `${numberOfUnitsSelected} units selected`
        }
    }

    const numberOfUnitsSelected = countNumberOfUnitsSelected(list);
    const count = pageCount();

    return (
      <div style={{ ...style, margin: '0 auto' }}>
          {props.actions && (
            <div className={styles.gridActions}>
                <span className={styles.unitsSelected}>{returnStringOfUnitsSelected(numberOfUnitsSelected)}</span>
                {props.actions}
            </div>
          )}
          <div className={styles.inputBlock}>
              <Fa icon={faSearch} containerClassName={styles.searchIcon}/>
              <input type="text" placeholder={placeholder} className={styles.inputBar} value={props.filterStr} onChange={filterChange} />
          </div>
          <div className={styles.container}>{displayItems()}</div>
          <Pager page={page} pages={count} change={changePage} />
      </div>
    )
}

const Pager = ({ page, pages, change }) => {
    if (pages < 2) return null;

    const pageNums = Array(pages).fill(1).map((_, p) => p + 1);

    return <div className={styles.pagerContainer}>
        <Fa icon={faChevronLeft} onClick={_ => change(-1)} containerClassName={`${styles.chevron} ${page === 1 ? styles.disabled : ''}`}/>
        <div className={styles.pageIndicators}>
            {pageNums.map(p => (
              <span
                key={p}
                className={`${styles.pageIndicator} ${page === p ? styles.currentPageIndicator : ''}`}
                onClick={_ => change(p - page)}
              />
            ))}
        </div>
        <Fa icon={faChevronRight} onClick={_ => change(1)} containerClassName={`${styles.chevron} ${page === pages ? styles.disabled : ''}`}/>
    </div>
}
