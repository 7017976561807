import React, {useEffect, useState} from 'react';
import {faPencilAlt} from '@fortawesome/fontawesome-free-solid';

import {iList, iPerson, MenuPinType} from '../../../../shared/interfaces';
import {getDeviceAllowedSee} from '../../../../shared/db/general-db';
import TableSimple from '../../../elements/TableSimple';
import {FaLink} from '../../../elements/fa';
import {LocalTile} from "../device-details";

export const VisibleToPeople = (props: {deviceId: string; deviceTags: string[], people: iList<iPerson>, isPinned: boolean}) => {
  const [peopleIds, setPeopleIds] = useState([]);
  const {people, isPinned, deviceId} = props;

  useEffect(() => {
    const {deviceId, deviceTags} = props;

    const getDevicesAllowedOnMount = async () => {
      const peopleIds = await getDeviceAllowedSee(deviceId, deviceTags);

      setPeopleIds(peopleIds);
    }

    getDevicesAllowedOnMount();
  }, []);

  const peopleIsEmpty = peopleIds.length === 0;

  return (
    <LocalTile
      collapsible
      initialCollapsed={isPinned}
      title="Visible to..."
      action={null}
      deviceId={deviceId}
      property={MenuPinType.VISIBLE_TO_PEOPLE}
      isPinned={isPinned}
    >
      {peopleIsEmpty ? null :
        <TableSimple sm striped headers={['Person', '']} style={{fontSize: 11}}>
          {peopleIds
            .filter((id) => people[id])
            .map((id) => people[id])
            .map((person) => <tr key={person.id}>
              <td>{person.displayName}</td>
              <td className="text-right"><FaLink icon={faPencilAlt} to={`/person/${person.id}/visible-devices`} style={{fontSize: 13}}/></td>
            </tr>)}
        </TableSimple>
      }
    </LocalTile>
  )
};