import React, { useEffect, useState } from "react";
import { Button } from "@material-ui/core";

import Modal from "../../Modal";
import { targetChecked } from "../../../shared/helpers";
import {getAvailableTime, postNewAlarmId, postNewPointId, updateAvailableTime} from "../../../api/openApi";

import styles from './AddAlarmIdModalForm.module.scss';
import moment from 'moment';
import AddPointTimeInput from './AddPointTimeInput';

const errorDetailsMap = {
    0: {
        heading: 'Unexpected Error',
        message: 'An unexpected error occurred. Please try again later.',
    },
    1: {
        heading: 'Validation Error',
        message: 'There was an error with the data you provided. Please check your input and try again.',
    },
    2: {
        heading: 'Device Not Found',
        message: 'The specified device could not be found. Please ensure the device ID is correct and try again.',
    },
    3: {
        heading: 'Device IMEI Missing',
        message: 'The device IMEI was not found. Please verify the IMEI and try again.',
    },
    4: {
        heading: 'Organization Not Found',
        message: 'The organization linked to this device was not found. Please check the organization details and try again.',
    },
    5: {
        heading: 'Point Not Found',
        message: 'The specified point could not be found. Please verify the point ID and try again.',
    },
};

const AddAlarmIdModalForm = ({ onCloseForm, formData, setFormData, serial, firebaseDeviceId, isEvent }) => {
    const [formSuccessfullySent, setFormSuccessfullySent] = useState(false);
    const [checkboxes, setCheckboxes] = useState({ first_checkbox: false, second_checkbox: false });
    const [estimatedDownloadTime, setEstimateTime] = useState(0);
    const [availableTime, setTime] = useState(0);
    const [isError, setIsError] = useState<boolean>(false);
    const [errorMsg, setErrorMsg] = useState<string>('');
    const [errorHeading, setErrorHeading] = useState<string>('');
    const { channel, stream_type, before, after } = formData;

    let initialFormData;

    const handleChangeCheckbox = ({ target: { name } }) => {
        setCheckboxes((prevState) => ({ ...prevState, [name]: !prevState[name] }));
    }

    // @ts-ignore
    useEffect(() => {
        getDeviceCamerasAvailableTime(firebaseDeviceId);
        initialFormData = {...formData};
    }, []);

    const getDeviceCamerasAvailableTime = async (firebaseDeviceId) => {
        const res = await getAvailableTime({firebaseDeviceId: firebaseDeviceId});
        setTime(res?.available_time);
        return;
    }

    useEffect(() => {
        let channelString;
        if (checkboxes.first_checkbox && checkboxes.second_checkbox) {
            channelString = "1,2";
        } else if (checkboxes.first_checkbox) {
            channelString = "1";
        } else if (checkboxes.second_checkbox) {
            channelString = "2";
        } else {
            channelString = "";
        }
        setFormData((prevState) => ({ ...prevState, channel: channelString }));
    }, [checkboxes])

    useEffect(() => {
        calcEstimatedTime();
    }, [channel, stream_type, before, after]);

    const calcEstimatedTime = () => {
        let isHighDefined = stream_type === '1' ? 2 : 1;
        let channelsCount = channel
            .split(',')
            .filter((channel_name) => channel_name.trim())
            .length;

        const neededDownloadTime = (Number(before) + Number(after)) * (Number(channelsCount) * isHighDefined);
        setEstimateTime(neededDownloadTime);
    }

    const formatDuration = (seconds) => {
        const duration = moment.duration(seconds, 'seconds');
        const hours = Math.floor(duration.asHours());
        const minutes = duration.minutes();
        const secs = duration.seconds();

        const hoursStr = hours > 0 ? `${hours}h ` : '';
        const minutesStr = minutes > 0 ? `${minutes}m ` : '';
        const secsStr = secs > 0 ? `${secs}s` : '';

        return `${hoursStr}${minutesStr}${secsStr}`.trim();
    };

    const handleChange = ({ target: { name, value } }) => {
        setFormData((prevState) => ({ ...prevState, [name]: value }));
    };

    const handleChangeTime = (type, value) => {
        setFormData(prevState => ({
            ...prevState,
            [type]: Math.max(0, Math.min(value, 1800 - (prevState[type === 'before' ? 'after' : 'before'] || 0)))
        }));
    };

    const submitForm = async (event) => {
        event.preventDefault();

        const before = formData.before || 0;
        const after = formData.after || 0;

        if ((before + after) < 15) {
            setIsError(true);
            setErrorHeading('Time Selection Too Short');
            setErrorMsg('Your selected time range must be at least 15 seconds. Please choose a longer duration to proceed.')

            return;
        }

        let res;

        if(isEvent){
            res = await postNewAlarmId(formData);
        }
        else {
            res = await postNewPointId(formData);
        }

        if (res.success) {
            const res = await updateAvailableTime({firebaseDeviceId: firebaseDeviceId, playedSeconds: estimatedDownloadTime});
            setFormSuccessfullySent(true);
        }
        else {
            const errorCode = res.data.error_type;

            const errorDetail = errorDetailsMap[errorCode] || {
                heading: 'Unexpected Error',
                message: 'An unexpected error occurred. Please try again later.',
            };

            setErrorHeading(errorDetail.heading);
            setErrorMsg(errorDetail.message);
            setIsError(true);
        }
    }

    return (
        <Modal>
            <div className={styles.modalContent} style={{ width: "35vw", padding: '30px 50px' }}>
                <div className={styles.modalHeader}>
                    <div className={styles.modalHeaderRow}>
                        {!isError ? <h2>Downloading the {isEvent ? "alarm" : "point"}</h2> : <h2>Error Occured</h2>}
                        <button onClick={onCloseForm} role="close modal button">X</button>
                    </div>
                </div>
                {formSuccessfullySent ? (
                    <>
                        <p>Your download has started. It may take several minutes for this process to complete. The camera is making your requested video segment and transmitting. You can check back on the status in the event balloon or by running a video report. </p>
                        <p>The camera must be powered on for this to complete. </p>
                    </>
                ) : (isError ? (
                  <div className={styles.modalError}>
                      <h3>{errorHeading}</h3>
                      <p>{errorMsg}</p>
                  </div>
                  ) : (
                  <form onSubmit={submitForm}>
                      <p className={styles.labelTitle}>Channel:</p>
                      <label className={styles.container}>1
                          <input
                            type="checkbox"
                            name="first_checkbox"
                            checked={checkboxes.first_checkbox}
                            onChange={handleChangeCheckbox}
                          />
                          <span className={styles.checkmark}></span>
                      </label>
                      <label className={styles.container}>2
                          <input
                            type="checkbox"
                            name="second_checkbox"
                            checked={checkboxes.second_checkbox}
                            onChange={handleChangeCheckbox}
                          />
                          <span className={styles.checkmark}></span>
                      </label>
                      <br/>
                      <label htmlFor="file_type" className={styles.labelTitle}>File type:</label>
                      <br/>
                      {isEvent ? (<select
                        id="file_type"
                        name="file_type"
                        value={formData.file_type}
                        onChange={handleChange}
                        className={styles.styledInput}
                      >
                          <option value="9">Still Image</option>
                          <option value="1">Video</option>
                      </select>) : <span style={{fontSize: 18}}>Video</span>}
                      <br/>
                      <label htmlFor="stream_type" className={styles.labelTitle}>Stream type:</label>
                      <br/>
                      {isEvent && <select
                        id="stream_type"
                        name="stream_type"
                        value={formData.stream_type}
                        onChange={handleChange}
                        className={styles.styledInput}
                      >
                          <option value="2">Standard Resolution</option>
                          <option value="1">High Resolution</option>
                      </select>}
                      {!isEvent && <select
                        id="stream_type"
                        name="stream_type"
                        value={formData.stream_type}
                        onChange={handleChange}
                        className={styles.styledInput}
                      >
                          <option value="2">Standard Resolution</option>
                          <option value="1">High Resolution</option>
                          <option value="0">Default</option>
                      </select>}
                      <br/>
                      {isEvent && <div className={styles.flex}>
                          <div>
                              <label htmlFor="before" className={styles.labelTitle}>Before event:</label>
                              <br/>
                              <input
                                type="number"
                                id="before"
                                name="before"
                                value={formData.before}
                                onChange={handleChange}
                                placeholder="1-60 sec"
                                className={`${styles.styledInput} ${styles.seconds}`}
                                min="1"
                                max="60"
                                required
                              />
                          </div>
                          <div>
                              <label htmlFor="after" className={styles.labelTitle}>After event:</label>
                              <br/>
                              <input
                                type="number"
                                id="after"
                                name="after"
                                value={formData.after}
                                onChange={handleChange}
                                placeholder="1-60 sec"
                                className={`${styles.styledInput} ${styles.seconds}`}
                                min="1"
                                max="60"
                                required
                              />
                          </div>
                      </div>}
                      {!isEvent && <div className={styles.flex}>
                          <AddPointTimeInput
                            type="before"
                            label="Before event"
                            totalSeconds={parseInt(formData.before) || 0}
                            onChange={handleChangeTime}
                          />
                          <AddPointTimeInput
                            type="after"
                            label="After event"
                            totalSeconds={parseInt(formData.after) || 0}
                            onChange={handleChangeTime}
                          />
                      </div>}
                      <div>
                          {availableTime && <p className={styles.labelTitle}>Time
                              Available:{moment.utc(moment.duration(availableTime, 'seconds').as('milliseconds')).format('HH:mm:ss')}</p>}
                          <p className={styles.labelTitle}>Time Needed for
                              download:{formatDuration(estimatedDownloadTime)}</p>
                      </div>
                      <hr/>
                      <div className={styles.buttons_block}>
                          <Button
                            type="submit"
                            variant="contained"
                          >
                              Create
                          </Button>
                          <Button
                            type={'button'}
                            variant="outlined"
                            onClick={onCloseForm}
                          >
                              Cancel
                          </Button>
                      </div>
                  </form>)
                )}
            </div>
        </Modal>
    )
}

export default AddAlarmIdModalForm;
