import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {pathOr, values} from 'ramda';

import {iDeviceDetails, iFullStoreState, iItemsAllowed, iList, iTag, ItemType} from '../../../../shared/interfaces';
import {IPropsFromStore, StateInterface, itemStyle} from './PersonCanSee.types';
import {
  readOnlyAccessToggle,
  removeAllowedSee,
  setAllowedSee,
  watchPersonAllowedSee
} from '../../../../shared/db/general-db';
import instance from '../../../../api/instance';
import StandardItem from '../../../general/standard-item';
import ModalHeader from '../../../menus/modal/modal-header';
import {Row} from '../../../elements/flex';
import {Fa} from '../../../elements/fa';
import {SearchGridOld} from '../../../SearchGridOld/SearchGridOld';
import TableSimple from '../../../elements/TableSimple';
import Checkbox from '../../../elements/Checkbox';
import DashboardBlock from '../../../DashboardBlock';

import {faSpinner} from '@fortawesome/fontawesome-free-solid';
import canSeeStyles from '../PersonCanSee/PersonCanSee.module.scss';

const mapStateToProps = (s: iFullStoreState, o: any): IPropsFromStore => {
  return {
    person: s.general.people[o.match.params.id],
    tags: s.general.tags,
    devicesDetails: s.devicesData.devicesDetails,
    authUser: s.auth.user!
  }
}

const AllBtn = ({onAllClick}) => (
  <button
    onClick={onAllClick}
    className={canSeeStyles.buttonStyles}
  >
    Select All
  </button>
)

const PersonCanSeeTest = (props) => {
  const [tagFilter, setTagFilter] = useState<string>('');
  const [deviceFilter, setDeviceFilter] = useState<string>('');
  const [readOnlyAccess, setReadOnlyAccess] = useState<{[key: string]: boolean}>();
  const [allowed, setAllowed] = useState<iItemsAllowed>();

  const {person, devicesDetails, tags} = props;
  const devicesIds = devicesDetails.keySeq();

  let canceller;
  const helpMsg = `
        Manage which devices a user can view. Adding a tag will allow this person to see all devices that have the associated tag.
        To prevent a user from seeing a device make sure device overrides and appropriate tags are removed. The complete list
        of seeable devices is at the very bottom.
     `;

  useEffect(() => {
    const {person} = props;

    canceller = watchPersonAllowedSee(person.id, (allowed) => {
      setAllowed(allowed);

      const devicesAccess = allowed?.device
        ? Object.keys(allowed.device).reduce((acc, rec) => {
          return { ...acc, [rec]: allowed.device[rec].readOnly || false };
        }, {})
        : {};

      setReadOnlyAccess({...devicesAccess});
    })

    return () => {
      if (canceller) canceller();
    }
  }, []);

  const toggleTagFlag = async (tagId) => {
    const {tags, person} = props;

    const isRemove = pathOr(false, [
      tagId,
      'instances',
      'allowed-see',
      'person',
      person.id,
    ])(tags);

    await (isRemove ? removeAllowedSee : setAllowedSee)(
      props.authUser
    )(props.person.id, ItemType.tag, tagId);

    instance.post('/api-firebase/revoke', {uid: props.person.id});
  }

  const toggleDeviceFlag = async (deviceId, tagId = null) => {
    const isRemove = pathOr(false, ['device', deviceId, deviceId])(allowed);

    await (isRemove ? removeAllowedSee : setAllowedSee)(
      props.authUser
    )(props.person.id, ItemType.device, deviceId);

    instance.post('/api-firebase/revoke', {uid: props.person.id});
  }

  const filterChange = (key: keyof StateInterface) => (str) => {
    if(key === 'tagFilter') setTagFilter(str);
    else if(key === 'deviceFilter') setDeviceFilter(str);
  }

  const getSource = (deviceId) => {
    if (allowed && allowed.device) {
      return Array.from(new Set(Object.values(allowed.device[deviceId]).filter(e => typeof e !== 'boolean'))).join(' & ');
    }

    return '';
  }

  const deviceItems = devicesDetails
    .map((device, deviceId) => (
      <StandardItem
        key={deviceId}
        style={itemStyle(
          pathOr(false, ['device', deviceId, deviceId])(allowed)
        )}
        displayName={device.name}
        itemId={deviceId}
      />
    ))
    .valueSeq()
    .toArray();

  const tagItems = values(tags).map((tag) => (
    <StandardItem
      style={itemStyle(
        pathOr(false, ['instances', 'allowed-see', 'person', person.id])(
          tag
        )
      )}
      key={tag.details.id}
      displayName={tag.details.name}
      itemId={tag.details.id}
    />
  ));

  const searchTagItems = tagItems.filter(({ props }) =>
    props.displayName?.toLowerCase().includes(tagFilter.toLowerCase())
  );

  const searchDeviceItems = deviceItems.filter(({ props }) =>
    props.displayName?.toLowerCase().includes(deviceFilter.toLowerCase())
  );

  const handleSelectAll = () => {
    const visibleDeviceIds = Object.keys(allowed?.device || {})
      .filter((deviceId) => devicesIds.includes(deviceId))
      .map((deviceId) => devicesDetails.get(deviceId)!);

    const devicesWithNewStatus = visibleDeviceIds
      .map((device: iDeviceDetails) => ({[device.id]: !readOnlyAccess?.[device.id]}))
      .reduce((result, obj) => {
        return {...result, ...obj};
      }, {});

    setReadOnlyAccess({...readOnlyAccess, ...devicesWithNewStatus});

    visibleDeviceIds.map(device => {
      readOnlyAccessToggle(props.authUser)(
        props.person.id,
        device.id,
        !readOnlyAccess?.[device.id]
      )
    });

    return;
  }

  return (
    <DashboardBlock>
      <ModalHeader
        title={person ? person.displayName : ''}
        help={helpMsg}
      />

      {!allowed ? (
        <Row justify='center'>
          <Fa icon={faSpinner} spin style={{ fontSize: 28 }} />
        </Row>
      ) : (
        <>
          <SearchGridOld
            style={{ marginTop: 10, maxWidth: 350 }}
            textual={true}
            list={tagFilter ? searchTagItems : tagItems}
            filterStr={tagFilter}
            placeholder='Search Tags...'
            perPage={9}
            keyClicked={toggleTagFlag}
            filterChange={filterChange('tagFilter')}
          />
          <SearchGridOld
            style={{ marginTop: 10, maxWidth: 350 }}
            textual={true}
            list={deviceFilter ? searchDeviceItems : deviceItems}
            filterStr={deviceFilter}
            placeholder='Search Devices...'
            perPage={9}
            keyClicked={toggleDeviceFlag}
            filterChange={filterChange('deviceFilter')}
          />

          {!!allowed.device && (
            <>
              <div className={canSeeStyles.headerBlock}>
                <p className={canSeeStyles.title}>Visible list</p>
                <AllBtn onAllClick={handleSelectAll} />
              </div>
              <TableSimple
                sm
                dark
                striped
                headers={['Label', 'Source', 'Access']}
              >
                {Object.keys(allowed.device || {})
                  .filter((deviceId) => devicesIds.includes(deviceId))
                  .map((deviceId) => devicesDetails.get(deviceId)!)
                  .map((device: iDeviceDetails) => (
                    <tr key={device.id}>
                      <td
                        style={{
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          maxWidth: 170,
                        }}
                      >
                        {device.name}
                      </td>
                      <td>{getSource(device.id)}</td>
                      <td>
                        <Checkbox
                          styles={{ marginTop: 6 }}
                          labelStyles={{ fontSize: 16, color: 'white' }}
                          inputId={device.id}
                          onChange={() =>
                            readOnlyAccessToggle(props.authUser)(
                              props.person.id,
                              device.id,
                              !readOnlyAccess?.[device.id]
                            )
                          }
                          checked={readOnlyAccess?.[device.id]}
                          label='Read only'
                        />
                      </td>
                    </tr>
                  ))}
              </TableSimple>
            </>
          )}
        </>
      )}
    </DashboardBlock>
  )
}

export default connect(mapStateToProps)(PersonCanSeeTest);