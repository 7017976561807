import {AnyAction} from 'redux';

import {OPEN_DASHBOARD, CLOSE_DASHBOARD, FULL_OPEN_DASHBOARD, FULL_CLOSE_DASHBOARD, DVIR_OPEN_DASHBOARD, DVIR_CLOSE_DASHBOARD} from './AC';
import {isMobile} from '../../../shared/helpers';

export enum DashboardSize {
    CLOSED = 'CLOSED',
    OPEN = 'OPEN',
    OPEN_FULL = 'OPEN_FULL',
    DVIR_FULL = 'DVIR_FULL'
}

export type IReducerDashboardInfo = {
    size: DashboardSize;
}

const initState: IReducerDashboardInfo = {size: isMobile || window['cordova'] ? DashboardSize.OPEN_FULL : DashboardSize.OPEN};

export default (state: IReducerDashboardInfo = initState, action: AnyAction): IReducerDashboardInfo => {
    switch (action.type) {
    case FULL_OPEN_DASHBOARD: {
        return {
            ...state,
            size: DashboardSize.OPEN_FULL,
        };

    }
    case DVIR_OPEN_DASHBOARD: {
        return {
            ...state,
            size: DashboardSize.DVIR_FULL
        }
    }
    case DVIR_CLOSE_DASHBOARD: {
        return {
            ...state,
            size: DashboardSize.OPEN
        }
    }
    case FULL_CLOSE_DASHBOARD: {
        return {
            ...state,
            size: DashboardSize.CLOSED,
        };

    }
    case OPEN_DASHBOARD:
        if (state.size === DashboardSize.CLOSED) {
            return {
                ...state,
                size: DashboardSize.OPEN,
            };
        }

        if (state.size === DashboardSize.OPEN) {
            return {
                ...state,
                size: DashboardSize.OPEN_FULL,
            };
        }

        return state;

    case CLOSE_DASHBOARD:
        if (state.size === DashboardSize.OPEN_FULL) {
            return {
                ...state,
                size: DashboardSize.OPEN,
            };
        }

        if (state.size === DashboardSize.OPEN) {
            return {
                ...state,
                size: DashboardSize.CLOSED,
            };
        }

        return state;
    default:
        return state;
    }
};
