import React, {CSSProperties} from 'react';
import {iTag} from '../../../shared/interfaces';
import {Fa} from '../../elements/fa';
import {faBell} from '@fortawesome/fontawesome-free-solid';

import styles from './TagToElement.module.scss';

interface Props {
  tag: iTag;
  isSelected: boolean;
}

export const TagToElement = (props: Props) => {
  const style: CSSProperties = ({
    color: props.isSelected ? '#2f76c3' : 'inherit',
    fontWeight: props.isSelected ? 'bold' : 'normal',
  });

  return (
    <div className={styles.TagToElement} style={style} title={props.tag.details.name}>
      {props.tag.details.name} {!props.tag.details.isAlertType ? null : <Fa icon={faBell} style={{position: 'absolute', right: 4}} />}
    </div>
  )
}