import React, {useState} from "react";
import {toast} from "react-toastify";
import CreditCard from "../../../../CreditCard";
import Modal from "../../../../Modal";
import TariffItem from "./TariffItem";
import styles from "../ContinueStreamModal/ContinueStreamModal.module.scss";
import {buyTime, getBillingCards} from "../../../../../api/openApi";
import { Fa } from "../../../../elements/fa";
import { faSpinner } from "@fortawesome/fontawesome-free-solid";
import { ITariffs } from "../interfaces";
import {useHistory} from "react-router-dom";

const BuyMoreModal = ({firebaseDeviceId = undefined, closeShowBuyMoreModal, currency, streamingTariffs, reset, setIsControls, setIsPlaying}): JSX.Element => {
    const BUY_MORE_TIME: string = "Your available time to live stream this device has been exhausted. You may do a one time purchase for more time or contact our sales department to change your plan.";

    const [loading, setLoading] = useState<boolean>(false);
    const [isBuyMore, setIsBuyMore] = useState<boolean>(false);
    const [showCards, setShowCards] = useState<boolean>(false);
    const [selectedTariff, setSelectedTariff] = useState<ITariffs | null>(null);
    const [billingCards, setBillingCards] = useState([]);
    const [selectedCard, setSelectedCard] = useState(null);

    const history = useHistory();

    const getMoreTime = async (): Promise<void> =>{
        if(selectedTariff) {
            setLoading(true);
            console.log('get cards')
            getBillingCards()
                .then((data) => setBillingCards(data))
                .catch(({message}) => toast.error(message, { position: toast.POSITION.TOP_RIGHT }))
                .finally(() => {setShowCards(true); setLoading(false)})
        }
    }

    const purchaseMoreTime = async (): Promise<void> => {
        const { id } = selectedCard;
        setLoading(true)
        const { billed } = await buyTime({firebaseDeviceId: firebaseDeviceId, tariff: selectedTariff, cardId: id}).finally(() => setLoading(false))
        if(billed) {
            setLoading(false);
            reset();
            setIsControls(true);
            setIsPlaying(true);
        }
    }

    const selectCard = (card) => {
        setSelectedCard(card)
    };

    const goToBilling = () => {
        history.push("/billing/units");
    }

    return (
        <Modal className={styles.modalContinueLive}>
            {
                !isBuyMore ? <p>{BUY_MORE_TIME}</p> :
                    (
                        <> {!showCards && streamingTariffs && (
                            <ul className={styles.tariffsList}>
                                {
                                    Object.keys(streamingTariffs).map((key, index)=> {
                                        return <TariffItem
                                            price={streamingTariffs[key].price}
                                            currency={currency}
                                            minutes={streamingTariffs[key].minutes}
                                            setSelectedTariff={setSelectedTariff}
                                            tariff={key}
                                        />
                                    })
                                }
                            </ul>
                        )}
                        </>
                    )
            }

            {showCards &&
                billingCards?.length > 0 && (
                billingCards.map((item) => (
                    <CreditCard
                        key={item.id}
                        type={item.type}
                        primary={item.type.toLowerCase() === 'bank account'}
                        creditInfo={item}
                        onSelectCard={() => selectCard(item)}
                        selectedCardId={selectedCard?.id}
                    />
                ))
            )}

            {showCards && !(billingCards?.length > 0) && (
                <div>
                    <p> Seems you don't have an credit card / bank account connected.</p>
                    <button className={styles.goToBilling} type='button' onClick={() => goToBilling()}> Go to the billing page </button>
                </div>
            )}

            <div className={styles.modalContinueLive__buttons}>
                {!showCards ? (
                    <button onClick={()=> !isBuyMore ? setIsBuyMore(true) : getMoreTime()}>
                        {loading ? <Fa icon={faSpinner} spin /> : 'Choose plan'}
                    </button>
                ) : (
                    billingCards?.length > 0 ? (
                        <button onClick={()=> purchaseMoreTime()}>
                            {loading ? <Fa icon={faSpinner} spin /> : 'Buy more'}
                        </button>
                    ) : null
                )}
                <button onClick={closeShowBuyMoreModal}>Close</button>
            </div>
        </Modal>
    );
};

export default BuyMoreModal;
