import React from "react";
import styles from './styles.module.scss';

const DvirFormHeadingInput = ({name, description, isHeaderNameError, headerNameErrorMsg, onChangeName, onChangeDescription, onClickErrorBlock, addHeading}) => {
  return (
    <div className={styles.CreateDvirFormHeadersContainer}>
      <h3>Add new heading</h3>
      {isHeaderNameError && (
        <div className={styles.CreateDvirFormError}>
          <p>{headerNameErrorMsg}</p>
          <span onClick={onClickErrorBlock}>&#x2715;</span>
        </div>
      )}
      <div className={styles.CreateDvirFormName}>
        <label htmlFor='heading-name'>Heading Name</label>
        <input
          type='text'
          id='heading-name'
          placeholder='Enter heading name:'
          value={name}
          onChange={onChangeName}
        />
      </div>
      <div className={styles.CreateDvirFormDescription}>
        <label>Heading Brief Description</label>
        <textarea
          id='form-description'
          placeholder='Enter heading description:'
          value={description}
          onChange={onChangeDescription}
        />
      </div>
      <button onClick={addHeading} className={styles.AddHeadingBtn}>Add</button>
    </div>
  )
}

export default DvirFormHeadingInput;
