import { CSSProperties as css } from 'react';
import * as color from 'color';
import {
  faWifi,
  faUser,
  faUsers,
  faTag,
  faTags,
  faTachometerAlt,
  faEye,
} from '@fortawesome/fontawesome-free-solid';
import { getReadOnlyStatus, getTagReadOnlyStatus } from './db/general-db';
import { iDefaultSettings, ItemType, UserAuth } from './interfaces';
import { resolve } from 'path';

export const AUDIT_LOG = 'audit-log';
export const IS_MOBILE_APP = !!window['cordova'];
export const IS_PUBLIC_VIEW = window.location.href.includes('public-view');

export default abstract class Constants {
  static opacity = 0.9;
  static fade = 1 - Constants.opacity;
  static lightGray = color('#e5e5e5');
  static mediumGray = color('#999');
  static primaryText = color('#424250');
  static primaryColor = color('#2f76c3');
  static darkGray = color('#6a6a6a');
  static yellow = color({ r: 247, g: 216, b: 45 });
  static blackMain = color('#111111');

  static simpleDateFormat = 'MM-DD-YYYY';

  static bucketFormat = 'YYYYQ';

  static mobileBreak = 768;

  static faIcos = {
    device: faWifi,
    person: faUser,
    people: faUsers,
    tag: faTag,
    tags: faTags,
    alerts: faTachometerAlt,
    eye: faEye,
  };

  static alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('');
  static alphanums = Constants.alphabet.concat('0123456789'.split(''));

  static editFenceOverlayBgColor = color('#6a6a6a').fade(0.5);

  static fenceFillOpacity = 0.35;

  static checkCss: css = {
    position: 'absolute',
    overflow: 'hidden',
    clip: 'rect(0 0 0 0)',
    height: '1px',
    width: '1px',
    margin: '-1px',
    padding: '0',
    border: '0',
  };

  static inputCss = {
    border: '1px solid ' + Constants.primaryColor,
    padding: 2,
    outline: 'none',
  } as any;

  static selectCss = {
    border: '1px solid ' + Constants.primaryColor,
    padding: 2,
    backgroundColor: '#ECECEC',
    outline: 'none',
  } as any;

  static chooserColors = [
    '#333333',
    '#B80000',
    '#DB3E00',
    '#FCCB00',
    '#008B02',
    '#006B76',
    '#1273DE',
    '#004DCF',
    '#5300EB',
    '#EB9694',
    '#FAD0C3',
    '#FEF3BD',
    '#C1E1C5',
    '#BEDADC',
    '#C4DEF6',
    '#BED3F3',
    '#D4C4FB',
  ];
}

export enum UserCan {
  CHANGE_ACL = 'change-acl',
  DO_ANYTHING = 'do-anything',
  CREATE_TAGS = 'create-tags',
  TAG_THINGS = 'tag-things',
  LABEL_THINGS = 'label-things',
  CREATE_LABEL = 'create-label',
  EDIT_DEVICE = 'edit-device',
  EDIT_PEOPLE = 'edit-people',
  EDIT_NOTES = 'edit-notes',
  AUTH = 'auth',
  SEE_FENCES = 'see-fences',
  EDIT_FENCES = 'edit-fences',
  LABEL_TRIPS = 'label-trips',
  HIDE_POINTS = 'hide-points',
  ASSIGN_DRIVER = 'assign-driver',
  SEE_ALL_REPORTS = 'see-all-reports',
  SET_MAINTENANCE = 'see-maintenance',
  SEE_TAGS = 'see-tags',
  FILTER_REPORTS_BY_PEOPLE = 'filter-reports-by-people',
  SEE_TAPPED_PEOPLE = 'see-tapped-people',
  SUB_USER = 'sub-user',
  SUB_ADMIN = 'sub-admin',
  SEE_DVIR = 'see-dvir'
}
export abstract class ACL {
  static check = (
    checkPermission: UserCan,
    userPermissions?: string[],
    adminCheck = false
  ) => {
    if (
      (userPermissions || []).indexOf(UserCan.DO_ANYTHING) !== -1 &&
      !adminCheck
    )
      return true;
    return (userPermissions || []).some(
      (permission) => permission == checkPermission
    );
  };

  static checkExtended = (
    checkPermission: UserCan[],
    userPermissions: UserCan[]
  ) => {
    if ((userPermissions || []).indexOf(UserCan.DO_ANYTHING) != -1) return true;

    for (const permissionToCheck of checkPermission) {
      if (!userPermissions.includes(permissionToCheck)) {
        return false;
      }
    }

    return true;
  };

  static checkDriver = (
    checkPermission: UserCan,
    userPermissions?: string[]
  ) => {
    return (
      (userPermissions || []).indexOf(UserCan.DO_ANYTHING) == -1 &&
      (userPermissions || []).some(
        (permission) => permission == UserCan.ASSIGN_DRIVER
      )
    );
  };

  static checkEditResource = async ({
    resourceId,
    itemType,
    userId,
  }: {
    resourceId: string;
    itemType: ItemType;
    userId: string;
  }) => {
    let res;
    switch (itemType) {
      case ItemType.device:
        res = !(await getReadOnlyStatus(userId, resourceId));
      default:
        res = !(await getTagReadOnlyStatus(userId, resourceId));
    }
    return res;
  };

  static checkPermissionAndEditResource = async ({
    itemType,
    itemId,
    user,
  }: {
    itemType: ItemType;
    itemId: string;
    user: UserAuth;
  }) => {
    let res;
    switch (itemType) {
      case ItemType.device: {
        // const canEditDevices = ACL.check(UserCan.EDIT_DEVICE, user.acl.can);
        // if (!canEditDevices) return false;
        res = !(await getReadOnlyStatus(user.uid, itemId));
        break;
      }
      case ItemType.tag: {
        //const canEditTags = ACL.check(UserCan.TAG_THINGS, user.acl.can);
        //if (!canEditTags) return false;
        res = !(await getTagReadOnlyStatus(user.uid, itemId));
        break;
      }
      default: {
        // const canEditTags = ACL.check(UserCan.TAG_THINGS, user.acl.can);
        // const canEditFences = ACL.check(UserCan.EDIT_FENCES, user.acl.can);
        // if (!(canEditTags && canEditFences)) return false;
        res = !(await getTagReadOnlyStatus(user.uid, itemId));
      }
    }
    return res;
  };
}

export enum ICON_NAMES {
  micro = 'micro',
  big_truck = 'big_truck',
  cabriolet = 'cabriolet',
  campervan = 'campervan',
  coupe = 'coupe',
  cuv = 'cuv',
  hatchback = 'hatchback',
  mini_truck = 'mini_truck',
  minivan = 'minivan',
  pickup = 'pickup',
  roadster = 'roadster',
  sedan = 'sedan',
  supercar = 'supercar',
  suv = 'suv',
  truck = 'truck',
  van = 'van',

  vehicle = 'vehicle',
  vehicle1 = 'vehicle1',
  vehicle2 = 'vehicle2',
  vehicle3 = 'vehicle3',
  vehicle4 = 'vehicle4',
  vehicle5 = 'vehicle5',
  vehicle6 = 'vehicle6',
  vehicle7 = 'vehicle7',
  vehicle8 = 'vehicle8',
  vehicle9 = 'vehicle9',
  vehicle10 = 'vehicle10',
  vehicle11 = 'vehicle11',
  vehicle12 = 'vehicle12',
  vehicle13 = 'vehicle13',
  vehicle14 = 'vehicle14',
  vehicle15 = 'vehicle15',
  vehicle16 = 'vehicle16',
}

export const ICON_NAMES_BY_TABS = {
  transportation: [
    'micro',
    'big_truck',
    'cabriolet',
    'campervan',
    'coupe',
    'cuv',
    'hatchback',
    'mini_truck',
    'minivan',
    'pickup',
    'roadster',
    'sedan',
    'supercar',
    'suv',
    'truck',
    'van',
  ],
  construction: [
    'vehicle',
    'vehicle1',
    'vehicle2',
    'vehicle3',
    'vehicle4',
    'vehicle5',
    'vehicle6',
    'vehicle7',
    'vehicle8',
    'vehicle9',
    'vehicle10',
    'vehicle11',
    'vehicle12',
    'vehicle13',
    'vehicle14',
    'vehicle15',
    'vehicle16',
  ],
  buildings: [],
};

export const getIconTabByName = (iconName: string): string => {
    if (ICON_NAMES_BY_TABS.transportation.includes(iconName)) {
        return ICON_TABS[0].toLowerCase();
    }

    if (ICON_NAMES_BY_TABS.construction.includes(iconName)) {
        return ICON_TABS[1].toLowerCase();
    }

    if (ICON_NAMES_BY_TABS.buildings.includes(iconName)) {
        return ICON_TABS[2].toLowerCase();
    }

    return ICON_TABS[3].toLowerCase();
}

export const ICON_TABS: string[] = [
  'Transportation',
  'Construction',
  'Buildings',
  'Other',
];

export enum FB_EVENTS_IDS {
  SOS = 4,
  Speed = 6,
  LOW_POWER = 7,
  POWER_OFF = 8,
  POWER_ON = 9,
  STOP_TIME = 10,
  // HARSH_BRAKING = 11,
  // RAPID_ACCELERATION = 12,
  // IDLE = 13,
  // VOLTAGE_LOW = 14,
  IGNITION_ON = 15,
  IGNITION_OFF = 16,
  POWER_DISCONNECT = 17,
  POTENTIAL_ROLLOVER = 18,
  POTENTIAL_COLLISION = 19,
  HARD_TURN = 20,
  CHECK_ENGINE_LIGHT_ON = 21,
  NEW_VEHICLE = 22,
  FUEL_LOSS = 23,
  REFUEL = 24,
  ENABLE_SOUND_EVENTS = 25,
  // HARSH_BRAKING = 26,
  // RAPID_ACCELERATION = 27,
  // OUTPUT = 28,
  // INPUT = 29,
  // POSTED_SPEED_LIMIT = 30,
  POWER_RESET = 31,
  // TOW = 32,
  SPEED = 33,
  VOLTAGE_LOW = 34,
  // TOW = 35,
  POWER_UP = 36,
  POSTED_SPEED_LIMIT = 37,
  INPUT = 38,
  OUTPUT = 39,
  POWER_RECONNECT = 40,
  HARSH_BRAKING_SOUND = 41,
  RAPID_ACCELERATION_SOUND = 42,
  SPEED_SOUND = 43,
  IDLE_SOUND = 44,
  POWER_DOWN = 45,
  ENTER_FENCE = 46,
  EXIT_FENCE = 47,
  HARSH_BRAKING = 50,
  RAPID_ACCELERATION = 51,
  IDLE = 52,
  TOW = 53,
}

export const defaultSettingsTemplate: iDefaultSettings = {
  timezone: '',
};
export const DEV_CAPTCHA_KEY = '6LcYBAAdAAAAAASE3p6HxNDFvRpd1TaDmfxb31ff';

export const defaultSettingsNaming = {
  timezone: 'Time zone',
};

export enum VIDEO_STATUSES {
  PENDING = "Pending",
  DOWNLOADED = "Downloaded",
}

export const SECONDS_IN_DAY = 86400;

export const WEEK_DAYS = ['S', 'M', 'T', 'W', 'TH', 'F', 'S'];
