import React, {useContext, useState, useRef} from 'react';
import {connect} from 'react-redux';
import { GithubPicker } from 'react-color';

import {IconTypes, iFullStoreState, iIcon} from '../../../../shared/interfaces';
import {extractDevice, noSubmit} from '../../../../shared/helpers';
import DashboardBlock from '../../../DashboardBlock';
import ModalActions from '../../../menus/modal/modal-actions';
import ModalHeader from '../../../menus/modal/modal-header';
import C, {ICON_NAMES} from '../../../../shared/constants';
import LocalTile from './LocalTile';
import Timezones from './Timezones';
import Dialog, {DialogConfigSetter} from '../../../Dialog';
import * as devicedb from '../../../../shared/db/devices-db';
import {IconDefinition} from '@fortawesome/fontawesome';
import * as generaldb from '../../../../shared/db/general-db';
import {selectIconContext} from '../../../../shared/contexts/selectIcon.context';

import styles from './DeviceTabPageEdit.module.scss';
import SvgIcon from '../../../SvgIcon/SvgIcon';

const defaultColor = '#333';

const mapStateToProps = (state: iFullStoreState, ownProps) => ({
  authUser: state.auth.user,
  device: extractDevice(
    state.devicesData.devicesDetails,
    ownProps.match.params.id
  ),
  deviceId: ownProps.match.params.id,
});

const DeviceTabPageEdit = (props) => {
  const [deviceEdits, setDeviceEdits] = useState<Record<string, any>>({} as any);
  const [svgIcon, setSvgIcon] = useState();
  const {device} = props;

  const { selectIcon } = useContext(selectIconContext);

  const dialogRef = useRef<DialogConfigSetter>();
  const dialog = dialogRef.current;
  const setupDialog = (callBack: () => DialogConfigSetter) =>
    (dialogRef.current = callBack());

  if (!device) return null;

  const timezoneData = deviceEdits.timezone || device.timezone;
  const icon = deviceEdits.icon ?? device.icon;

  const cancelEdit = () => {
    setDeviceEdits({});
  }

  const update = async () => {
    try {
      if (svgIcon) {
        await devicedb.updateDeviceDetails(props.authUser)(
          props.deviceId,
          {
            ...deviceEdits,
            icon: svgIcon,
          }
        );
      } else {
        await devicedb.updateDeviceDetails(props.authUser)(
          props.deviceId,
          deviceEdits
        );
      }
    } catch (e) {
      await dialog?.({
        title: 'Permission error',
        body: 'You dont have permission to edit devices, please contact admin',
        type: 'NOTIFICATION',
      });
    }

    setDeviceEdits({});
    setSvgIcon(null);
  }

  const changeImage = async () => {
    const selectedIcon = await selectIcon?.();
    let icon: iIcon;

    if (!selectedIcon || !(typeof selectedIcon == 'object')) {
      return;
    }

    if ('icon' in selectedIcon) {
      icon = {
        type: IconTypes.FaIcon,
        fa: selectedIcon as IconDefinition,
      };
    } else if ('name' in selectedIcon && ICON_NAMES[selectedIcon.name]) {
      icon = {
        type: IconTypes.SvgIcon,
        svgIconName: selectedIcon.name as string,
      };
    } else {
      icon = {
        type: IconTypes.UserSvgIcon,
        url: await generaldb.saveIcon(selectedIcon as File),
      };
    }

    setDeviceEdits({...deviceEdits, icon});
  };

  const setColor = ({ hex }) => {
    setDeviceEdits({...deviceEdits, color: hex});
  }

  const editName = (e) => {
    setDeviceEdits({...deviceEdits, name: e.target.value});
  }

  const editUnits = (e) => {
    setDeviceEdits({...deviceEdits, units: e.target.value});
  }

  const editTimezone = (timezone) => {
    setDeviceEdits({...deviceEdits, timezone})
  }

  return (
    <DashboardBlock
      overlay={
        Object.keys(deviceEdits).length ? (
          <ModalActions
            buttons={[
              { click: cancelEdit, title: 'Cancel' },
              { click: update, title: 'Update', primary: true },
            ]}
          />
        ) : undefined
      }
    >
      <ModalHeader title='Edit Device' />
      <div className={styles.image} onClick={changeImage}>
        {icon ? (
          <SvgIcon
            size='lg'
            color={deviceEdits.color || device.color || defaultColor}
            icon={icon}
          />
        ) : (
          'Choose Icon'
        )}
      </div>
      {!icon?.url && (
        <div className={styles.colorPicker}>
          <GithubPicker onChange={setColor} colors={C.chooserColors} />
        </div>
      )}
      <form onSubmit={noSubmit} style={{marginTop: '20px'}}>
        <LocalTile title='Device Title'>
          <input
            key='title-input'
            className={`mas-input ${styles.titleInput}`}
            name='name'
            value={
              deviceEdits.hasOwnProperty('name')
                ? deviceEdits.name
                : device.name
            }
            onChange={editName}
          />
        </LocalTile>
        <LocalTile title='Time Zone'>
          <Timezones
            onChange={editTimezone}
            value={timezoneData}
          />
        </LocalTile>
        <LocalTile title='Units'>
          <select
            key='units'
            className={`mas-input ${styles.selectUnits}`}
            onChange={editUnits}
            value={deviceEdits.units || device.units || 'miles'}
          >
            <option value='miles'>Miles</option>
            <option value='kilo'>Kilometers</option>
            <option value='knots'>Knots</option>
          </select>
        </LocalTile>
      </form>
      <Dialog setupConfig={setupDialog} />
    </DashboardBlock>
  )
}

export default connect(mapStateToProps)(DeviceTabPageEdit);