// import axios from "axios";
// import AuthInterceptor from "./auth-interceptor";

import axios from "axios";
import instance from "./instance";

// const mapApiInstance = axios.create({
//     baseURL: process.env.REACT_APP_API_URL
// });

// const mapApiPlaineInstance = axios.create({
//     baseURL: process.env.REACT_APP_API_URL
// });

// // export const axiosCall = (url, method, data) => axios({ url, method, data });
// class MapApi {
//     private authInterceptor = new AuthInterceptor(mapApiInstance, mapApiPlaineInstance)

//     public setCredentials = ({accessToken, refreshToken, ...rest}) => {
//         this.authInterceptor.setCredentials(accessToken, refreshToken);
//     }

//     public login = (data) => {
//         return mapApiPlaineInstance.post('/site/app-login', data);
//     }

//     public logout = () => {
//         return mapApiInstance.get(`${process.env.REACT_APP_API_URL}/site/api-logout` ,{
//             headers: {
//                 'Accept': 'application/json',
//                 'Content-Type': 'application/json',
//             },
//             data: null,
//         });
//     }

//     public isUserHaveFingerPrint(deviceId) {
//         return mapApiInstance.post(`${process.env.REACT_APP_API_URL}/api-fingerprint/has`, { "device_id": deviceId }, {
//             headers: {
//                 'Accept': 'application/json',
//                 'Content-Type': 'application/json',
//             }
//         })
//     }
// }

// export default new MapApi();

export const login = (data) => {
  return axios.post(`${process.env.REACT_APP_API_URL}/site/app-login`, data);
};

export const loginByToken = (token: string) => {
  return axios.post(`${process.env.REACT_APP_API_URL}/api/sub-user/login-sub-user-by-token`, {token: token}, {
  });

}

export const logout = () => {
  return axios.get(`${process.env.REACT_APP_API_URL}/site/api-logout`);
};

export const isUserHaveFingerPrint = (deviceId) => {
  return axios.post(`${process.env.REACT_APP_API_URL}/api-fingerprint/has`, { device_id: deviceId });
};
