import React, {useEffect, useState} from 'react';
import DashboardBlock from "../../../DashboardBlock";
import {RouteComponentProps, useHistory} from "react-router-dom";
import {connect, useDispatch, useSelector} from "react-redux";
import styles from "./styles.module.scss";
import {Fa} from "../../../elements/fa";
import {faArrowLeft, faSpinner} from "@fortawesome/fontawesome-free-solid";
import {dvirCloseDashboard, dvirOpenDashboard} from "../../../../stores/reducers/dashboardInfo/AC";
import {localStorage} from "../../../../shared/storage";
import {useRedux} from "../../../../states/redux-state";
import {iFullStoreState} from "../../../../shared/interfaces";
import {dvirInspectionMapping, dvirStatusMapping, formatDate, getDvirByKey} from "../utils";
import {Row} from "../../../elements/flex";
import { faChevronRight, faChevronDown } from '@fortawesome/fontawesome-free-solid';

interface iDvirProps extends RouteComponentProps<{ id: string }> {}

interface iDvirInfo {
  id: string,
  vehicle_name: string,
  vehicle_vin: string,
  firebase_key: string,
  device_key: string,
  person_key: string,
  status: string,
  created_at: string
}

interface iHeaderItem {
  id: string,
  firebase_key: string,
  header: string,
  description: string,
  sort_order: string,
  pre_status: string,
  post_status: string,
  maintenance_status: string
}

interface iDvirInspection {
  id: string,
  person_key: string,
  type: string,
  ip_address: string,
  browser_type: string,
  start_date: string,
  end_date: string,
  odometer: string,
  notes: string
}

const mapStateToProps = (state: iFullStoreState, ownProps: iDvirProps) => ({
  dvirId: ownProps.match.params.id,
});

const DvirInspection = ({title, type, inspections, headings}) => {
  const mapInspectionValue = (value: number) => {
    if (value === 1) return 'Pass';
    else if (value === 0) return 'N/A';
    else return 'Defect';
  }

  let key;

  if (type === 'pre-trip-inspection') {
    key = 'pre_status';
  }
  else if (type === 'post-trip-inspection') {
    key = 'post_status';
  }
  else {
    key = 'maintenance_staus';
  }

  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  return (
    <div className={styles.DvirInspection}>
      <div className={styles.DvirInspectionHeader}>
        <p>{title}</p>
        <Fa icon={isExpanded ? faChevronDown : faChevronRight} className={styles.DvirInspectionIcon} onClick={() => setIsExpanded(!isExpanded)}/>
      </div>
      {isExpanded && <div className={styles.DvirInspectionTable}>
        {headings.map((heading: iHeaderItem) => (
          <p>{`${heading.header} - ${mapInspectionValue(Number(heading[key]))}`}</p>
        ))}
      </div>}
    </div>
  )
}

const DvirItem = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const clientId = localStorage.get('active-client');
  const uid = useRedux((s) => s.auth.user.uid);
  const id = props.dvirId;
  const [dvirInfo, setDvirInfo] = useState<iDvirInfo>();
  const [dvirHeaders, setDvirHeaders] = useState<iHeaderItem[]>();
  const [dvirInspections, setDvirInspections] = useState<iDvirInspection[]>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [personName, setPersonName] = useState<string>('');
  const people = useSelector<iFullStoreState>(state => state.general.people);

  const getDvir = async () => {
    try {
      setIsLoading(true);

      const data = await getDvirByKey(id);

      setDvirInfo(data.dvir);
      setDvirHeaders(data.header_items);
      setDvirInspections(data.inspections);

      const person = people[data.dvir.person_key];

      if (person && person.displayName) setPersonName(person.displayName);
    }
    catch (e) {
      console.log(e)
    }

    setIsLoading(false);
  }

  useEffect(() => {
    dispatch(dvirOpenDashboard());

    getDvir();

    return () => {
      dispatch(dvirCloseDashboard());
    }
  }, []);

  return (
    <DashboardBlock>
      <div className={styles.DvirItemHeader}>
        <span className="no-print btn-link" style={{paddingLeft: 10, marginRight: 20}} onClick={() => history.goBack()}><Fa
          icon={faArrowLeft}/></span>
      </div>
      {isLoading && (
        <Row justify='center' align='center'>
          <Fa icon={faSpinner} spin style={{ fontSize: 30 }}/>
        </Row>
      )}
      {!isLoading && dvirInfo && <div className={styles.DvirItemContainer}>
        <table className={styles.DvirInfoTable}>
          <tr>
            <td>Vehicle Name</td>
            <td>{dvirInfo.vehicle_name}</td>
          </tr>
          <tr>
            <td>VIN Code</td>
            <td>{dvirInfo.vehicle_vin}</td>
          </tr>
          <tr>
            <td>Driver</td>
            <td>{personName}</td>
          </tr>
          <tr>
            <td>Date</td>
            <td>{formatDate(dvirInfo.created_at)}</td>
          </tr>
          <tr>
            <td>Status</td>
            <td>{dvirStatusMapping[dvirInfo.status]}</td>
          </tr>
        </table>
        {dvirInspections.length > 0 && <div className={styles.DvirInspections}>
          <h3>Inspections</h3>
          {dvirInspections.map(inspection => (
            <DvirInspection
              key={inspection.id}
              title={dvirInspectionMapping[inspection.type]}
              type={inspection.type}
              inspections={dvirInspections}
              headings={dvirHeaders}
            />
          ))}
        </div>}
      </div>}
    </DashboardBlock>
  )
};

export default connect(mapStateToProps)(DvirItem);
