import React, {useEffect, useState} from 'react';

import {Fa} from '../elements/fa';
import {faChevronDown, faChevronRight, faSpinner} from '@fortawesome/fontawesome-free-solid';
import {MenuPin} from '../elements/MenuPin';

import styles from './Tile.module.scss';

interface iTileProps {
  children: any,
  title?: string,
  style?: React.CSSProperties,
  loading?: boolean,
  action?: JSX.Element,
  collapsible?: boolean,
  initialCollapse?: boolean,
  deviceId?: string,
  property?: string,
  isPinned?: boolean
}

const tileStyle: React.CSSProperties = {
  width: '100%',
  userSelect: 'none'
};

const Title = ({text, action=false, collapsible, collapsed, toggle, loading = false, deviceId, property, isPinned}: any) => (
  <div className={styles.StandardTileTitle} onClick={toggle}>
    {loading
      ? <Fa style={{marginRight: 10, cursor: 'pointer'}} spin icon={faSpinner} />
      : !collapsible
        ? null
        : <Fa style={{marginRight: 10, cursor: 'pointer'}} icon={collapsed ? faChevronRight : faChevronDown} />
    }
    <div style={{flex: 1}}>{text}</div>
    {!action ? null : <div>{action}</div>}
    {deviceId && property && !collapsed && <MenuPin deviceId={deviceId} property={property} isPinned={isPinned}/>}
  </div>
);

const StandardTile = (props: iTileProps) => {
  const { style = {}, title, action, collapsible, children, loading=false, deviceId, property, isPinned, initialCollapse } = props;
  const [collapsed, setCollapsed] = useState<boolean>(() => {
    return property ? !isPinned : !!props.initialCollapse;
  });

  useEffect(() => {
    const value = property ? !isPinned : !!props.initialCollapse;
    setCollapsed(value);
  }, [isPinned, initialCollapse]);

  return (
    <div style={{...tileStyle, ...style}}>
      <Title
        loading={loading}
        text={title}
        action={action}
        collapsible={collapsible}
        collapsed={collapsed}
        toggle={() => setCollapsed(!collapsed)}
        deviceId={deviceId}
        property={property}
        isPinned={isPinned}
      />
      <div className={styles.StandardTileBody}>
        {collapsed ? null : children}
      </div>
    </div>
  )
}

export default StandardTile;