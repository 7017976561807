import React from "react";
import styles from './styles.module.scss';

const DvirFormInput = ({name, description, onChangeName, onChangeDescription, nameIsEmpty}) => {
  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      width: '90%',
      gap: '15px'
    }}>
      <div className={styles.CreateDvirFormName}>
        <label htmlFor='form-name'>Name</label>
        <input
          type='text'
          id='form-name'
          placeholder='Enter name:'
          value={name}
          onChange={onChangeName}
        />
      </div>
      {nameIsEmpty && <p className={styles.NameErrorMsg}>Name can't be empty</p>}
      <div className={styles.CreateDvirFormDescription}>
        <label>Description</label>
        <textarea
          id='form-description'
          placeholder='Enter description:'
          value={description}
          onChange={onChangeDescription}
        />
      </div>
    </div>
  )
}

export default DvirFormInput;
