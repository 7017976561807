import React from 'react';
import styles from './DeviceHeader.module.scss';
import {FormatAddress} from "../device-list";
import { ReactComponent as MapPointerSvg } from '../../../../assets/svg/map_pointer.svg';

const DeviceHeader = ({name, address}) => {
  return (
    <div className={styles.DeviceHeader}>
      <div className={styles.DeviceHeaderImage}>
        <img src='images/no_car_image.jpg' style={{width: '100%'}}/>
      </div>
      <div className={styles.DeviceHeaderInfo}>
        <h3>{name}</h3>
        <div className={styles.DeviceHeaderInfoLocation}>
          <FormatAddress style={{border: '2px solid black', padding: '10px', fontSize: '20px'}} address={address}/>
          <MapPointerSvg style={{height: '30px'}}/>
        </div>
      </div>
    </div>
  )
}

export default DeviceHeader;