import React, { useEffect, useMemo, useState } from 'react';
import { NavLink as Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useRedux } from '../../../states/redux-state';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { iFullStoreState } from '../../../shared/interfaces';
import {
  getFavoriteLinksSelector,
  iFav,
} from '../../../shared/db/favorites-selectors';
import MasTrackLogoIcon from '../../SVG-components/mastrack_logo';
import DashboardIcon from '../../SVG-components/dashboard';
import DeviceIcon from '../../SVG-components/device';
import PeopleIcon from '../../SVG-components/people';
import ListAltIcon from '../../SVG-components/list_alt';
import LayersIcon from '../../SVG-components/layers';
import Billing from '../../SVG-components/billing';

import { ReactComponent as MaintenanceIcon } from '../../../assets/svg/maintanance.svg';
import { ReactComponent as DVIRIcon } from '../../../assets/svg/form.svg';

import './styles.scss';
import { localStorage } from '../../../shared/storage';
import { ACL, UserCan } from '../../../shared/constants';
import useAutoOpenDashboardIfClosed from '../../../hooks/auto-open-dashboard-if-closed';
import SvgIcon from '../../SvgIcon/SvgIcon';
import Portal from '../../Portal';
import { fbapp } from '../../../shared/firebase';
import { logout as logoutAPI } from '../../../api';
import {resetRememberMeIfExists} from "../../../shared/helpers";

const PAGES = [
  {
    name: 'Dashboard',
    link: '/dashboard',
    Icon: DashboardIcon,
  },
  {
    name: process.env.REACT_APP_DEVICES_TAB_NAME || 'Devices',
    link: '/device',
    Icon: DeviceIcon,
    enabledForDriver: true,
  },
  {
    name: process.env.REACT_APP_PEOPLE_TAB_NAME || 'People',
    link: '/person',
    Icon: PeopleIcon,
    enabledForDriver: true,
  },
  {
    name: process.env.REACT_APP_REPORTS_TAB_NAME || 'Reports',
    link: '/reports',
    Icon: ListAltIcon,
  },
  {
    name: process.env.REACT_APP_TAGS_TAB_NAME || 'Tags',
    link: '/tags',
    Icon: LayersIcon,
  },
  {
    name: process.env.REACT_APP_BILLING_TAB_NAME|| 'Billing',
    link: '/billing',
    Icon: Billing,
  },
  {
    name: process.env.REACT_APP_MAINTENANCE_TAB_NAME || 'Maintenance',
    link: '/maintenance',
    Icon: MaintenanceIcon,
  },
  {
    name: process.env.REACT_APP_DVIR_TAB_NAME || 'DVIR',
    link: '/dvir',
    Icon: DVIRIcon,
  }
];

const MainMenu = () => {
  const [colors, setColors] = useState({
    colorDark: null,
    colorBlack: null,
    colorGunPowder: null,
    colorWhite: null,
    colorTangerine: null,
    colorEclipse: null,
    colorSnow: null,
    colorSuvaGrey: null,
  });

  const [logo, setLogo] = useState(null);
  const userCanDo = useSelector<iFullStoreState, Array<string>>(
    (s) => s.auth.user?.acl?.can
  );
  const isDisabled = useSelector<iFullStoreState, boolean>(
    (s) => {
      const userId = s.auth.user.uid;

      const person = Object.values(s.general.people).find(v => v.id === userId);

      return person?.disabled ?? false;
    }
  )
  const hideTags = !ACL.check(UserCan.SEE_TAGS, userCanDo, true);
  const hideMaintenance = !ACL.check(UserCan.SET_MAINTENANCE, userCanDo, true);
  const hideDvir = !ACL.check(UserCan.SEE_DVIR, userCanDo, true);
  const isDriver = useRedux((s) => s.auth.isDriver);
  const getPages = () => {
    if (isDriver) return PAGES.filter((page) => page.enabledForDriver);
    if (hideMaintenance && hideTags)
      return PAGES.filter(
        (page) => page.name !== 'Maintenance' && page.name !== 'Tags'
      );
    if (hideMaintenance)
      return PAGES.filter((page) => page.name !== 'Maintenance');
    if (hideDvir) {
      return PAGES.filter((page) => page.name !== 'DVIR')
    }
    if (hideTags) return PAGES.filter((page) => page.name !== 'Tags');
    return PAGES;
  };

  useEffect(() => {
    let url = new URL(window.location.href);
    let params = new URLSearchParams(url.search);
    let localColors = {};
    Object.keys(colors).map((item) => {
      if (url.searchParams.get(item)) {
        localStorage.set(item, url.searchParams.get(item));
        localColors[item] = localStorage.get(item);
        params.delete(item);
        window.history.replaceState({}, '', `${url.pathname}?${params}`);
      }

      if (localStorage.get(item) && !url.searchParams.get(item)) {
        localColors[item] = localStorage.get(item);
      }
    });

    if (url.searchParams.get('logo')) {
      localStorage.set(
        'logo',
        decodeURIComponent(url.searchParams.get('logo'))
      );
      setLogo(localStorage.get('logo'));
      params.delete('logo');
      window.history.replaceState({}, '', `${url.pathname}?${params}`);
    }

    if (localStorage.get('logo') && !url.searchParams.get('logo')) {
      setLogo(localStorage.get('logo'));
    }

    setColors({
      ...colors,
      ...localColors,
    });
  }, []);

  const css = `
            :root {
                 ${
                   colors.colorDark
                     ? `--colorDark: #` + colors.colorDark + `;`
                     : ``
                 }
                 ${
                   colors.colorBlack
                     ? `--colorBlack: #` + colors.colorBlack + `;`
                     : ``
                 }
                 ${
                   colors.colorGunPowder
                     ? `--colorGunPowder: #` + colors.colorGunPowder + `;`
                     : ``
                 }
                 ${
                   colors.colorWhite
                     ? `--colorWhite: #` + colors.colorWhite + `;`
                     : ``
                 }
                 ${
                   colors.colorTangerine
                     ? `--colorTangerine: #` + colors.colorTangerine + `;`
                     : ``
                 }
                 ${
                   colors.colorEclipse
                     ? `--colorEclipse: #` + colors.colorEclipse + `;`
                     : ``
                 }
                 ${
                   colors.colorSnow
                     ? `--colorSnow: #` + colors.colorSnow + `;`
                     : ``
                 }
                 ${
                   colors.colorSuvaGrey
                     ? `--colorSuvaGrey: #` + colors.colorSuvaGrey + `;`
                     : ``
                 }
            }
        `;

  const autoOpenDashboardIfClosed = useAutoOpenDashboardIfClosed();

  const handleLinkClick = () => {
    autoOpenDashboardIfClosed();
  };

  const logout = (e) => {
      e.preventDefault();
      logoutAPI().finally(() => {
          resetRememberMeIfExists();
          document.location.href = '/';
          fbapp.auth().signOut();
      });
  };

  if (isDisabled) {
    return (
      <Portal>
        <div className="portal-container">
          <div className="portal-container__modal">
            <p>Your account is disabled.</p>
            <p>Please, contact to organization owner.</p>
            <input type="button" value="Ok" onClick={(e) => logout(e)} />
          </div>
        </div>
      </Portal>
    )
  }

  return (
    <div className='menu-block'>
      {colors && <style>{css}</style>}
      <div className='logo'>
        {/* {logo && <img src={logo} alt="logo"/>}
                {!logo && <MasTrackLogoIcon/>} */}
        <img src={process.env.REACT_APP_ICON} alt='logo' />
      </div>
      <div className='main-menu'>
        {getPages().map(({ name, link, Icon }) => (
          <Link key={name} to={link} onClick={handleLinkClick}>
            <Icon />
            <span>{name}</span>
          </Link>
        ))}
      </div>
      <div className='favorites'>
        <FavoriteList
          onClick={() => {
            // TODO logic for open dashboard
          }}
        />
      </div>
      <div className='all-favorites'>
        <Link to='/favorites'>All Favorites</Link>
      </div>
    </div>
  );
};

export default MainMenu;

const FavoriteList = ({ onClick }: { onClick: () => void }) => {
  const favorites = useSelector<iFullStoreState, Array<iFav>>(
    getFavoriteLinksSelector()
  );
  const favoritesJsx = useMemo(
    () =>
      favorites.map((fav) => (
        <StandardFav
          onClick={onClick}
          key={fav.itemId}
          id={fav.itemId}
          fav={fav}
          color={fav?.color}
        />
      )),
    [favorites]
  );
  return <>{favoritesJsx}</>;
};

const StandardFav = ({ fav, color }: { id: string; fav: iFav; onClick: any, color: any }) => {
  return (
    // TODO logic for open dashboard
    <Link to={fav.link}>
      {fav.icon ? (
        // @ts-ignore
        <div className='ico-container'>
          <SvgIcon icon={fav.icon} size='xs' color={color} />
        </div>
      ) : fav.photo ? (
        <img src={fav.photo} alt='logo' />
      ) : (
        <div className='ico-container'></div>
      )}
      <span>{fav.name}</span>
    </Link>
  );
};
