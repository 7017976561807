import React from 'react';
import {connect} from 'react-redux';
import {NavLink as Link} from 'react-router-dom';
import classNames from 'classnames';
import {faEnvelope, faPencilAlt, faPhone, faSyncAlt,} from '@fortawesome/fontawesome-free-solid';
import LocalTile from '../devices/DeviceTabPageEdit/LocalTile';

import * as peopleDb from '../../../shared/db/people-db';

import DashboardBlock from '../../DashboardBlock';
import ModalHeader from '../../menus/modal/modal-header';
import {BaseComponent} from '../../../shared/BaseComponent';
import {ItemType} from '../../../shared/interfaces';
import C, {ACL, IS_MOBILE_APP, UserCan} from '../../../shared/constants';
import MinimalTile from '../../general/MinimalTile';
import {Fa} from '../../elements/fa';
import {formatWhenLast} from '../devices/device-page';
import {FormatAddress} from '../devices/device-list';
import {Col, Row} from '../../elements/flex';
import {ExtraInfo} from '../../elements/extra-info';
import {AllUsersFeatures} from '../../elements/all-users-features';
import {history} from '../../../stores/store';
import {clientDb, fbapp} from '../../../shared/firebase';
import {localStorage} from '../../../shared/storage';
import Logout from '../../SVG-components/logout';
import {logout as logoutAPI} from '../../../api';
import {AuthAC} from '../../../stores/reducers/auth-reducer';
import moment from 'moment';
import Select from 'react-select';
import {customSelectStyles} from '../../general/selections-grid/constants';
import {FingerprintHelper} from '../../forms/LoginForm/FingerPrintHelper';
import Dialog, {DialogConfigSetter} from '../../Dialog';
import {IFullProps, mapStateToProps} from './PersonTabPage.types';
import {VisibleDevicesTile} from './VisibleDevicesTile';
import {VisibleTagsTile} from './VisibleTagsTile';
import {DefaultSettingsTile} from './DefaultSettingsTile';
import ResetPassword from './ResetPassword';
import instance from '../../../api/instance';
import {resetRememberMeIfExists} from "../../../shared/helpers";
import RFIDTagsTile from "./RFIDTagsTile/RFIDTagsTile";
import GroupsTile from "./GroupsTile/GroupsTile";

export default connect(mapStateToProps)(
  class PersonTabPage extends BaseComponent<IFullProps> {
    state = {
      selectedTime: 3600000,
      hasFingerprint: false,
      isDisabled: false,
      RFIDTag: null,
      personIsSubAdmin: false,
      personIsAdmin: false,
      isAdmin: false,
      isSubAdmin: false,
      subUsers: [],
      groups: [],
      personGroups: [],
    };
    private dialog: DialogConfigSetter;
    fingerprintHelper = new FingerprintHelper();

    setupDialog = (callBack: () => DialogConfigSetter): void => {
      this.dialog = callBack();
    };

    checkFingerprint = () => {
      const {
        params: { id: personId },
      } = this.props.match;

      if (personId !== this.props.authUser.uid) return;
      this.fingerprintHelper
        .isUserHaveFingerPrint()
        .then((res) => this.setState({ hasFingerprint: res.has_fingerprint }));
    };

    async componentDidMount() {
      const personSubAdmin = (await (clientDb().child(`/acl/can/sub-admin/${this.props.person.id}`).once('value'))).val();
      const personAdmin = (await (clientDb().child(`/acl/can/do-anything/${this.props.person.id}`).once('value'))).val();
      const personGroups = (await (clientDb().child(`/people/people-details/${this.props.person.id}/groups`).once('value'))).val() || [];
      const logoutTime = (await (clientDb().child(`/people/people-details/${this.props.person.id}/logoutTime`).once('value'))).val() || 3600000;
      const groupsRaw = (await (clientDb().child(`/groups`).once('value'))).val();
      const groups = groupsRaw ? Object.keys(groupsRaw)
        .filter(group => Object.keys(personGroups).includes(group))
        .map(group => {
          return {
            group,
            name: groupsRaw[group].name
          }
      }) : [];
      const personIsSubAdmin = personSubAdmin ? true : false;
      const personIsAdmin = personAdmin ? true : false;
      const isAdmin = !!localStorage.get('login-init-be-token')[UserCan.DO_ANYTHING];
      const isSubAdmin = !!localStorage.get('login-init-be-token')[UserCan.SUB_ADMIN];
      const subUsers = localStorage.get('login-init-user')['subUsers'];

      this.checkUserStatus();
      this.getRFIDTag();
      this.setState({
        selectedTime: moment.duration(logoutTime, 'milliseconds').asMilliseconds(),
        personIsSubAdmin,
        personIsAdmin,
        isAdmin,
        isSubAdmin,
        subUsers: Array.isArray(subUsers) ? subUsers.includes(this.props.person.id) : [],
        groups,
        personGroups
      });

      IS_MOBILE_APP && this.checkFingerprint();
    }

    getRFIDTag = async () => {
      const {
        params: { id: personId },
      } = this.props.match;

      try {
        const response: { data } = await instance.get(`api/rfid-tag/user-tag?user_id=${personId}`);
        this.setState({ RFIDTag: response.data })
      } catch (error) {
        console.error(error)
      }
    }

    checkUserStatus = async () => {
      const userId = this.props.person.id;
      try {
        const response: { data: { is_disabled: number, message: string } } = await instance.get(`api/user/check-user-status?firebase_uid=${userId}`);
        this.setState({ isDisabled: response.data.is_disabled })
      } catch (error) {
        console.error(error)
      }
    }

    handleDisableAccount = async () => {
      const userId = this.props.person.id;
      try {
        const response: { data: { is_disabled: boolean, message: string } } = await instance.get(`api/user/toggle-user-status?firebase_uid=${userId}`);
        this.setState({ isDisabled: response.data.is_disabled })
      } catch (error) {
        console.error(error)
      }
    }

    handleToggleSubAdmin = async () => {
      const value = this.state.personIsSubAdmin ? null : true;

      this.setState({ personIsSubAdmin: !!value });

      await clientDb().update({
        [`acl/can/sub-admin/${this.props.person.id}`]: value,
      });

      if (Object.keys(this.state.personGroups).length) {
        const values = {};

        for (const group of Object.keys(this.state.personGroups)) {
          values[`groups/${group}/sub-admins/${this.props.person.id}`] = value ? true : null;
          values[`groups/${group}/sub-users/${this.props.person.id}`] = value ? null : true;
        }

        await clientDb().update(values);
      }
    }

    debugRender = () => {
      const {
        assignedDevice,
        assignedDeviceLastPing,
        person,
        currentLabels,
        userCanDo,
        authUser,
        username,
        itemTags,
        devicesDetails,
        tags,
        config,
      } = this.props;
      const {
        params: { id: personId },
      } = this.props.match;
      if (!person) return null;

      const hasTags = !!Object.keys(itemTags).length;

      const ago = formatWhenLast(
        assignedDevice ? assignedDeviceLastPing?.time : undefined
      );

      const isAdminPage =
        ACL.check(UserCan.DO_ANYTHING, userCanDo) && personId === authUser.uid;

      const isAdmin = ACL.check(UserCan.DO_ANYTHING, userCanDo) || ACL.check(UserCan.SUB_ADMIN, userCanDo);

      const isDriver = ACL.checkDriver(UserCan.ASSIGN_DRIVER, userCanDo);

      const logout = async (e) => {
        e.preventDefault();
        await logoutAPI();
        document.location.href = '/';
        resetRememberMeIfExists();
        fbapp.auth().signOut();
      };

      const removeFingerprint = () => {
        const userId = this.props.authUser.uid;

        const dialog = this.dialog;
        dialog?.({
          type: 'CONFIRM',
          title: 'Delete Fingerprint?',
          body: 'You are about to delete a fingerprint. Are you sure?',
        }).then((confirmed) => {
          confirmed &&
            this.fingerprintHelper
              .deleteFingerPrintToken({ username, userId })
              .then(({ data }) => {
                dialog?.({
                  type: 'NOTIFICATION',
                  title: 'Result',
                  body: data.status,
                });
                // @ts-ignore
                this.setState(({ hasFingerprint }) => ({
                  hasFingerprint: !hasFingerprint,
                }));
              })
              .catch((err) => console.log(err));
        });
      };

      const setNewTime = async (selectedLogoutTime) => {
        const { dispatch } = this.props;
        const { value } = selectedLogoutTime;
        const newValue = value * 60 * 60 * 1000;

        this.setState({
          selectedTime: newValue,
        });

        await clientDb().update({
          [`people/people-details/${person.id}/logoutTime`]: newValue,
        });

        dispatch(AuthAC.setLogoutTime(newValue));
      };

      const formatTimeForSelect = () => {
        const hours = Math.floor(this.state.selectedTime / 3600000);

        return hours === 1 ? `${hours} hour` : `${hours} hours`;
      }

      const onDisableUser = (personId) => () => {
        peopleDb.updatePersonDetails(authUser)(personId, { 'disabled': true });
      }

      const onEnableUser = (personId) => () => {
        peopleDb.updatePersonDetails(authUser)(personId, { 'disabled': false });
      }

      const optionsForLogoutSelector = Array(24)
        .fill('')
        .map((_, i) => ({
          value: i + 1,
          label: `${i + 1} ${i == 0 ? 'hour' : 'hours'}`,
        }));

      const selectTimePlaceholder = formatTimeForSelect();

      return (
        <DashboardBlock>
          <ModalHeader
            title={person ? person.displayName : ''}
            action={
              !isDriver && {
                fa: faPencilAlt,
                title: 'Edit Person Details',
                click: () => history.push(`/person/${personId}/edit`),
              }
            }
            role={isDriver}
          />

          <div>
            <div style={{ display: 'flex', marginBottom: 10 }}>
              <div
                style={{
                  width: 70,
                  height: 70,
                  borderRadius: 70,
                  backgroundImage: `url(${person.photoURL})`,
                  backgroundSize: 'cover',
                  border: `2px solid ${C.mediumGray}`,
                }}
              />

              <MinimalTile
                style={{
                  display: 'flex',
                  flex: 1,
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  fontSize: 16,
                  lineHeight: '15px',
                  position: 'relative',
                  marginLeft: 5,
                  paddingBottom: 21,
                }}
              >
                <div>
                  {assignedDeviceLastPing && (
                    <FormatAddress
                      style={{
                        alignSelf: 'stretch',
                        paddingTop: 5,
                        textAlign: 'center',
                        lineHeight: '18px',
                        fontWeight: 'lighter',
                      }}
                      address={assignedDeviceLastPing.address}
                    />
                  )}
                </div>
                <span
                  style={{
                    position: 'absolute',
                    left: 3,
                    bottom: 3,
                    fontSize: 10,
                  }}
                >
                  <Fa icon={faSyncAlt} style={{ color: C.primaryColor }} /> Last
                  Seen: {ago || 'N/A'}
                </span>
                {(assignedDevice && assignedDevice.id !== '-1') && (
                  <Col align='center' justify='space-between'>
                    <span style={{ fontSize: 10 }}>Assigned Device</span>
                    <span style={{ fontSize: 12 }}>{assignedDevice.name}</span>
                  </Col>
                )}
              </MinimalTile>
            </div>
            <MinimalTile>
              <Row align='center' justify='space-between'>
                <span>
                  <Fa icon={faPhone} style={{ fontSize: 11 }} /> &nbsp; Main
                </span>
                {person.mainNumber || 'none'}
              </Row>
              <Row align='center' justify='space-between'>
                <span>
                  <Fa icon={faEnvelope} style={{ fontSize: 11 }} /> &nbsp; Email
                </span>
                {person.email || 'none'}
              </Row>
            </MinimalTile>

            <div
              style={{
                borderTop: '2px solid ' + C.primaryText,
                marginTop: 4,
                marginBottom: 6,
              }}
            />

            {this.state.isAdmin && <GroupsTile
              groups={this.state.groups}
            />}

            <VisibleTagsTile
              personId={personId}
              userCanDo={userCanDo}
              devicesDetails={devicesDetails}
              tags={tags}
            />

            {!config.strictAccess ? null : (
              <VisibleDevicesTile
                personId={personId}
                userCanDo={userCanDo}
                devicesDetails={devicesDetails}
                tags={tags}
              />
            )}

            <ExtraInfo
              editUrl={`/${ItemType.person}/${personId}/add/extra-info`}
              labels={currentLabels}
              role={isDriver}
            />

            {(isAdmin || (this.state.isSubAdmin && this.state.subUsers.includes(personId))) && (
              <RFIDTagsTile
                personId={personId}
                RFIDTag={this.state.RFIDTag}
              />
            )}

            {isAdminPage && (
              <DefaultSettingsTile personId={personId} userCanDo={userCanDo} />
            )}

            {isAdminPage && <AllUsersFeatures />}

            {personId === authUser.uid && (
              <ResetPassword />
            )}

            {personId === authUser.uid && (
              <LocalTile title='Logout time'>
                <div style={{ margin: '10px 0' }}>
                  <Select
                    key='logout'
                    onChange={(e) => setNewTime(e)}
                    value={this.state.selectedTime}
                    placeholder={selectTimePlaceholder}
                    styles={customSelectStyles}
                    options={optionsForLogoutSelector}
                  />
                </div>
              </LocalTile>
            )}
            {
              (this.state.isAdmin && !this.state.personIsAdmin) &&
                <button
                    className={classNames('btn', 'btn-primary')}
                    style={{ width: '100%' }}
                    onClick={this.handleToggleSubAdmin}
                >
                  {this.state.personIsSubAdmin ? 'Remove Sub Admin' : 'Make Sub Admin'}
                </button>
            }
            <div
              style={{
                margin: '10px 0',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              {personId === authUser.uid && (
                <Link
                  onClick={logout}
                  to='/login'
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    textDecoration: 'none',
                    marginTop: 10,
                  }}
                >
                  <Logout
                    style={{
                      height: 30,
                      width: 30,
                      fill: `${C.primaryText}`,
                      marginRight: 10,
                    }}
                  />
                  <h6 style={{ marginBottom: 0, color: `${C.primaryText}` }}>
                    Logout
                  </h6>
                </Link>
              )}
              {personId === authUser.uid &&
                IS_MOBILE_APP &&
                this.state.hasFingerprint && (
                  <button
                    className='btn btn-primary'
                    onClick={() => removeFingerprint()}
                  >
                    Delete Fingerprint
                  </button>
                )}
              {
                personId !== authUser.uid && isAdmin && !person.disabled && (
                  <button
                    className={classNames('btn', 'btn-primary')}
                    style={{ width: '100%' }}
                    onClick={this.handleDisableAccount}
                  >
                    {this.state.isDisabled ? 'Enable this account' : 'Disable this account'}
                  </button>
                )
              }

              {
                personId !== authUser.uid && isAdmin && person.disabled && (
                  <button
                    className='btn btn-primary'
                    style={{ width: '100%' }}
                    onClick={onEnableUser(personId)}
                  >
                    Enable this account
                  </button>
                )
              }

              <Dialog setupConfig={this.setupDialog} />
            </div>
          </div>
        </DashboardBlock>
      );
    };
  }
);
