import styles from "./styles.module.scss";
import React from "react";

const DvirFormHeadings = ({headings, removeHeading}) => {
  return (
    <div className={styles.HeadingsContainer}>
      <h3>Headings</h3>
      {headings.map(heading => (
        <div className={styles.HeadingItem}>
          <span className={styles.HeadingName}>{heading.header}</span>
          <span className={styles.DeleteHeading} onClick={() => removeHeading(heading.header)}>&#x2715;</span>
        </div>
      ))}
    </div>
  )
}

export default DvirFormHeadings;
