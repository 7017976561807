import React, { useEffect, useState } from "react";

import AuditLog from "../AuditLog";
import Chevron from "../Chevron";

import styles from "./styles.module.scss";
import {useRedux} from "../../../../../states/redux-state";

interface IAuditLogNestedListProps {
  data: object;
  name: string;
  isFirstNest: boolean;
}

const AuditLogNestedList: React.FC<IAuditLogNestedListProps> = ({
  data,
  name,
  isFirstNest,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const people = useRedux((s) => s.general.people);
  const devices = useRedux((s) => s.devicesData.devicesDetails).toObject();
  const tags = useRedux((s) => s.general.tags);
  const [activeTab, setActiveTab] = useState(name);

  useEffect(() => {
    if (isFirstNest) {
      setIsOpen(true);
    }
  }, [isFirstNest]);

  const toggleOpen = (event) => {
    event.stopPropagation();
    setIsOpen(!isOpen);
    setActiveTab(name);
  };

  const mapIdToDisplayName = (key) => {
    let name = key;

    try {
      const peopleDetails = people[key];
      const deviceDetails = devices[key];
      const tagDetails = tags[key];

      if (peopleDetails && peopleDetails.displayName) name = peopleDetails.displayName;
      else if (deviceDetails && deviceDetails.name) name = deviceDetails.name;
      else if (tagDetails && tagDetails.details && tagDetails.details.name) name = tagDetails.details.name;
    } catch (e) {
      console.error(e)
    }

    return name;
  }

  return (
    <ul
      className={styles.AuditLogNestedList}
      onClick={(event) => event.stopPropagation()}
    >
      {!isFirstNest && (
        <li className={styles.title} onClick={toggleOpen}>
          <Chevron isActive={isOpen} direction={isOpen ? "down" : "right"} />
          <span style={{ fontWeight: isOpen && 600 }}>{name}</span>
        </li>
      )}
      <ul className={styles.nestedChild}>
        {isOpen &&
          Object.entries(data).map(([key, value]) => {
            let name = key.startsWith('-') ? mapIdToDisplayName(key) : key;

            return (
              <li key={key}>
                {typeof value === "object" ? (
                  <AuditLogNestedList
                    data={value}
                    name={name}
                    isFirstNest={false}
                  />
                ) : (
                  <AuditLog label={key} value={value} />
                )}
              </li>
            );
          })}
      </ul>
    </ul>
  );
};

export default AuditLogNestedList;
