import React, {useEffect, useMemo, useState} from 'react';
import PathPolygon from './gmap/path';
import {keys, pickBy, propEq, values} from 'ramda';
import {connect, DispatchProp, useSelector} from 'react-redux';
import {OvalPolygon} from './gmap/oval-polygon';
import Rectangle from './gmap/rectangle-polygon';
import { TrafficLayer } from 'react-google-maps';
import HeatmapLayer from "react-google-maps/lib/components/visualization/HeatmapLayer";
import {useRedux} from '../states/redux-state';
import InfoMarkers from './GoogleMap/InfoMarkers';
import DevicesRoutes from './GoogleMap/DevicesRoutes';
import SearchMarkers from './GoogleMap/SearchMarkers';
import UserMarker from './gmap/UserMarker';
import {localStorage} from '../shared/storage';
import StreetView from './GoogleMap/StreetView';
import {FenceType, iFullStoreState, iLocation} from '../shared/interfaces';
import withGoogleMap, {WithGoogleMapProps} from 'react-google-maps/lib/withGoogleMap';
import {BaseComponent} from '../shared/BaseComponent';
import GoogleMap from 'react-google-maps/lib/components/GoogleMap';
import {Actions as MapActions} from '../stores/reducers/gmap-reducers';
import {getPathIdsSelector} from '../stores/reducers/gmap-selectors';
import {throttle} from 'throttle-debounce';
import { isMobile } from '../shared/helpers';
import {IS_MOBILE_APP} from "../shared/constants";
import { toast } from 'react-toastify';
import instance from '../api/instance';
import { useLocation } from 'react-router-dom';
import './gmap-styles.scss';

type IPropsFromStore = {
    mapType: google.maps.MapTypeId;
    streetViewLocation: boolean | iLocation;
    defaultCenter: iLocation;
    defaultZoom: number;
}
type IFullProps = DispatchProp & IPropsFromStore;

const mapGmapStateToProps = (state: iFullStoreState): IPropsFromStore => ({
    mapType: state.gmap.mapType,
    streetViewLocation: state.gmap.streetViewLocation,
    defaultCenter: state.gmap.defaultCenter,
    defaultZoom: state.gmap.defaultZoom,
});


const PreviewGoogleMap = connect(mapGmapStateToProps)
(class extends BaseComponent<IFullProps & WithGoogleMapProps> {
    private mapRef = React.createRef<GoogleMap>();

    getMapZoom = (): number => {
        const zoom = this.mapRef && this.mapRef.current && this.mapRef.current.getZoom();

        return zoom || this.props.defaultZoom;
    };

    debugRender = () => {
        const {
            streetViewLocation,
            mapType,
            defaultCenter,
        } = this.props;

        const zoom = this.getMapZoom();

        return (
            <GoogleMap
                ref={this.mapRef}
                defaultZoom={zoom}
                defaultCenter={defaultCenter}
                options={{
                    streetViewControl: !!streetViewLocation,
                    streetViewControlOptions: {
                        position: google.maps.ControlPosition.LEFT_BOTTOM,
                    },
                    disableDefaultUI: true,
                }}

                mapTypeId={mapType}
            />
        )
    };
});

const PreviewMapGoogle = withGoogleMap(PreviewGoogleMap);

const GettingStartedGoogleMap1 = connect(mapGmapStateToProps)
(class extends BaseComponent<IFullProps & WithGoogleMapProps> {
    private mapRef = React.createRef<GoogleMap>();

    getMapCenter = (): iLocation => {
        const center = this.mapRef && this.mapRef.current && this.mapRef.current.getCenter();

        return center ?
            {lat: center.lat(), lng: center.lng()} :
            this.props.defaultCenter;
    };

    getMapZoom = (): number => {
        const zoom = this.mapRef && this.mapRef.current && this.mapRef.current.getZoom();

        return zoom || this.props.defaultZoom;
    };

     getMyBounds = () => {
         const latNorth = this.mapRef?.current?.getBounds()?.getNorthEast().lat();
         const lngEast = this.mapRef?.current?.getBounds()?.getNorthEast().lng();
         const latSouth = this.mapRef?.current?.getBounds()?.getSouthWest().lat();
         const lngWest = this.mapRef?.current?.getBounds()?.getSouthWest().lng();

         return {latNorth, lngEast, latSouth, lngWest} || {};
     };

     componentDidMount () {
         const {dispatch} = this.props;

         dispatch(MapActions.SET_MAP(this.mapRef));

         // new google.maps.places.PlacesService(this.map);
     }

     shouldComponentUpdate (nextProps: Readonly<IFullProps & WithGoogleMapProps>): boolean {
         return nextProps.mapType !== this.props.mapType || nextProps.streetViewLocation != this.props.streetViewLocation;
     }

     componentWillUnmount() {
         const { dispatch } = this.props;
         dispatch(MapActions.SET_MAP_STATUS(false));
     }

    debugRender = () => {
        const {
            streetViewLocation,
            mapType,
            defaultCenter,
            dispatch,
        } = this.props;

        const center = this.getMapCenter();

        const zoom = this.getMapZoom();

        const getBounds = () => this.getMyBounds();

        const refreshBounds = throttle(1000, ()=>{
            dispatch(MapActions.SET_BOUNDS(getBounds()));
        });

        const IS_PUBLIC_VIEW = window.location.href.includes('public-view');

        return <>
            <GoogleMap
                ref={this.mapRef}
                defaultZoom={zoom}
                defaultCenter={defaultCenter}
                options={{
                    streetViewControl: !!streetViewLocation,
                    streetViewControlOptions: {
                        position: google.maps.ControlPosition.LEFT_BOTTOM,
                    },
                    disableDefaultUI: true,
                }}

                mapTypeId={mapType}
                onZoomChanged={() => {
                    const newZoom = this.getMapZoom();
                    !IS_PUBLIC_VIEW && localStorage.set('map-default-zoom', newZoom);
                    !IS_PUBLIC_VIEW && localStorage.set('map-default-bounds', getBounds());
                    refreshBounds();
                }}
                onDragEnd={function (this: google.maps.Map) {
                    !IS_PUBLIC_VIEW && localStorage.set('map-default-center', {lat: this.getCenter().lat(), lng: this.getCenter().lng()});
                    !IS_PUBLIC_VIEW && localStorage.set('map-default-bounds', getBounds());
                    // dispatch(MapActions.SET_CENTER({lat: this.center.lat(), lng: this.center.lng()}))
                    refreshBounds();
                }}
                onTilesLoaded={() => dispatch(MapActions.SET_MAP_STATUS(true))}
            >
                <MapStuff />
            </GoogleMap>
            {
                streetViewLocation && (
                    <>
                        <StreetView mapRef={this.mapRef}
                            defaultCenter={center}
                            center={typeof streetViewLocation === 'object' ? streetViewLocation : undefined}/>
                    </>
                )
            }
        </>;
    };
});

const GettingStartedGoogleMap = withGoogleMap(GettingStartedGoogleMap1);

const MapStuff = () => {
    const pathIds = useRedux(getPathIdsSelector);

    const userFromHttp = localStorage.get('login-init-user');
    const firebase_id = userFromHttp?.uid;

    const [coordinatesOfPoints, setCoordinatesOfPoints] = useState([]);
    const { pathname } = useLocation();

    const displayRecordsRaw = useRedux((s) => s.report.displayRecords) || {};
    const tripIds = useMemo(() => Object.keys(displayRecordsRaw), [displayRecordsRaw]);

    const getCoordinatesOfPoints = async (tripIds) => {
        const URL = `/api/location/get-points-by-trips?firebaseId=${firebase_id}`;

        const body = {
            tripIds: tripIds
        }

        await instance.post(URL, body)
            .then(response => {
                const { data } = response;

                (data.length > 0) && setCoordinatesOfPoints(data);
                return data
            })
            .catch(({ message }) => {
                toast.error(message, { position: toast.POSITION.TOP_RIGHT });
            });
    }

    useEffect(() => {
        if (tripIds.length > 0 && pathname === '/reports/new/travel') {
            getCoordinatesOfPoints(tripIds);
        } else if (coordinatesOfPoints.length > 0 && tripIds.length <= 0 && pathname === '/reports/new/travel') {
            setCoordinatesOfPoints([])
        };
    }, [tripIds])

    const coordinatesOfPointsForHeatmap = coordinatesOfPoints.map(coordinates => new google.maps.LatLng(coordinates.lat, coordinates.lng));

    const tripPoints = useRedux((s) => s.devicesTripsPoints);
    const isRouteOfTripsShownOnMap = Array.from(tripPoints)
        .map(el => Array.from(el[1]))
        .map(el => Array.from(el[3]))
        .map(el => {
            const subArray = el[1] ?? [];
            return Array.from(subArray);
        })
        .some(subArray => subArray.some(pair => pair[1] === true));

    const bounds = useRedux((s) => s.gmap.bounds) || {};
    const location = useSelector<iFullStoreState, iLocation>(state => state.gmap.userLocation);

    const regions = useRedux((s) => s.regions.vis) || {};

    const isTrafficLayer = useRedux((s) => s.gmap.trafficLayer);

    const trafficLayerOptions = { autoRefresh: true };

    const newRegions = Object.keys(regions).reduce((acc,rec) => {
        if (regions[rec].center.lat > bounds.latSouth &&
            regions[rec].center.lng > bounds.lngWest &&
            regions[rec].center.lat < bounds.latNorth &&
            regions[rec].center.lng < bounds.lngEast) {
            return{...acc, [rec]: {...regions[rec]}};
        }

        return acc;
    }, {});
    const ovals = keys(pickBy(propEq('shape', FenceType.OVAL), newRegions));
    const rectangles = keys(pickBy(propEq('shape', FenceType.RECTANGLE), newRegions));

    return (<>
        <SearchMarkers />

        <InfoMarkers />

        <DevicesRoutes />

        {(IS_MOBILE_APP && location) && (
            <UserMarker />
        )}

        {(pathname === '/reports/new/travel' && !isRouteOfTripsShownOnMap) && (<HeatmapLayer
            data={coordinatesOfPointsForHeatmap}
        />)}

        {isTrafficLayer && (
            <TrafficLayer defaultOptions={trafficLayerOptions} />
        )}

        {ovals.map((id) => <OvalPolygon key={id} id={id} />)}

        {pathIds.map((id) => <PathPolygon key={id} id={id} />)}

        {rectangles.map((id) => <Rectangle key={id} id={id} />)}
    </>);

};

export const GmapComponent = React.memo(() => {
    return (
      <div id="gmap-wrapper" style={{
          display: 'flex',
          flexDirection: isMobile ? 'column-reverse' : 'row',
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          zIndex: 0,
      }}>
          <GettingStartedGoogleMap
            containerElement={
                <div id="google-map" style={{flex: 1}}/>
            }
            mapElement={
                <div style={{height: '100%'}}/>
            }
          />
          {/* This will be rendered in streetView mode*/}
          {/* <div id="pano" style={{flex: 1}} />*/}
      </div>
    )
}, () => true);

export const GmapPreview = React.memo(() => {
  return (
    <div
      id='gmap-wrapper'
      style={{
        display: 'flex',
        flexDirection: isMobile ? 'column-reverse' : 'row',
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: 0,
      }}
    >
      <PreviewMapGoogle
        containerElement={
          <div id="google-map" style={{flex: 1}}/>
        }
        mapElement={
          <div style={{height: '100%'}} />
        }
      />
      {/* This will be rendered in streetView mode*/}
      {/* <div id="pano" style={{flex: 1}} />*/}
    </div>
  );
}, () => true);
