import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { equals, pathOr } from 'ramda';

import { useRedux } from '../../../../states/redux-state';
import { useFirebase } from '../../../../states/firebase-state';
import { iDeviceDetails, iDevicePing, iFullStoreState, ItemType } from '../../../../shared/interfaces';
import { fitText } from '../../../../shared/fit-text';
import C from '../../../../shared/constants';
import { FaFav, FaPer } from '../../../elements/fa';
import SvgIcon from '../../../SvgIcon/SvgIcon';
import { FormatAddress } from '../device-list';
import { PersonBox } from '../../../general/person-box';
import { formatWhenLast } from '../device-page';

import styles from './styles.module.scss';

interface iTileProps {
  deviceId: string;
  style?: React.CSSProperties;
}

const getPersonFromState = (personId, state) =>
  pathOr(false, ['general', 'people', personId], state);

export const DeviceTile = ({ deviceId, style = {} }: iTileProps) => {
  const uid = useRedux((s) => s.auth.user.uid);
  const [isFavorite, setFavorite] = useFirebase(
    `favorites/${uid}/${ItemType.device}/${deviceId}`
  );
  const device = useSelector<iFullStoreState, iDeviceDetails>(
    (state) => state.devicesData.devicesDetails.get(deviceId)!,
    (left, right) => equals(left, right)
  );
  const lastPing = useSelector<iFullStoreState, iDevicePing | undefined>(
    (state) => state.devicesData.devicesLastPing.get(deviceId),
    (left, right) => left?.pointId === right?.pointId
  );
  const pingMsg = lastPing?.msg ?? 'N/A';
  const assignedPersonId = device?.assignedTo?.personId;
  const assignedTo = useRedux((s) => getPersonFromState(assignedPersonId, s));
  const [ago, setAgo] = React.useState(formatWhenLast(lastPing?.time));
  const deviceEl = React.createRef();
  const personEl = React.createRef();
  const toggleFav = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setFavorite(isFavorite ? null : 999999);
  };

  useEffect(() => {
    const timerId = setTimeout(
      () => setAgo(formatWhenLast(lastPing?.time)),
      1000 * 60
    );

    return () => clearTimeout(timerId);
  }, [lastPing?.time, ago]);

  useEffect(() => {
    fitText([[deviceEl.current, `device-tile-${deviceId}`]]);
  }, [assignedTo]);

  return (
    <Link
      key={deviceId}
      to={`/device/${deviceId}`}
      className={styles.DeviceTile}
    >
      <div
        className={styles.DeviceIcons}
      >
        <FaPer active={assignedTo} title={assignedTo} />
        <SvgIcon
          style={{ flex: 1, textAlign: 'center' }}
          color={device.color}
          icon={device.icon}
          size='sm'
        />
        <FaFav
          isFav={!!isFavorite}
          style={{ color: C.yellow }}
          onClick={toggleFav}
        />
      </div>

      <div
        className={styles.DeviceName}
        ref={deviceEl as any}
      >
        {device.name}
      </div>

      {ago && (
        <span
          className={styles.DeviceLastPing}
          title={ago.toString()}
        >
          {ago}
        </span>
      )}

      {pingMsg && (
        <div
          className={styles.DevicePingMsg}
        >
          {pingMsg}
        </div>
      )}

      <FormatAddress
        style={{
          alignSelf: 'stretch',
          fontSize: 11,
          paddingTop: 5,
          textAlign: 'center',
        }}
        address={lastPing?.address}
        noWrap
      />

      {assignedTo && (
        <div style={{ width: '100%' }} ref={personEl as any}>
          <PersonBox
            tiny
            compressed
            active={false}
            person={assignedTo}
            style={{
              alignSelf: 'flex-start',
              marginLeft: -5,
              marginBottom: -5,
            }}
          />
        </div>
      )}
    </Link>
  );
};
