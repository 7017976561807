import React, {useEffect, useState} from 'react';
import { useParams } from 'react-router-dom';
import DashboardBlock from "../../../DashboardBlock";
import ModalHeader from "../../../menus/modal/modal-header";
import AddGroupOverlay from "./AddGroupOverlay";
import {faPlusCircle} from "@fortawesome/fontawesome-free-solid";
import {SearchGridOld} from "../../../SearchGridOld/SearchGridOld";
import {clientDb} from "../../../../shared/firebase";
import {StandardItem} from "../../../general";

const GroupsEditPage = () => {
  const { id: personId } = useParams();
  const [createGroup, setCreateGroup] = React.useState(false);
  const [groups, setGroups] = useState([]);
  const [personGroups, setPersonGroups] = useState([]);
  const [personGroupIds, setPersonGroupIds] = useState([]);
  const [initialPersonGroupIds, setInitialPersonGroupIds] = useState([]);
  const [allGroups, setAllGroups] = useState([]);
  const [groupFilter, setGroupFilter] = useState('');
  const [personIsSubAdmin, setPersonIsSubAdmin] = useState(false);

  const fetchGroups = async () => {
    const groupsRaw = (await (clientDb().child(`/groups`).once('value'))).val();
    const personGroupsRaw = (await (clientDb().child(`/people/people-details/${personId}/groups`).once('value'))).val();

    const personGroups = personGroupsRaw ? Object.keys(groupsRaw)
      .filter(group => Object.keys(personGroupsRaw).includes(group))
      .map(group => {
        return {
          group,
          name: groupsRaw[group].name
        }
      }) : [];

    const groups = groupsRaw ? Object.keys(groupsRaw).map(group => {
      return {
        group,
        name: groupsRaw[group].name
      }
    }) : [];

    const personSubAdmin = (await (clientDb().child(`/acl/can/sub-admin/${personId}`).once('value'))).val();
    const isSubAdmin = personSubAdmin ? true : false;

    setGroups(groups);
    setPersonGroups(personGroups);
    if(personGroups.length) setPersonGroupIds(Object.keys(personGroupsRaw));
    if(personGroups.length) setInitialPersonGroupIds(Object.keys(personGroupsRaw));
    setAllGroups(groups);
    setPersonIsSubAdmin(isSubAdmin);
  }

  useEffect(() => {
    fetchGroups();
  }, []);

  const groupClicked = (groupId) => {
    if (!personGroupIds.includes(groupId)) {
      setPersonGroupIds(ids => [...ids, groupId]);
      const newGroup = groups.find(group => group.group === groupId);
      setPersonGroups(groups => [...groups, newGroup]);
    }
  }

  const onFilterChange = async (newValue) => {
    setGroupFilter(newValue);

    if(newValue){
      const filteredGroups = allGroups.filter(group => {
        return group.name.toLowerCase().includes(newValue.toLowerCase())
      });

      setGroups(filteredGroups)
    }
    else {
      setGroups(allGroups);
    }
  }

  const onRemove = async (groupId) => {
    setPersonGroupIds(ids => ids.filter(id => id !== groupId));
    setPersonGroups(personGroups.filter(group => group.group !== groupId));
  }

  const updateGroups = async () => {
    const groupsToBeAdded = [];
    const groupsToBeDeleted = [];

    for (const groupId of personGroupIds) {
      if(!initialPersonGroupIds.includes(groupId)) groupsToBeAdded.push(groupId);
    }

    for (const groupId of initialPersonGroupIds) {
      if(!personGroupIds.includes(groupId)) groupsToBeDeleted.push(groupId);
    }

    const deleteGroupsObject = {};
    const addGroupsObject = {};
    const collection = personIsSubAdmin ? 'sub-admins' : 'sub-users';

    for (const group of groupsToBeDeleted) {
      deleteGroupsObject[`groups/${group}/${collection}/${personId}`] = null;
    }

    for (const group of groupsToBeAdded) {
      addGroupsObject[`groups/${group}/${collection}/${personId}`] = true;
    }

    const groupsObject = personGroupIds.reduce((obj, id) => {
      obj[id] = true;
      return obj;
    }, {});

    await clientDb().update({
      [`people/people-details/${personId}/groups`]: groupsObject
    });

    setInitialPersonGroupIds(personGroupIds)

    await clientDb().update(deleteGroupsObject);
    await clientDb().update(addGroupsObject);
  }

  return (
    <DashboardBlock>
      {createGroup && <AddGroupOverlay
          close={() => setCreateGroup(false)}
          onUpdate={fetchGroups}
      />}
      <ModalHeader
        title='Groups'
        action={
          {
            click: () => setCreateGroup(true),
            fa: faPlusCircle,
            title: 'Add new group'
          }
        }
      />
      <SearchGridOld
        style={{maxWidth: 300}}
        list={groups.map(group => {
          return (
            <div key={group.group}>
              {group.name}
            </div>
          )
        })}
        filterStr={groupFilter}
        placeholder="Search Groups"
        perPage={3}
        keyClicked={groupClicked}
        filterChange={onFilterChange}
      />
      {personGroups.map(group =>
        <StandardItem
          key={group.group}
          style={{margin: 4}}
          remove={onRemove}
          itemId={group.group}
          displayName={group.name}
          isGroup={true}
        />
      )}
      <div className="text-center" style={{marginTop: 10}}>
        <button onClick={updateGroups} className="btn btn-primary">Update Groups</button>
      </div>
    </DashboardBlock>
  );
};

export default GroupsEditPage;
