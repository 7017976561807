import React, { useState, useEffect } from 'react';
import styles from './styles.module.scss';

export const DvirPagination = ({ totalPages, currentPage, onPageChange }) => {
  const [pageNumbers, setPageNumbers] = useState([]);

  useEffect(() => {
    const  adjacentPages = 1;
    const generatePageNumbers = () => {
      const pages = [];

      const start = Math.max(2, currentPage - adjacentPages);
      const end = Math.min(totalPages - 1, currentPage + adjacentPages);

      pages.push(1);

      if (start > 2) {
        pages.push('...');
      }

      for (let i = start; i <= end; i++) {
        pages.push(i);
      }

      if (end < totalPages - 1) {
        pages.push('...');
      }

      if (totalPages > 1) {
        pages.push(totalPages);
      }

      setPageNumbers(pages);
    };

    generatePageNumbers();
  }, [currentPage, totalPages]);

  const getPageStyles = (page): string => {
    if (page === '...') return styles.InactiveBtn;
    else if (page === currentPage) return styles.ActivePage;

    return styles.PaginationBtn
  }

  return (
    <div className={styles.DvirPagination}>
      {pageNumbers.map((page, index) => (
        <button
          className={getPageStyles(page)}
          key={`${page}-${currentPage}`}
          onClick={() => typeof page === 'number' && onPageChange(page)}
          disabled={page === '...' || page === currentPage}
        >
          {page}
        </button>
      ))}
    </div>
  );
};
