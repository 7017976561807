import React, {CSSProperties as css} from "react";
import C from "../../shared/constants";

const mTileStyle = ({ small, connectTop, connectBottom }): css => {
  let style = {
    backgroundColor: '#fff',
    padding: 8,
    border: '1px solid ' + C.mediumGray,
    borderRadius: 5,
    color: C.primaryText,
    fontSize: small ? 12 : 'inherit',
    overflowX: 'hidden',
    minHeight: '55px'
  } as css;

  if (connectTop) {
    style.borderTopLeftRadius = 0;
    style.borderTopRightRadius = 0;
    style.borderTop = 'none';
  }

  if (connectBottom) {
    style.borderBottomStyle = 'dotted';
    style.borderBottomLeftRadius = 0;
    style.borderBottomRightRadius = 0;
  }
  return style;
}

const mTitleStyle = (small): css => ({
  fontSize: small ? 12 : 'large',
  fontWeight: small ? 'bold' : 'normal',
  justifyContent: 'space-between',
})

interface iTileProps {
  children,
  title?: string,
  style?: css,
  small?: boolean,
  connectTop?: boolean,
  connectBottom?: boolean,
  className?: string,
}

const MinimalTile = (props: iTileProps) => {
  const {
    children,
    title,
    style,
    small,
    connectTop,
    connectBottom,
    className
  } = props;

  return (
    <div className={`mtile ${className || ''}`} style={{ ...mTileStyle({ small, connectTop, connectBottom }), ...style }}>
      {title && (
        <div className="tile-header" style={mTitleStyle(small)}>
          <div className="txt">{title}</div>
        </div>
      )}
      {children}
    </div>
  );
};

export default MinimalTile;