import React from 'react';
import { connect } from 'react-redux';
import { values, pathOr } from 'ramda';
import { get } from 'lodash';
import { faSpinner } from '@fortawesome/fontawesome-free-solid';

import { BaseComponent } from '../../../shared/BaseComponent';
import DashboardBlock from '../../DashboardBlock';
import ModalHeader from '../../menus/modal/modal-header';
import {
  iFullStoreState,
  ItemType,
  iItemsAllowed,
} from '../../../shared/interfaces';
import { UserCan } from '../../../shared/constants';
import { toggleUserPermission, userCan } from '../../../shared/db/users-access';
import { StandardItem } from '../../general';
import TableSimple from '../../elements/TableSimple';
import {
  setAllowedSee,
  removeAllowedSee,
  watchPersonAllowedSee,
  tagReadOnlyToggle,
} from '../../../shared/db/general-db';
import { Fa } from '../../elements/fa';
import { Row } from '../../elements/flex';
import Checkbox from '../../elements/Checkbox';
import { SearchGridOld } from '../../../components/SearchGridOld/SearchGridOld';
import instance from '../../../api/instance';
import {
  IFullProps,
  StateInterface,
  itemStyle,
  helpMsg,
  IPropsFromStore,
} from './PersonVisibleTags.types';

import styles from './PersonVisibleTags.module.scss';

export default connect(
  (s: iFullStoreState, o: any): IPropsFromStore => ({
    person: s.general.people[o.match.params.id],
    tags: s.general.tags,
    devicesDetails: s.devicesData.devicesDetails,
    authUser: s.auth.user!,
  })
)(
  class PersonVisibleTags extends BaseComponent<IFullProps, StateInterface> {
    private canceller;
    constructor(props) {
      super(props);
      this.state = {
        tagFilter: '',
        deviceFilter: '',
        readOnlyAll: false,
        readOnlyAccess: {},
        canEditTagsAndFences: false,
      };
    }

    _populateState = (allowed: iItemsAllowed) => {
      this.setState({ allowed });
    };

    async componentDidMount() {
      const { person, tags } = this.fullProps;
      this.canceller = watchPersonAllowedSee(person.id, (allowed) =>
        this.setState({ allowed: allowed || {} }, () => {
          const devicesAccess = allowed.device
            ? Object.keys(allowed.device).reduce((acc, rec) => {
                return { ...acc, [rec]: allowed.device[rec].readOnly || false };
              }, {})
            : {};
          const filteredTags = Object.keys(tags).reduce((acc, rec) => {
            if (get(tags[rec], `instances.allowed-see.person.${person.id}`)) {
              return { ...acc, [rec]: tags[rec] };
            }

            return acc;
          }, {});
          const tagsAccess = Object.keys(filteredTags).reduce((acc, rec) => {
            return {
              ...acc,
              [rec]: get(
                tags[rec],
                `instances.allowed-see.readOnly.${person.id}`,
                false
              ),
            };
          }, {});

          this.setState({
            readOnlyAccess: { ...devicesAccess, ...tagsAccess },
          });
        })
      );
      const canEditTags =
        (await userCan(person.id, UserCan.TAG_THINGS)) &&
        (await userCan(person.id, UserCan.CREATE_TAGS));
      const canEditFences = await userCan(person.id, UserCan.EDIT_FENCES);
      this.setState({ canEditTagsAndFences: canEditTags && canEditFences });
    }

    requestPermissionToggling = async () => {
      const { person } = this.fullProps;
      const { canEditTagsAndFences } = this.state;

      const tagThings = await toggleUserPermission(
        person.id,
        UserCan.TAG_THINGS,
        !canEditTagsAndFences
      );
      const canCreateTags = await toggleUserPermission(
        person.id,
        UserCan.CREATE_TAGS,
        !canEditTagsAndFences
      );
      const canEditFences = await toggleUserPermission(
        person.id,
        UserCan.EDIT_FENCES,
        !canEditTagsAndFences
      );

      this.setState({
        canEditTagsAndFences: tagThings && canCreateTags && canEditFences,
      });
    };

    componentWillUnmount() {
      if (this.canceller) this.canceller();
    }

    _toggleTagFlag = async (tagId) => {
      const { tags, person } = this.fullProps;
      const { readOnlyAccess } = this.state;

      const isRemove = pathOr(false, [
        tagId,
        'instances',
        'allowed-see',
        'person',
        person.id,
      ])(tags);

      await (isRemove ? removeAllowedSee : setAllowedSee)(
        this.fullProps.authUser
      )(this.fullProps.person.id, ItemType.tag, tagId);

      if (isRemove) {
        this.setState({
          readOnlyAccess: {
            ...readOnlyAccess,
            [tagId]: !readOnlyAccess?.[tagId],
          },
        });
      }

      instance.post('/api-firebase/revoke', { uid: this.fullProps.person.id });
    };

    _filterchange = (key: keyof StateInterface) => (str) =>
      this.setState({ [key]: str } as any);

    debugRender = () => {
      const { person, tags } = this.props;
      const {
        tagFilter,
        allowed = false,
        readOnlyAccess,
        canEditTagsAndFences,
      } = this.state;

      const tagItems = values(tags).map((tag) => (
        <StandardItem
          style={itemStyle(
            pathOr(false, ['instances', 'allowed-see', 'person', person.id])(
              tag
            )
          )}
          key={tag.details.id}
          displayName={tag.details.name}
          itemId={tag.details.id}
        />
      ));

      const filteredTags = Object.keys(tags).reduce((acc, rec) => {
        if (get(tags[rec], `instances.allowed-see.person.${person.id}`)) {
          return { ...acc, [rec]: tags[rec] };
        }

        return acc;
      }, {});

      const searchTagItems = tagItems.filter(({ props }) =>
        props.displayName?.toLowerCase().includes(tagFilter.toLowerCase())
      );


    const handleSelectAll = () => {
        const visibleTagsIds = Object.keys(filteredTags);
        const tagsWithNewStatus = visibleTagsIds
            .map(tagId => ({ [tagId]: !readOnlyAccess?.[tagId] }))
            .reduce((result, obj) => {
                return { ...result, ...obj };
            }, {});
          this.setState({
              readOnlyAccess: {
                  ...readOnlyAccess,
                  ...tagsWithNewStatus,
              }
            });
          visibleTagsIds.map(tag => {
              tagReadOnlyToggle(this.fullProps.authUser)(
                  tag,
                  this.fullProps.person.id,
                  !readOnlyAccess?.[tag]
              );
          });
          return;
      };

      const AllBtn = ({ onAllClick }) => (
          <button
              onClick={onAllClick}
              className={styles.buttonStyles}
          >
              Select All
          </button>
      );

      return (
        <DashboardBlock>
          <ModalHeader
            title={person ? person.displayName : ''}
            help={helpMsg}
          />

          {!allowed ? (
            <Row justify='center'>
              <Fa icon={faSpinner} spin style={{ fontSize: 28 }} />
            </Row>
          ) : (
            <>
              <SearchGridOld
                style={{ marginTop: 10, maxWidth: 350 }}
                textual={true}
                list={tagFilter ? searchTagItems : tagItems}
                filterStr={tagFilter}
                placeholder='Search Tags...'
                perPage={9}
                keyClicked={this._toggleTagFlag}
                filterChange={this._filterchange('tagFilter')}
              />
              {
                <div>
                  <label>
                    <input
                      type='checkbox'
                      onChange={this.requestPermissionToggling}
                      checked={canEditTagsAndFences}
                    />{' '}
                    Allow to edit tags and fences
                  </label>
                </div>
              }
              {(Object.keys(filteredTags).length > 0 || !!allowed.device) && (
                <>
                  <div className={styles.headerBlock}>
                      <p className={styles.title}>Visible list</p>
                      <AllBtn onAllClick={handleSelectAll} />
                  </div>
                  <TableSimple
                    sm
                    dark
                    striped
                    headers={['Label', 'Source', 'Access']}
                  >
                    {Object.keys(filteredTags || {}).map((tag) => (
                      <tr key={tag}>
                        <td
                            className={styles.labelTdStyles}
                        >
                          {filteredTags[tag].details.name}
                        </td>
                        <td>Tag</td>
                        <td>
                          <Checkbox
                            styles={{ marginTop: 6 }}
                            labelStyles={{ fontSize: 16, color: 'white' }}
                            inputId={tag}
                            onChange={() => {
                              this.setState({
                                readOnlyAccess: {
                                  ...readOnlyAccess,
                                  [tag]: !readOnlyAccess?.[tag],
                                },
                              });
                              tagReadOnlyToggle(this.fullProps.authUser)(
                                tag,
                                this.fullProps.person.id,
                                !readOnlyAccess?.[tag]
                              );
                            }}
                            checked={readOnlyAccess?.[tag]}
                            label='Read only'
                          />
                        </td>
                      </tr>
                    ))}
                  </TableSimple>
                </>
              )}
            </>
          )}
        </DashboardBlock>
      );
    };
  }
);
