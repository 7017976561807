import React from 'react';
import { pipe, keys, map, pathOr, path} from 'ramda';

import {AlertTypeTitle, iFullStoreState, iScheduledReport} from '../../shared/interfaces';
import {useRedux} from '../../states/redux-state';
import {IReportFiltersLabelsProps, IReportFiltersLabelsPropsFromStore} from './types';
import { FilterType } from '../pages/reports/ReportPageFilters/ReportPageFilters.interfaces';

export const ReportFiltersLabels = ({type, defaultLabel, render}: IReportFiltersLabelsProps) => {
    const {
        devicesDetails,
        reportDetails,
        people,
        tags
    } = useRedux((s: iFullStoreState): IReportFiltersLabelsPropsFromStore => ({
        devicesDetails: s.devicesData.devicesDetails,
        reportDetails: s.report.details,
        people: s.general.people,
        tags: s.general.tags
    }))
    const report: iScheduledReport = {...reportDetails, type: reportDetails.reportType} as any;
    let items = [];

    switch (type) {
        case FilterType.EDIT_ALERTS:
            items = Object.keys(report.filters?.['edit_alerts'] ?? {})

            if (!items.length) {
                items = [defaultLabel]
            } else {
                items = items.map(i => AlertTypeTitle(i))
            }

            break;
        case FilterType.EDIT_LABELS:
            items = Object.keys(report.filters?.['edit_labels'] ?? {[defaultLabel]: true})
            break;
        case FilterType.EDIT_TAGS:
            items = report.filters?.['edit_tags']
                ? pipe(
                    pathOr({}, ['filters', 'edit_tags']),
                    keys,
                    map(k => (path([k, 'details', 'name'])(tags)))
                )(report)
                : [defaultLabel];
            break;
        case FilterType.EDIT_DEVICES:
            items = report.filters?.['edit_device']
                ? pipe(
                    pathOr({}, ['filters', 'edit_device']),
                    keys,
                    map(k => devicesDetails.getIn([k, 'name']))
                )(report)
                : [defaultLabel]
            break;
        case FilterType.EDIT_PEOPLE:
            items = report.filters?.['edit_people']
                ? pipe(
                    pathOr({}, ['filters', 'edit_people']),
                    keys,
                    map(k => (path([k, 'displayName'])(people)))
                )(report)
                : [defaultLabel];
            break;
    }

    return (
        <>
            {items.map(item => render({item}))}
        </>
    )
}

