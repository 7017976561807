import React, { CSSProperties as css } from 'react';
import { pipe } from 'ramda';
import { RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';

import * as alertsdb from '../../../shared/db/alerts-db';
import ActionRow from '../../fence/tab-bodies/ActionRow/ActionRow';
import { BaseComponent } from '../../../shared/BaseComponent';
import C from '../../../shared/constants';
import DashboardBlock from '../../DashboardBlock';
import { FenceBodyLayout } from '../../fence/tab-bodies/BodyLayout/FenceBodyLayout/FenceBodyLayout';
import MinimalTile from '../../general/MinimalTile';
import ModalHeader from '../../menus/modal/modal-header';
import { localStorage } from '../../../shared/storage';
import {
  ItemType,
  UserAuth,
  iFenceDetails,
  iFullStoreState,
} from '../../../shared/interfaces';
import {
  iDeviceEvent,
  iEventInstance,
  iList,
} from '../../../shared/interfaces';
import {
  AlertSettingsPageNotify,
  DoNotDisturb,
  NonUserAlerts,
  SettingsPage,
} from './components';

import './AlertSettings.scss';

const fenceItemSelector = (state, ownProps) =>
  state.general.fences[ownProps.match.params.fenceType]?.[
    ownProps.match.params.deviceId
  ]?.[ownProps.match.params?.itemId];
const isFence = (ownProps) =>
  ownProps.match.params.itemId !== undefined &&
  ownProps.match.params.itemType === 'fence';

const mapStateToProps = (
  state: iFullStoreState,
  ownProps
): IPropsFromStore => ({
  authUser: state.auth.user!,
  event: state.general.deviceEvents[ownProps.match.params.eventKey],
  fenceItem: isFence(ownProps) ? fenceItemSelector(state, ownProps) : null,
});

type IPropsFromFence = {
  isVisible?: boolean;
  toggleVisible?: (visible: boolean) => void;
};

type IProps = RouteComponentProps<{
  eventKey: string;
  itemType: ItemType;
  itemId: string;
}>;

type IPropsFromStore = {
  event: iDeviceEvent;
  authUser: UserAuth;
  fenceItem?: iFenceDetails;
};

type IFullProps = IProps & IPropsFromFence & IPropsFromStore;

interface iState {
  tab: 'notify' | 'settings';
  alertInst: iEventInstance | 'initializing';
}

const headingStyle = (active): css => ({
  cursor: 'pointer',
  borderBottomStyle: 'solid',
  borderBottomColor: C.primaryColor,
  borderBottomWidth: active ? 3 : 1,
  padding: '0 20px',
  margin: '10px 0',
});

class AlertSettingsPage extends BaseComponent<IFullProps, iState> {
  state = {
    tab: 'notify',
    alertInst: 'initializing',
  } as iState;

  watchCloser;
  componentDidMount() {
    const {
      event: { key },
    } = this.props;
    const { itemType, itemId } = this.props.match.params;
    const { setState } = this;

    this.watchCloser = alertsdb.watchEventValues(
      { alertType: key, itemType, itemId },
      pipe((alertInst) => ({ alertInst }), setState.bind(this))
    );
  }

  componentWillUnmount() {
    if (this.watchCloser) this.watchCloser();
  }

  tabSwitcher = (tab) => () => this.setState((s) => ({ tab }));

  debugRender = () => {
    const { event, fenceItem } = this.props;
    const { itemType, itemId } = this.props.match.params;
    const { tabSwitcher } = this;
    const { tab, alertInst } = this.state;
    const isFence = itemType === ItemType.fence;

    if (alertInst === 'initializing') return null;

    const people = alertInst && alertInst.people ? alertInst.people : {};
    const customRecipients =
      alertInst && alertInst.custom ? alertInst.custom : ({} as iList<string>);

    const goBackCallback = () => {
      localStorage.set('fromAlertSettings', false);
    };

    const DynamicLayout: any = isFence ? FenceBodyLayout : DashboardBlock;
    const DynamicHeader = isFence ? null : (
      <ModalHeader
        title={this.props.event.name}
        goBackCallback={goBackCallback}
      />
    );

    return (
      <DynamicLayout
        style={!isFence ? {} : { maxWidth: 300 }}
        itemType={null}
        itemId={null}
        isVisible={this.props.isVisible}
        toggleVisible={this.props.toggleVisible}
        header={
          isFence ? (
            <ActionRow
              title={fenceItem?.name || 'New Item'}
              canBack
              actions={[]}
            />
          ) : (
            false
          )
        }
      >
        {DynamicHeader}

        <div
          style={{ display: 'flex', justifyContent: 'center', width: '100%' }}
        >
          <span
            onClick={tabSwitcher('notify')}
            style={headingStyle(tab == 'notify')}
          >
            Notify
          </span>
          <span
            onClick={tabSwitcher('settings')}
            style={headingStyle(tab == 'settings')}
          >
            Settings
          </span>
        </div>

        {tab == 'notify' ? (
          <div style={{ paddingLeft: 10 }}>
            <AlertSettingsPageNotify
              togglePerson={(personId) =>
                alertsdb.toggleAlertNotifyPerson(this.props.authUser)(
                  [{ itemType, itemId, alertType: event.key }],
                  personId,
                  !people[personId]
                )
              }
              notifyPeople={people}
            />
            <MinimalTile style={{ marginTop: 8, marginBottom: 8 }}>
              <NonUserAlerts
                recipients={customRecipients}
                toggleRecipient={(recipient, on) =>
                  on
                    ? alertsdb.addAlertNofityCustom(this.props.authUser)(
                        [{ itemType, itemId, alertType: event.key }],
                        recipient
                      )
                    : alertsdb.removeAlertNotifyCustom(this.props.authUser)(
                        { itemType, itemId, alertType: event.key },
                        recipient
                      )
                }
              />
            </MinimalTile>

            <MinimalTile style={{ marginTop: 8, marginBottom: 8 }}>
              <DoNotDisturb
                alertInst={alertInst}
                eventKey={event.key}
                itemType={itemType}
                itemId={itemId}
              />
            </MinimalTile>
          </div>
        ) : (
          <SettingsPage
            initialValue={(alertInst || ({} as any)).settings}
            onChange={(newVal) =>
              alertsdb.alertSettingVal(this.props.authUser)(
                itemType,
                itemId,
                event.key,
                newVal
              )
            }
            event={event}
          />
        )}
      </DynamicLayout>
    );
  };
}
export default connect(mapStateToProps)(AlertSettingsPage);
