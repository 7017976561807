import React from 'react';

export const CheckboxElement = (props) => {
  const { style = {} } = props;

  return (
    <span style={style}>
      <input type="checkbox" className="css-checkbox" {...props}/>
      <label htmlFor={props.id} className="css-label lite-blue-check"/>
    </span>
  )
}
