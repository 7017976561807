import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { fitText } from '../../../../shared/fit-text';
import { extractDevice } from '../../../../shared/helpers';
import {
  iFullStoreState,
  ItemType,
  iDeviceDetails,
  iDevicePing,
} from '../../../../shared/interfaces';
import { FaDevice, FaFav } from '../../../elements/fa';
import UserImage from '../../../general/UserImage';
import { FormatAddress } from '../../devices/device-list';
import { formatWhenLast } from '../../devices/device-page';
import * as favoritesdb from '../../../../shared/db/favorites-db';
import C from '../../../../shared/constants';
import { ITileProps } from './PersonTile.types';

import styles from './styles.module.scss';

export const PersonTile = ({ person, style = {} }: ITileProps) => {
  const isFavorite = useSelector<iFullStoreState, boolean>(
    (state) => !!state.general.favorites?.[ItemType.person]?.[person.id]
  );
  const assignedDevice = useSelector<
    iFullStoreState,
    iDeviceDetails | undefined
  >((state) =>
    extractDevice(state.devicesData.devicesDetails, person.hasDevice?.deviceId)
  );
  const assignedDeviceLastPing = useSelector<
    iFullStoreState,
    iDevicePing | undefined
  >((state) =>
    state.devicesData.devicesLastPing.get(person.hasDevice?.deviceId)
  );
  const uid = useSelector<iFullStoreState, string>(
    (state) => state.auth.user?.uid ?? ''
  );
  const userName = React.createRef();

  useEffect(() => {
    fitText([[userName.current, `person-tile-${uid}`]])
  }, [])

  const ago = formatWhenLast(
    assignedDevice ? assignedDeviceLastPing?.time : undefined
  );

  const toggleFav = (e) => {
    e.preventDefault()
    e.stopPropagation()
    favoritesdb.toggleFavorite(ItemType.person, uid, person.id, !isFavorite)
  }

  return (
    <Link
      className={styles.PersonTile}
      key={person.id}
      to={`/person/${person.id}`}
    >
      <div className={styles.PersonIcons}>
        <FaDevice active={!!person.hasDevice} />
        <UserImage key={person.id} person={person} size={50} />
        <FaFav
          isFav={isFavorite}
          style={{ color: C.yellow }}
          onClick={toggleFav}
        />
      </div>

      <div className={styles.PersonName} ref={userName as any}>
        {person.displayName}
      </div>

      {ago && (
        <span className={styles.PersonAgo} title={ago.toString()}>
          {ago}
        </span>
      )}

      <div className={styles.PersonStatus}>
        Status: {assignedDeviceLastPing?.msg ?? 'N/A'}
      </div>

      {assignedDevice && (
        <>
          <FormatAddress
            style={{
              alignSelf: 'stretch',
              fontSize: 11,
              paddingTop: 5,
              textAlign: 'center',
            }}
            address={assignedDeviceLastPing?.address}
          />
          <div style={{ fontSize: 11, paddingTop: 10 }}>
            {assignedDevice.name}
          </div>
        </>
      )}
    </Link>
  )
}