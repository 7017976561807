import React from 'react';
import { connect } from 'react-redux';
import { values } from 'ramda';
import { faSpinner } from '@fortawesome/fontawesome-free-solid';

import { BaseComponent } from '../../../shared/BaseComponent';
import { localStorage  } from '../../../shared/storage';
import DashboardBlock from '../../DashboardBlock';
import ModalHeader from '../../menus/modal/modal-header';
import {
  iFullStoreState,
} from '../../../shared/interfaces';
import { StandardItem } from '../../general';
import { Fa } from '../../elements/fa';
import { Row } from '../../elements/flex';
import { SearchGridOld } from '../../../components/SearchGridOld/SearchGridOld';
import instance from '../../../api/instance';
import {
  IFullProps,
  StateInterface,
  helpMsg,
  IPropsFromStore,
} from './PersonVisibleRFIDTags.types';


export default connect(
  (s: iFullStoreState, o: any): IPropsFromStore => ({
    person: s.general.people[o.match.params.id],
  })
)(
  class PersonVisibleRFIDTags extends BaseComponent<IFullProps, StateInterface> {
    constructor(props) {
      super(props);
      this.state = {
        tagFilter: '',
        currentRFIDTag: { id: null },
        allRFIDTags: [],
      };
    }

    getAllRFIDTags = async () => {     
      const clientId = localStorage.get('active-client');
 
      try {
        const response: { data } = await instance.get(`/api/rfid-tag/all-tags?orgKey=${clientId}`);
        this.setState({ allRFIDTags: response.data.data })
      } catch (error) {
        console.error(error)
      }
    }

    getRFIDTag = async () => {
      const {
        params: { id: personId },
      } = this.props.match;

      try {
        const response: { data } = await instance.get(`api/rfid-tag/user-tag?user_id=${personId}`);
        this.setState({ currentRFIDTag: response.data })
      } catch (error) {
        console.error(error)
      }
    }

    async componentDidMount() {
      this.getAllRFIDTags()
      this.getRFIDTag();
    }

    assignTag = async (body) => {
      try {
        const response: { data } = await instance.post(`api/rfid-tag/assigned-user`, body);
        this.setState({ currentRFIDTag: response.data })
      } catch (error) {
        console.error(error)
      }
    }

    unassignTag = async (body) => {
      try {
        const response: { data } = await instance.post(`api/rfid-tag/unassigned-user`, body);
        this.setState({ currentRFIDTag: { id: null } });
      } catch (error) {
        console.error(error)
      }
    }

    _toggleTagFlag = async (tagId) => {

      const tagIdNumber = Number(tagId);

      const {
        params: { id: personId },
      } = this.props.match;

      const body = {
        tag_id: tagId,
        user_id: personId,
      }

      if (this.state?.currentRFIDTag?.id) {
        if (this.state?.currentRFIDTag?.id !== tagIdNumber) {
          this.assignTag(body);
        } else {
          this.unassignTag(body);
        }
      } else {
        this.assignTag(body);
      }

    };

    _filterchange = (key: keyof StateInterface) => (str) =>
      this.setState({ [key]: str } as any);

    debugRender = () => {
      const { person } = this.props;
      const {
        tagFilter,
      } = this.state;

      const allRFIDTags = this.state.allRFIDTags ?? [];

      const tagItems = values(allRFIDTags).map((tag) => {
        const current = tag.id === this.state?.currentRFIDTag?.id
        return (
          <StandardItem
            style={
              {
                cursor: 'pointer',
                display: 'inline-block',
                margin: 8,
                fontSize: 'initial',
                color: current ? "white" : '#2f76c3',
                backgroundColor: current ? "#2f76c3" : 'rgb(229, 229, 229)',
              }
            }
            key={tag.id}
            displayName={tag.name}
            itemId={tag.id}
          />
        )
      });

      const searchTagItems = tagItems.filter(({ props }) =>
        props.displayName?.toLowerCase().includes(tagFilter.toLowerCase())
      );

      return (
        <DashboardBlock>
          <ModalHeader
            title={person ? person.displayName : ''}
          // help={helpMsg}
          />

          {!allRFIDTags.length ? (
            <Row justify='center'>
              <Fa icon={faSpinner} spin style={{ fontSize: 28 }} />
            </Row>
          ) : (
            <>
              <SearchGridOld
                style={{ marginTop: 10, maxWidth: 350 }}
                textual={true}
                list={tagFilter ? searchTagItems : tagItems}
                filterStr={tagFilter}
                placeholder='Search Tags...'
                perPage={9}
                keyClicked={this._toggleTagFlag}
                filterChange={this._filterchange('tagFilter')}
              />
            </>
          )}
        </DashboardBlock>
      );
    };
  }
);
