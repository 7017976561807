import React from 'react';
import {Link, useHistory} from 'react-router-dom';

import {isMobile} from '../../../../shared/helpers';
import {Button, ButtonSize, ButtonStyles} from '../../../Button';

export const CreateReportActions = ({saveReport}) => {
  const history = useHistory();
  const handleBackClick = () => history.goBack();
  return (
    <div className="create-report-actions">
      {!(isMobile || window["cordova"]) &&
          <div className="create-report-actions-left">
              <Button
                  styleType={ButtonStyles.BLACK_WHITE}
                  size={ButtonSize.MD}
                  onClick={handleBackClick}
              >
                  Back
              </Button>
          </div>
      }
      <div className="create-report-actions-right">
        <Link to={"/reports/scheduled"}>
          <Button
            className="but white but-28px"
            styleType={(isMobile || window["cordova"]) ? ButtonStyles.WHITE_GRAY : ButtonStyles.GRAY_INACTIVE}
            size={(isMobile || window["cordova"]) ? ButtonSize.LG : ButtonSize.MD}
          >
            Cancel
          </Button>
        </Link>
        <Button
          styleType={ButtonStyles.ORANGE_WHITE}
          size={(isMobile || window["cordova"]) ? ButtonSize.LG : ButtonSize.MD}
          onClick={saveReport}
        >
          Apply
        </Button>
      </div>
    </div>
  )
}
