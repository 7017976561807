import React, {useEffect, useState} from 'react';
import {get} from 'lodash';
import {faPencilAlt} from '@fortawesome/fontawesome-free-solid';

import {iItemsAllowed, iList, iTag, iVisibleDevicesTags} from '../../../../shared/interfaces';
import {getPersonAllowedSee} from '../../../../shared/db/general-db';
import LocalPersonTile from '../LocalPeopleTile';
import {ACL, UserCan} from '../../../../shared/constants';
import {FaLink} from '../../../elements/fa';
import TableSimple from '../../../elements/TableSimple';

export const VisibleTagsTile = (props: iVisibleDevicesTags) => {
  const [tags, setTags] = useState<iList<iTag>>();
  const [allowed, setAllowed] = useState<iItemsAllowed>();
  const { userCanDo, personId } = props;

  useEffect(() => {
    const filteredTags = Object.keys(props.tags).reduce((acc, rec) => {
      if (get(props.tags[rec], `instances.allowed-see.person.${personId}`)) {
        return {...acc, [rec]: props.tags[rec]};
      }

      return acc;
    }, {});

    const getAllowedItems = async () => {
      const data = await getPersonAllowedSee(props.personId);

      setAllowed(data);
    }

    getAllowedItems();
    setTags(filteredTags);
  }, []);

  const hideTable = !tags || Object.keys(tags).length === 0;

  return (
    <LocalPersonTile
      collapsible
      initialCollapsed
      title='Visible Tags'
      loading={!allowed}
      action={
        !ACL.check(UserCan.DO_ANYTHING, userCanDo) ? undefined : (
          <FaLink
            to={`/person/${personId}/visible-tags`}
            icon={faPencilAlt}
            style={{ color: '#fff' }}
          />
        )
      }
    >
      {(!allowed || hideTable) ? null : (
        <TableSimple
          sm
          striped
          headers={['Tag label', 'Read only']}
          style={{ fontSize: 11 }}
        >
          {Object.keys(tags || {}).map((filterId) => (
            <tr key={filterId}>
              <td
                style={{
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  maxWidth: 170,
                }}
              >
                {tags[filterId].details.name}
              </td>
              <td>
                {get(
                  tags[filterId],
                  `instances.allowed-see.readOnly.${personId}`,
                  false
                ).toString()}
              </td>
            </tr>
          ))}
        </TableSimple>
      )}
    </LocalPersonTile>
  )
}
