import React, {useEffect, useState} from 'react';

import {iItemsAllowed, iVisibleDevicesTags} from '../../../../shared/interfaces';
import {getPersonAllowedSee} from '../../../../shared/db/general-db';
import LocalPersonTile from '../LocalPeopleTile';
import {ACL, UserCan} from '../../../../shared/constants';
import {FaLink} from '../../../elements/fa';
import {faPencilAlt} from '@fortawesome/fontawesome-free-solid';
import TableSimple from '../../../elements/TableSimple';

import styles from '../PersonTabPage.module.scss';

export const VisibleDevicesTile = (props: iVisibleDevicesTags) => {
  const [allowed, setAllowed] = useState<iItemsAllowed>();
  const { userCanDo, personId, devicesDetails } = props;

  useEffect(() => {
    const getAllowedItems = async () => {
      const data = await getPersonAllowedSee(props.personId);

      setAllowed(data);
    }

    getAllowedItems();
  }, []);

  const getSource = (deviceId) => {
    if (allowed && allowed.device) {
      return Array.from(new Set(Object.values(allowed.device[deviceId]).filter(e => typeof e !== 'boolean'))).join(' & ');
    }

    return '';
  }

  const hideTable = allowed?.device && Object.keys(allowed.device).length === 0;

  return (
    <LocalPersonTile
      collapsible
      initialCollapsed
      title='Visible Devices'
      loading={!allowed}
      action={
        !ACL.check(UserCan.DO_ANYTHING, userCanDo) ? undefined : (
          <FaLink
            to={`/person/${personId}/visible-devices`}
            icon={faPencilAlt}
            style={{ color: '#fff' }}
          />
        )
      }
    >
      {(!allowed || hideTable) ? null : (
        <TableSimple
          sm
          striped
          headers={['Device', 'Source']}
          style={{ fontSize: 11 }}
        >
          {Object.keys(allowed.device || {})
            .filter((id) => devicesDetails.has(id))
            .map((id) => devicesDetails.get(id))
            .map((device) => (
              <tr key={device!.id}>
                <td className={styles.tableItem}>{device!.name}</td>
                <td>{getSource(device!.id)}</td>
              </tr>
            ))}
        </TableSimple>
      )}
    </LocalPersonTile>
  )
}
