import React, {useState, useRef, useEffect, useLayoutEffect} from 'react';
import {connect} from 'react-redux';
import Marker from 'react-google-maps/lib/components/Marker';
import InfoBox from 'react-google-maps/lib/components/addons/InfoBox';
import * as color from 'color';
import {compose} from 'redux';

import {IconTypes, iFullStoreState} from '../../../../shared/interfaces';
import {getIconTabByName} from '../../../../shared/constants';
import {IInfoMarkerProps, IInfoMarkerPropsFromState} from './types';
import {setSelectedPoint} from '../../../../stores/reducers/selectedPoint/AC';
import {Actions} from '../../../../stores/reducers/gmap-reducers';
import {FaVector} from '../../../elements/fa';

import styles from './index.module.scss';

const mapStateToProps = (state: iFullStoreState, o: IInfoMarkerProps) => ({
  deviceDetails: state.devicesData.devicesDetails.get(o.deviceId),
  deviceLastPing: state.devicesData.devicesLastPing.get(o.deviceId),
  bounds: state.gmap.bounds,
});

const loadIcon = async (
  url: string,
  iconColor: string = '#000'
): Promise<string> => {
  const response = await fetch(url);
  const content = await response.text();

  const isSvg = content.includes('svg');

  if (isSvg) {
    const parser = new DOMParser();
    const svgDocument = parser.parseFromString(content, 'image/svg+xml');

    svgDocument
      .getElementsByTagName('svg')
      .item(0)
      ?.setAttribute('fill', iconColor.toUpperCase());

    const svgString = new XMLSerializer().serializeToString(svgDocument);
    const blob = new Blob([svgString], { type: 'image/svg+xml;charset=utf-8' });

    return URL.createObjectURL(blob);
  } else {
    return url;
  }
};

const DeviceMarker = (props) => {
  const prevPropsRef = useRef<IInfoMarkerPropsFromState>();
  const { deviceDetails, deviceLastPing, dispatch, bounds } = props;
  const [markerIcon, setMarkerIcon] = useState<any>(null as any);
  const pingMsg = deviceLastPing?.msg ?? 'N/A';
  const hasSpeed = Boolean(deviceLastPing?.speed);

  const recenterToDevice = () => {
    const { dispatch, deviceId, doPan = false } = props;

    if (doPan) {
      dispatch(Actions.RECENTER_MAP_TO_DEVICE(deviceId, true));
    }
  };

  const updateMarker = async () => {
    const { size } = props;
    const { icon, color } = props.deviceDetails;

    let iconType = icon?.type;
    let url: string;

    if (!iconType) {
      if ('fa' in icon) {
        iconType = IconTypes.FaIcon;
      } else if ('svgIconName' in icon) {
        iconType = IconTypes.SvgIcon;
      } else {
        iconType = IconTypes.UserSvgIcon;
      }
    }

    switch (iconType) {
      case IconTypes.FaIcon:
        url = FaVector(icon.fa);
        break;
      case IconTypes.SvgIcon:
        const iconTab = getIconTabByName(icon.svgIconName);
        url = require(`../../../../assets/svg/${iconTab}/${icon.svgIconName}.svg`);
        break;
      case IconTypes.UserSvgIcon:
        url = icon?.url;
        break;
    }

    if (iconType === IconTypes.SvgIcon) {
      setMarkerIcon(null);
      url = await loadIcon(url, color);
    }

    const isIcon = 'url' in icon;
    const isFa = 'fa' in icon;
    const isSvg = !isIcon && !isFa;
    const fixedSize = 13;

    const marker = {
      [isFa ? 'path' : 'url']: url,

      size: new google.maps.Size(size, size),
      origin: new google.maps.Point(0, 0),
      strokeWeight: 0,

      ...(isIcon && {
        size: new google.maps.Size(fixedSize * 3, fixedSize * 3),
        anchor: new google.maps.Point(fixedSize, fixedSize * 3),
        scaledSize: new google.maps.Size(fixedSize * 3, fixedSize * 3),
      }),

      ...(isFa && {
        anchor: new google.maps.Point(100, 500),
        scale: 0.06,
        scaledSize: new google.maps.Size(size / 2, size / 2),
        fillColor: color || '#000',
        fillOpacity: 0.95,
      }),

      ...(isSvg && {
        size: new google.maps.Size(fixedSize * 4, fixedSize * 4),
        anchor: new google.maps.Point(fixedSize, fixedSize * 2.5),
        scaledSize: new google.maps.Size(fixedSize * 4, fixedSize * 4),
        fillColor: color || '#000',
        fillOpacity: 0.95,
      }),
    };

    setMarkerIcon(marker);
  };

  useLayoutEffect(() => {
    updateMarker();
  }, []);


  useEffect(() => {
    recenterToDevice();
  }, []);

  useEffect(() => {
    const prevProps = prevPropsRef.current;

    if (prevProps) {
      const prevLastLocation = prevProps.deviceLastPing?.coordinates.location;
      const lastLocation = props.deviceLastPing?.coordinates.location;

      if (!prevLastLocation && !!lastLocation) {
        recenterToDevice();
      }

      if (prevProps.deviceDetails.icon !== props.deviceDetails.icon) {
        updateMarker();
      }
    }

    prevPropsRef.current = props;
  }, [props]);

  if (!deviceLastPing || !deviceDetails) return null;

  const {
    device: deviceId,
    tripId,
    pointId,
    coordinates: { location },
  } = deviceLastPing;

  const renderDevice =
    location.lat > bounds.latSouth &&
    location.lng > bounds.lngWest &&
    location.lat < bounds.latNorth &&
    location.lng < bounds.lngEast;

  if (!renderDevice) return;

  const pingMsgCheck = (pingMsg === 'Ignition on' ||
    pingMsg === 'Acceleration Threshold' ||
    pingMsg === 'Acceleration threshold' ||
    pingMsg === 'Deceleration Threshold' ||
    pingMsg === 'Deceleration threshold' ||
    pingMsg === 'Idle Threshold' ||
    pingMsg === 'Idle threshold' ||
    pingMsg === 'Ignition On Report' ||
    pingMsg === 'Ignition On Interval Report' ||
    pingMsg === 'Direction Change' ||
    pingMsg === 'Virtual Ignition On Interval Report' ||
    pingMsg === 'Virtual Ignition On Report' ||
    pingMsg === 'Move Start' ||
    pingMsg === 'Move Stop' ||
    pingMsg === 'Trip Start');

  return (
    <>
      {markerIcon && (
        <>
          <Marker
            zIndex={8}
            position={location}
            onClick={() =>
              dispatch(
                setSelectedPoint(deviceId, tripId, pointId, location)
              )
            }
            icon={markerIcon}
          />
          <InfoBox
            options={{
              disableAutoPan: true,
              zIndex: 4,
              boxStyle: {
                zIndex: 4,
                width: '1px',
                overflow: 'visible',
                padding: '2px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                opacity: '.9',
              },
              alignBottom: true,
              closeBoxURL: '',
              pixelOffset: new google.maps.Size(9, 29),
            }}
            position={new google.maps.LatLng(location.lat, location.lng)}
          >
            <div
              className={styles.messageBlock}
              style={{
                backgroundColor: deviceDetails.color,
                color: color(deviceDetails.color).light() ? '#000' : '#fff',
                border: `1px solid ${color(deviceDetails.color).darken(
                  0.5
                )}`
              }}
            >
              {deviceDetails.name}

              {pingMsg === 'Heartbeat' && (
                <div className={`${styles.indicatorDot} ${styles.pink}`} />
              )}

              {(pingMsg === 'Ignition off' ||
                pingMsg === 'Ignition Off Report' ||
                pingMsg === 'Ignition off Report' ||
                pingMsg === 'Ignition Off Interval Report' ||
                pingMsg === 'Virtual Ignition Off Report' ||
                pingMsg === 'Trip Stop') && (
                <div className={`${styles.indicatorDot} ${styles.red}`} />
              )}

              {pingMsgCheck && !hasSpeed && (
                <div
                  className={`${styles.indicatorDot} ${styles.yellow}`}
                />
              )}

              {pingMsgCheck && hasSpeed && (
                <div
                  className={`${styles.indicatorDot} ${styles.green}`}
                />
              )}
            </div>
          </InfoBox>
        </>
      )}
    </>
  )
}

export default compose(connect(mapStateToProps), React.memo)(DeviceMarker);