import {
	pipe,
	pathOr,
	prop,
	filter,
	keys,
	map,
	join,
	startsWith,
	values,
	always,
} from 'ramda';

import {utils, writeFileXLSX} from 'xlsx';

import {
	AlertTypeTitle,
	iTrip,
	iDevicePing,
	iList,
	iPerson,
	iReportTravelData,
	iReportStaticData,
	iFullTravelData,
	iFullStaticData,
	iSummaryItemResults,
	iExportReportPdfProps,
	iExportReportProps,
	DateTimeFormatOptions,
} from '../../../shared/interfaces';
import { csvExporter } from '../../../shared/csv';
import { DevicesDetailsContainer } from '../../../stores/reducers/devicesData';
import {
	friendlyDiff,
	minutesToFriendly,
	friendlySpeed,
	friendlyMilesPer,
	friendlyDist,
	countDateWithUserAndTimezoneOffset,
	exportPDF,
	calcTotalTime,
	timeClockRecords,
} from '../../../shared/helpers';
import moment from 'moment';
import autoTable, { UserOptions } from 'jspdf-autotable';
import {
	mapFromDeviceResultToTableBody,
	checkFileResolution,
	generateSummaryResults, mapFromDeviceResultToTableXlsx,
} from './SummaryReport/helper';
import {getPermissionsForUsers} from "./report-utils";
import {clientStore} from "../../../shared/firebase";
import jsPDF from "jspdf";

interface iTravelTripData {
	id: string;
	personId?: string;
	label?: string;
	device?: string;
}

// we can't really use device units preference
// because we are combining devices.
const _tripRecordToRow =
	(devicesDetails: DevicesDetailsContainer, people: iList<iPerson>) => (record: iTrip) => {
		const timezone = devicesDetails.getIn([record.device, 'timezone']);
		const start = countDateWithUserAndTimezoneOffset(record.startDate, timezone);
		const end = countDateWithUserAndTimezoneOffset(record.endDate, timezone);
		return {
			'Device Name': devicesDetails.getIn([record.device, 'name']) || 'device not found',
			'Trip Minutes': record.endDate.diff(record.startDate, 'minutes'),
			'Person': people[record.personId] ? people[record.personId].displayName : 'N/A',
			'Label': record.label || 'N/A',
			'Start Time': start.format('M/D/YY h:mm A'),
			'Start Address': record.startAddress,
			'End Time': end.format('M/D/YY h:mm A'),
			'End Address': record.endAddress,
			'Idle Minutes': record.idleMinutes,
			'Stopped Minutes': record.stopMinutes.toFixed(1),
			'Max Speed': record.maxSpeed,
			'Average Speed': record.averageSpeed.toFixed(1),
			'Miles Traveled': record.miles,
			MPG: record.mpg,
			'Safty Percent': `${record.safetyPercent}%`,
			// Alerts: pipe(
			// 	prop('alertActivity'),
			// 	filter(Boolean),
			// 	keys,
			// 	filter(startsWith('has')),
			// 	map(AlertTypeTitle),
			// 	join(', ')
			// )(record),
		};
	};

const _timeClockRecordToRow =
	(devicesDetails: DevicesDetailsContainer, people: iList<iPerson>) => (record: iTrip) => {
		return {
			'Device Name': devicesDetails.getIn([record.device, 'name']) || 'device not found',
			'Start Date': record.startDate.format('MM/DD/YYYY'),
			'Start Time': record.startDate.format('h:mmA'),
			'End Time': record.endDate.format('h:mmA'),
			'Total time': calcTotalTime(record.endDate, record.startDate),
		};
	};

const _pointToRow =
	(devicesDetails: DevicesDetailsContainer, people: iList<iPerson>) => (record: iDevicePing) => {
		const timezone = devicesDetails.getIn([record.device, 'timezone']);
		const dateWithCorrection = countDateWithUserAndTimezoneOffset(record.time, timezone);
		return {
			Person: pathOr('Not assigned', [record.personId, 'displayName'], people),
			Device: devicesDetails.getIn([record.device, 'name']) || '',
			Time: dateWithCorrection.format('M/D/YY h:mm A'),
			Street: record.address.street,
			City: record.address.city,
			State: record.address.state,
			Zip: record.address.zip,
			Latitude: record.coordinates.location.lat,
			Longitude: record.coordinates.location.lng,
			Label: record.label,
			Fuel: record.fuel,
			MPG: record.mpg,
			Miles: record.miles,
			Message: record.msg,
			'Posted Speed': record.posted_speed,
			MPH: record.speed,
			Voltage: record.volt,
		};
	};

export const exportReport = ({
	displayRecords,
	reportType,
	devicesDetails,
	people,
}: iExportReportProps) =>
	pipe(
		always(displayRecords),
		values,
		map(
			reportType === 'travel'
				? _tripRecordToRow(devicesDetails, people)
				: _pointToRow(devicesDetails, people)
		),
		// turn into header row + values of rest
		records => [keys(records[0]), ...records.map(values)],
		csvExporter('report.csv')
	) as () => any;

const getReportTravelData = ({ group, device, people }: iReportTravelData): UserOptions[] => {
	const { timezone, name, units } = device;
	return Object.values(group).map(row => {
		const start = countDateWithUserAndTimezoneOffset(row.startDate, timezone);
		const end = countDateWithUserAndTimezoneOffset(row.endDate, timezone);
		const personKey = row.personId;
		const person = people[personKey] || { displayName: '' };
		return {
			head: [[name, start.format('M/D/YY'), '', `${start.format('h:mmA')} - ${row.startAddress}`]],
			body: [
				['', 'Duration:', friendlyDiff(moment(row.endDate), moment(row.startDate))],
				['', 'Idle:', minutesToFriendly(row.idleMinutes)],
				['', 'Stopped:', minutesToFriendly(row.stopMinutes)],
				['', 'Safety:', row.safetyPercent ? row.safetyPercent + '%' : 'N/A'],
				['', 'Person', person.displayName],
				['', 'Avg. Speed:', `${row.averageSpeed ? friendlySpeed(row.averageSpeed, units) : 'N/A'}`],
				['', 'Max Speed', `${row.maxSpeed ? friendlySpeed(row.maxSpeed, units) : 'N/A'}`],
				['', 'Distance', friendlyDist(row.miles, units)],
				['', 'Consumption:', friendlyMilesPer(row.mpg, units)],
				['', 'Label', row.label || ''],
			],
			foot: [['', '', '', `${end.format('h:mmA')} - ${row.endAddress}`]],
		};
	});
};

const getReportTravelDataXlsx = ({ group, device, people }: iReportTravelData) => {
	const { timezone, name, units } = device;
	return Object.values(group).map(row => {
		const start = countDateWithUserAndTimezoneOffset(row.startDate, timezone);
		const end = countDateWithUserAndTimezoneOffset(row.endDate, timezone);
		const personKey = row.personId;
		const person = people[personKey] || { displayName: '' };
		return {
			deviceName: name,
			duration: friendlyDiff(moment(row.endDate), moment(row.startDate)),
			label: row.label || 'N/A',
			person: person.displayName || 'N/A',
			startTime: start.format('M/D/YY') + ' ' + start.format('h:mmA'),
			startAddress: row.startAddress,
			endTime: end.format('M/D/YY') + ' ' + end.format('h:mmA'),
			endAddress: row.endAddress,
			idleMinutes: minutesToFriendly(row.idleMinutes),
			stoppedMinutes: minutesToFriendly(row.stopMinutes),
			maxSpeed: row.maxSpeed ? friendlySpeed(row.maxSpeed, units) : 'N/A',
			averageSpeed: row.averageSpeed ? friendlySpeed(row.averageSpeed, units) : 'N/A',
			milesTraveled: friendlyDist(row.miles, units),
			mpg: friendlyMilesPer(row.mpg, units),
			safetyPercent: row.safetyPercent ? row.safetyPercent + '%' : 'N/A'
		};
	});
};

const formattedTimeCLockData = (records): iDevicePing[] => {
	const groupedData = {};
	timeClockRecords(records).map(record => {
		const date = record.startDate.format("MM/DD/YYYY");
		const objectKey = record.device + date;
		if (!groupedData[objectKey]) {
			//if no such deviceName exists in groupedData,
			// then we add it there and set the initial values
			groupedData[objectKey] = {
				...record,
			};
		} else {
			// if deviceName is already in groupedData,
			// then compare the dates and update the values if necessary
			if (record.startDate < groupedData[objectKey].startDate) {
				groupedData[objectKey].startDate = record.startDate;
			}
			if (record.endDate > groupedData[objectKey].endDate) {
				groupedData[objectKey].endDate = record.endDate;
			}
		}
	});
	return  Object.values(groupedData);
}

const getReportTimeClockData = ({ group, device, people }: iReportTravelData, fileType): UserOptions[] => {
	const { name } = device;
	const formattedData = formattedTimeCLockData(Object.values(group));
	return formattedData.map(row => {

	if (fileType === 'pdf') {
		return {
			head: [['Device name', name]],
			body: [
				['Start date', row.startDate.format('MM/DD/YYYY')],
				['Start time', row.startDate.format('h:mmA')],
				['End time', row.endDate.format('h:mmA')],
			],
			foot: [['Total', calcTotalTime(row.endDate, row.startDate)]],
		};
	} else if (fileType === 'xlsx') {
		return {
			deviceName: name,
			startDate: row.startDate.format('MM/DD/YYYY'),
			startTime: row.startDate.format('h:mmA'),
			endTime: row.endDate.format('h:mmA'),
			total: calcTotalTime(row.endDate, row.startDate),
		}
	}
	});
};

const getReportStaticData = ({ group, device, people }: iReportStaticData): UserOptions[] => {
	const { timezone, name } = device;
	const bodyData = Object.values(group).map(row => {
		const dateWithCorrection = countDateWithUserAndTimezoneOffset(row.time, timezone);
		const personKey = row.personId;
		const name = people[personKey] ? people[personKey].displayName : 'N/A';

		return [
			dateWithCorrection.format('MMM-D'),
			dateWithCorrection.format('h:mmA'),
			name,
			row.label || 'N/A',
			row.fuel,
			row.volt,
			row.speed,
			row.posted_speed,
			row.mpg,
			row.msg,
			`${row.address.street} ${row.address.city} ${row.address.state} ${row.address.zip}`,
			`${row.coordinates.location['lat']}, ${row.coordinates.location['lng']}`,
		];
	});

	return [
		{
			theme: 'grid',
			headStyles: { fillColor: '#2E80BA' },
			head: [[name, 'Data', 'Person', 'Label', 'Fuel', 'Voltage', 'mph', 'Posted', 'mpg', 'Message', 'Location', 'Coordinates']],
			body: bodyData,
		},
	];
};

const getReportStaticDataXlsx = ({ group, device, people }: iReportStaticData) => {
	const { timezone, name } = device;
	return  Object.values(group).map(row => {
		const dateWithCorrection = countDateWithUserAndTimezoneOffset(row.time, timezone);
		const personKey = row.personId;
		const personName = people[personKey] ? people[personKey].displayName : 'N/A';

		return {
			name,
			date: dateWithCorrection.format('MMM-D') + " " + dateWithCorrection.format('h:mmA'),
			person: personName,
			label: row.label || 'N/A',
			fuel: row.fuel,
			voltage: row.volt,
			mph: row.speed,
			posted: row.posted_speed,
			mpg: row.mpg,
			message: row.msg,
			location: `${row.address.street} ${row.address.city} ${row.address.state} ${row.address.zip}`,
			coordinates: `${row.coordinates.location['lat']}, ${row.coordinates.location['lng']}`,
		};
	});
};

const getFullTravelData = ({ records, reportData, people }: iFullTravelData) => {
	const fullTravelData = [];
	Object.entries(records).forEach(([deviceId, group], index) => {
		const device = reportData.get(deviceId);
		if (!device) return;
		const travelTableData = getReportTravelData({ group, device, people });

		// travelTableData is an array of UserOptions
		// we need to save an one array instead array of UserOptions arrays
		fullTravelData.push(...travelTableData);
	});

	return fullTravelData;
};

const getTravelDataXlsx = ({ records, reportData, people }: iFullTravelData) => {
	const fullTravelData = [];
	Object.entries(records).forEach(([deviceId, group], index) => {
		const device = reportData.get(deviceId);
		if (!device) return;
		const travelTableData = getReportTravelDataXlsx({ group, device, people });

		// travelTableData is an array of UserOptions
		// we need to save an one array instead array of UserOptions arrays
		fullTravelData.push(...travelTableData);
	});

	return fullTravelData;
};

const getFullStaticData = ({ records, reportData, people }: iFullStaticData) => {
	const fullStaticData = [];
	Object.entries(records).forEach(([deviceId, group], index) => {
		const device = reportData.get(deviceId);
		if (!device) return;
		const staticTableData = getReportStaticData({ group, device, people });

		// travelTableData is an array of UserOptions
		// we need to save an one array instead array of UserOptions arrays
		fullStaticData.push(...staticTableData);
	});

	return fullStaticData;
};

const getFullStaticDataXlsx = ({ records, reportData, people }: iFullStaticData) => {
	const fullStaticData = [];
	Object.entries(records).forEach(([deviceId, group], index) => {
		const device = reportData.get(deviceId);
		if (!device) return;
		const staticTableData = getReportStaticDataXlsx({ group, device, people });

		// travelTableData is an array of UserOptions
		// we need to save an one array instead array of UserOptions arrays
		fullStaticData.push(...staticTableData);
	});

	return fullStaticData;
};

export const exportTimeClockReportCsv = ({
   displayRecords,
   devicesDetails,
   people,
}) => {
	return pipe(
		always(formattedTimeCLockData(Object.values(displayRecords))),
		values,
		map(_timeClockRecordToRow(devicesDetails, people)),
		// turn into header row + values of rest
		records => [keys(records[0]), ...records.map(values)],
		csvExporter('report_time-clock.csv')
	) as () => any
};


export const exportTimeClockReportPdf = ({
  records,
  reportData,
  people,
}) => {
	const allTables = getFullTimeClockData({ records, reportData, people }, 'pdf');

	exportPDF({
		fileName: 'Report_time-clock',
		data: allTables,
	});
};

export const exportTimeClockReportXlsx = ({
  records,
  reportData,
  people,
}) => {
	const allTables = getFullTimeClockData({ records, reportData, people }, 'xlsx');

	const worksheet = utils.json_to_sheet(allTables);
	// column width in file
	worksheet['!cols'] = [{wch: 20}, {wch: 15}, {wch: 8}, {wch: 8}, {wch: 8}];
	const workbook = utils.book_new();
	utils.book_append_sheet(workbook, worksheet, "report_time-clock");
	writeFileXLSX(workbook, "Report_time-clock.xlsx");
};

const getFullTimeClockData = ({ records, reportData, people }: iFullTravelData, fileType) => {
	const fullTravelData = [];
	Object.entries(records).forEach(([deviceId, group], index) => {
		const device = reportData.get(deviceId);
		if (!device) return;
		const travelTableData = getReportTimeClockData({ group, device, people }, fileType);

		fullTravelData.push(...travelTableData);
	});
	return fullTravelData;
};

export const exportReportPdf: (params: iExportReportPdfProps) => void = ({
	records,
	reportData,
	report,
	people,
}) => {
	const allTables =
		report.reportType === 'travel'
			? getFullTravelData({ records, reportData, people })
			: getFullStaticData({ records, reportData, people }); // report.reportType === 'static'

	exportPDF({
		fileName: 'Report',
		data: allTables,
	});
};

export const exportReportXlsx: (params: iExportReportPdfProps) => void = ({
  records,
  reportData,
  report,
  people,
}) => {
	const allTables =
		report.reportType === 'travel'
			? getTravelDataXlsx({ records, reportData, people })
			: getFullStaticDataXlsx({ records, reportData, people }); // report.reportType === 'static'
	const worksheet = utils.json_to_sheet(allTables);
	const workbook = utils.book_new();
	utils.book_append_sheet(workbook, worksheet, "report");
	writeFileXLSX(workbook, "Report.xlsx");
};

export const exportSummary = (data, deviceUnits, devicesDetails, reportDates, exportType) => {
	const results = {};

	Object.values(data).forEach((deviceTrips: any) => {
		const deviceName = devicesDetails.get(deviceTrips[0].device)?.name || 'Unknown Device';
		results[deviceTrips[0].device] = generateSummaryResults(deviceTrips, deviceName, 'summary');
	});

	const outputPDF = Object.values(results).map((device: iSummaryItemResults) => {
		return {
			head: [[device.device, '']],
			body: mapFromDeviceResultToTableBody(device, deviceUnits),
		};
	});

	const outputXLSX = Object.values(results).map((device: iSummaryItemResults) => mapFromDeviceResultToTableXlsx(device, deviceUnits))

	const outputCSV = [
		['Device', 'Average Speed', 'Safety', 'Drive Time', 'Stopped Time', 'Total Distance'],
	];
	Object.values(results).forEach(
		(device: iSummaryItemResults, index: number, array: iSummaryItemResults[]) => {
			const deviceInfo = [...mapFromDeviceResultToTableBody(device, deviceUnits, true)];
			outputCSV.push(...deviceInfo);
		}
	);

	const dateOptions: DateTimeFormatOptions = {
		day: '2-digit',
		month: '2-digit'
	}
	const fileName = reportDates.length > 1 ? reportDates.map(date => new Intl.DateTimeFormat('en-US', dateOptions).format(date.startDate)).join(', ') : `${new Intl.DateTimeFormat('en-US', dateOptions).format(reportDates[0].startDate)} - ${new Intl.DateTimeFormat('en-US', dateOptions).format(reportDates[0].endDate)}`;

	if (exportType === 'csv') {
		const newFileName = checkFileResolution({ typeWithoutDot: 'csv', fileName });
		csvExporter(newFileName)(outputCSV);
	} else if (exportType === 'pdf') {
		const newFileName = checkFileResolution({ typeWithoutDot: 'pdf', fileName });
		exportPDF({
			fileName: newFileName,
			data: outputPDF,
		});
	} else if (exportType === 'xlsx') {
		const worksheet = utils.json_to_sheet(outputXLSX);
		const workbook = utils.book_new();
		utils.book_append_sheet(workbook, worksheet, "summary");
		writeFileXLSX(workbook, "ReportSummary.xlsx");
	}
};

const getCsvAndXlsxRecords = async (persons) => {
	const records = await getPermissionsForUsers(persons);
	const exportData = [];

	for (const record of records) {
		const displayName = record.displayName;

		for (const device of record.devices) {
			const deviceName = device.name || '';
			const permissionLevel = device.readOnly ? 'Read Only' : 'Full';
			let assignmentType = '';

			if (device.tag) assignmentType += `Tag: ${device.tag}\n`;
			if (device.device) assignmentType += `Direct assignment`;

			exportData.push({
				displayName,
				deviceName,
				assignmentType,
				permissionLevel
			})
		}
	}

	return exportData;
}

export const exportPermissionsPdf = async (persons) => {
	const records = await getPermissionsForUsers(persons);

	const exportData = [];

	for (const record of records) {
		const data = {};

		data['head'] = [[record.displayName, 'Device', 'Assignment Type', 'Permission Level']];
		data['body'] = [];

		for (const device of record.devices) {
			const deviceName = device.name || '';
			const permissionLevel = device.readOnly ? 'Read Only' : 'Full';
			let assignmentType = '';

			if (device.tag) assignmentType += `Tag: ${device.tag}\n`;
			if (device.device) assignmentType += `Direct assignment`;

			data['body'].push(['', deviceName, assignmentType, permissionLevel]);
		}

		exportData.push(data);
	}

	exportPDF({
		fileName: 'Report',
		data: exportData
	})
};

export const exportPermissionsXlsx = async (persons) => {
	const exportData = await getCsvAndXlsxRecords(persons);

	const worksheet = utils.json_to_sheet(exportData);
	const workbook = utils.book_new();

	utils.book_append_sheet(workbook, worksheet, "report");
	writeFileXLSX(workbook, "Report.xlsx");
};

export const exportPermissionsCsv = async (persons) => {
	const exportData = await getCsvAndXlsxRecords(persons);

	csvExporter('Report.csv')([['User name', 'Device', 'Assignment type', 'Permission'], ...exportData.map(data => {
		return [data.displayName, data.deviceName, data.assignmentType, data.permissionLevel]
	})])
};

const getTripByIds = async (ids, filters, devices) => {
	const peopleFilter = filters.person ? Object.keys(filters.person) : [];
	const labelsFilter = filters.labels ? Object.keys(filters.labels) : [];
	const chunkSize = 10;

	const chunks = [];
	for (let i = 0; i < ids.length; i += chunkSize) {
		chunks.push(ids.slice(i, i + chunkSize));
	}

	const promises = chunks.map(async chunk => {
		let query = await clientStore().collection('trips')
			.where('__name__', 'in', chunk)
			.get();

		return query.docs.map(doc => ({ id: doc.id, ...doc.data() }) as iTravelTripData);
	});

	const results = (await Promise.all(promises)).flat();

	const filteredResults = results.filter(doc => {
		const hasPersonId = doc.personId && peopleFilter.includes(doc.personId);
		const hasLabel = doc.label && labelsFilter.includes(doc.label);

		if (peopleFilter.length === 0 && labelsFilter.length === 0) {
			return true;
		}

		return (peopleFilter.length && hasPersonId) || (labelsFilter.length && hasLabel);
	});

	const deviceGroups = filteredResults.reduce((groups, trip) => {
		const device = trip.device || '';

		if (!groups[device]) {
			groups[device] = [];
		}
		groups[device].push(trip);

		return groups;
	}, {});

	const groupsMappedEntries = Object.entries(deviceGroups).map(([deviceId, data]) => {
		const device = devices.getIn([deviceId]);
		const displayName = device ? device.name : '';

		return [
			deviceId,
			displayName,
			data
		];
	})

	groupsMappedEntries.sort((a, b) => (a[1] as string).localeCompare(b[1] as string));

	const sortedByDeviceGroups = {};
	for (const [deviceId, , data] of groupsMappedEntries) {
		sortedByDeviceGroups[deviceId] = data;
	}

	const sortedGroups = Object.entries(sortedByDeviceGroups).map(([device, deviceTrips]) => {
		return (deviceTrips as iTrip[]).sort((a, b) => {
			const aTime = (a.startDate.seconds ?? 0) as number;
			const bTime = (b.startDate.seconds ?? 0) as number;

			return aTime - bTime;
		});
	});

	return sortedGroups.flat();
}

const getFullTravelReportData = ({reportData, people}, records) => {
	const data = [];

	for (const record of records) {
		const device = reportData.get(record.device);

		if (!device) continue;

		const person = record.personId ? people[record.personId] : { displayName: ''};
		const startDate = moment(record.startDate.seconds * 1000);
		const endDate = moment(record.endDate.seconds * 1000);
		const start = countDateWithUserAndTimezoneOffset(startDate, device.timezone);
		const end = countDateWithUserAndTimezoneOffset(endDate, device.timezone);

		const report = {
			head: [[device.name, start.format('M/D/YY'), '', `${start.format('h:mmA')} - ${record.startAddress}`]],
			body: [
				['', 'Duration:', friendlyDiff(endDate, startDate)],
				['', 'Idle:', minutesToFriendly(record.idleMinutes)],
				['', 'Stopped:', minutesToFriendly(record.stopMinutes)],
				['', 'Safety:', record.safetyPercent ? record.safetyPercent + '%' : 'N/A'],
				['', 'Person', person.displayName],
				['', 'Avg. Speed:', `${record.averageSpeed ? friendlySpeed(record.averageSpeed, device.units) : 'N/A'}`],
				['', 'Max Speed', `${record.maxSpeed ? friendlySpeed(record.maxSpeed, device.units) : 'N/A'}`],
				['', 'Distance', friendlyDist(record.miles, device.units)],
				['', 'Consumption:', friendlyMilesPer(record.mpg, device.units)],
				['', 'Label', record.label || ''],
			],
			foot: [['', '', '', `${end.format('h:mmA')} - ${record.endAddress}`]],
		}

		data.push(report);
	}

	return data;
}

const getFullTravelReportDataXlsx = ({reportData, people}, records) => {
	const data = [];

	for (const record of records) {
		const device = reportData.get(record.device);

		if (!device) continue;

		const person = record.personId ? people[record.personId] : { displayName: ''};
		const startDate = moment(record.startDate.seconds * 1000);
		const endDate = moment(record.endDate.seconds * 1000);
		const start = countDateWithUserAndTimezoneOffset(startDate, device.timezone);
		const end = countDateWithUserAndTimezoneOffset(endDate, device.timezone);

		const report = {
			deviceName: device.name,
			duration: friendlyDiff(endDate, startDate),
			label: record.label || 'N/A',
			person: person.displayName || 'N/A',
			startTime: start.format('M/D/YY') + ' ' + start.format('h:mmA'),
			startAddress: record.startAddress,
			endTime: end.format('M/D/YY') + ' ' + end.format('h:mmA'),
			endAddress: record.endAddress,
			idleMinutes: minutesToFriendly(record.idleMinutes),
			stoppedMinutes: minutesToFriendly(record.stopMinutes),
			maxSpeed: record.maxSpeed ? friendlySpeed(record.maxSpeed, device.units) : 'N/A',
			averageSpeed: record.averageSpeed ? friendlySpeed(record.averageSpeed, device.units) : 'N/A',
			milesTraveled: friendlyDist(record.miles, device.units),
			mpg: friendlyMilesPer(record.mpg, device.units),
			safetyPercent: record.safetyPercent ? record.safetyPercent + '%' : 'N/A'
		};

		data.push(report);
	}

	return data;
}

const getFullTravelReportDataCsv = ({reportData, people}, records) => {
	const data = [];

	for (const record of records) {
		const device = reportData.get(record.device);

		if (!device) continue;

		const person = record.personId ? people[record.personId] : { displayName: ''};
		const startDate = moment(record.startDate.seconds * 1000);
		const endDate = moment(record.endDate.seconds * 1000);
		const start = countDateWithUserAndTimezoneOffset(startDate, device.timezone);
		const end = countDateWithUserAndTimezoneOffset(endDate, device.timezone);

		const report = {
			'Device Name': device.name,
			'Trip Minutes': friendlyDiff(endDate, startDate),
			'Person': person.displayName || 'N/A',
			'Label': record.label || 'N/A',
			'Start Time': start.format('M/D/YY') + ' ' + start.format('h:mmA'),
			'Start Address': record.startAddress,
			'End Time': end.format('M/D/YY') + ' ' + end.format('h:mmA'),
			'End Address': record.endAddress,
			'Idle Minutes': minutesToFriendly(record.idleMinutes),
			'Stopped Minutes': minutesToFriendly(record.stopMinutes),
			'Max Speed': record.maxSpeed ? friendlySpeed(record.maxSpeed, device.units) : 'N/A',
			'Average Speed': record.averageSpeed ? friendlySpeed(record.averageSpeed, device.units) : 'N/A',
			'Miles Traveled': friendlyDist(record.miles, device.units),
			'MPG': friendlyMilesPer(record.mpg, device.units),
			'Safety Percent': record.safetyPercent ? record.safetyPercent + '%' : 'N/A'
		};

		data.push(report);
	}

	return data;
}

export const exportTravelReport = async ({reportData, report, people, travelTripIds, formats}) => {
	const records = await getTripByIds(travelTripIds, report.filters, reportData);

	if (formats.exportPdf) {
		const data = await getFullTravelReportData({reportData, people}, records);

		exportPDF({
			fileName: 'Report',
			data
		})
	}

	if (formats.exportXlsx) {
		const data = await getFullTravelReportDataXlsx({reportData, people}, records);

		const worksheet = utils.json_to_sheet(data);
		const workbook = utils.book_new();

		utils.book_append_sheet(workbook, worksheet, "report");
		writeFileXLSX(workbook, "Report.xlsx");
	}

	if (formats.exportCsv) {
		const data = await getFullTravelReportDataCsv({reportData, people}, records);

		csvExporter('Report.csv')([Object.keys(data[0]), ...data.map(item => Object.values(item))]);
	}
}

export const exportActivityReport = async ({formats, displayRecords}) => {
	if (formats.exportPdf) {
		const data = [];
		const pdf = new jsPDF();
		const pageWidth = pdf.internal.pageSize.width;
		const margin = 20;
		const width = (pageWidth - margin) / 2;

		for (const record of displayRecords) {
			data.push({
				head: [['Device Name', 'Last Report']],
				body: [[record.deviceName, record.ago]],
				columnStyles: {
					0: {cellWidth: width},
					1: {cellWidth: width}
				}
			})
		}

		data.forEach(table => {
			autoTable(pdf, table);
		});

		pdf.save('Report.pdf');
	}

	if (formats.exportXlsx) {
		const data = displayRecords.map(record => {
			return {
				'Device Name': record.deviceName,
				'Last Report': record.ago
			}
		});

		const worksheet = utils.json_to_sheet(data);
		const workbook = utils.book_new();

		utils.book_append_sheet(workbook, worksheet, "report");
		writeFileXLSX(workbook, "Report.xlsx");
	}

	if (formats.exportCsv) {
		const data = displayRecords.map(record => {
			return {
				'Device Name': record.deviceName,
				'Last Report': record.ago
			}
		});

		csvExporter('Report.csv')([['Device Name', 'Last Report'], ...data.map(item => Object.values(item))]);
	}
}
