import React, {Fragment, useState, useEffect, useRef} from "react";
import {useDispatch} from 'react-redux';
import {useRedux} from '../../../../states/redux-state';
import {groupBy, keys} from 'ramda';
import {VisReportData} from "../vis-report-data";
import NewDeviceReportSections from "../report-table/NewDeviceReportSections";
import {
    hideReportTrips,
    removeReportTrips,
} from '../../../../stores/reducers/devicesTripsPoints/AC';
import {
    Actions as ReportActions,
} from '../../../../stores/reducers/report-reducers';
import { checkingSelectedFiltersCount } from '../ReportPageFilters';
import {usePrevious} from '../../../../shared/hooks/usePrevius';
import {
    timeClockRecords,
    vals
} from '../../../../shared/helpers';

import {
    ItemType,
} from '../../../../shared/interfaces';
import { store } from '../../../../stores/store';
import RenderPermissionReportData from "../report-table/RenderPermissionReportData";
import {getPermissionsForUsers} from "../report-utils";
import Loader from "../../Billing/Other/Loader";

const TripTravelTablePure = ({showAllRoute, setShowAllRoute, showReportTrip, hideReportTrip, isTimeClock, ...props} ) => {
    const state = store.getState();
    const dispatch = useDispatch();
    const {showVisibleReportData} = props;
    const reportType = useRedux(s => s.report.details.reportType);
    const displayRecordsRaw = useRedux(s => s.report.displayRecords) || {};
    const deviceOrPerson = useRedux(s => s.report.details.devicePerson);
    const tripPoints = useRedux(s => s.devicesTripsPoints);
    const devicesDetails = state.devicesData.devicesDetails;
    const people = useRedux(s => s.general.people);
    const currentFilters = useRedux(s => s.report.details.filters);
    const records = groupBy(x => deviceOrPerson === ItemType.device ? x.device : x.personId)(vals(displayRecordsRaw));
    const permissionRecords = Array.isArray(displayRecordsRaw) ? displayRecordsRaw : [];
    const permissionPersons = useRedux(s => s.report.permissionPersons);
    const [formattedPermissionRecords, setFormattedPermissionRecords] = useState([]);
    const [page, setPage] = useState(1);
    const limit = 10;
    const pages = Math.ceil(permissionPersons.length / limit);
    const [isLoading, setIsLoading] = useState(false);
    // const [ titles, setTitles] = React.useState({});

    const dateSorting = (previous, next) => {
        if (previous?.startDate?.toDate().valueOf() < next?.startDate?.toDate().valueOf()) return -1;
        else if (previous?.startDate?.toDate().valueOf() === next?.startDate?.toDate().valueOf()) return 0;
        else return 1;
    }

    const sortTripsByStartDate = (records) => {
        const sortedRecords = {};

        Object.entries(records).forEach(([key, array]) => {
            // @ts-ignore
            sortedRecords[key] = array.sort((a, b) => dateSorting(a, b))
        });

        return sortedRecords;
    };

    const countOfSelectedFilters = checkingSelectedFiltersCount({currentFilters});

    const showAllTrips = (records, k) => {
        records[k].forEach((item) => {
            setShowAllRoute(!showAllRoute);
            showReportTrip(item, tripPoints)
        })
    }

    React.useEffect(() => () => {
        dispatch(removeReportTrips());
        setShowAllRoute(false);
    }, []);

    const prevReportType = usePrevious(reportType);
    React.useEffect(() => {

        return () => {
            if (countOfSelectedFilters['alerts'] > 0 && reportType === 'travel') {
                dispatch(ReportActions.RESET_REPORT_ALERTS_FILTER());
            }
        }

        // TODO: is never called => prevReportType is undefined
        if (reportType !== 'travel' && prevReportType === 'travel') {
            dispatch(hideReportTrips());
        }
    }, [reportType]);

    React.useEffect(() => {
        // setTitles(getTitles(records, deviceOrPerson, devicesDetails, people));
        // dispatch(ReportActions.SET_TRIP_RECORDS(records));
        props.getRecords(records)
    }, [deviceOrPerson]);

    // React.useEffect(() => {
    //     props.getNames(titles)
    // }, [titles]);

    const hideAllReportTripsFor = (records, k) => {
        records[k].forEach(item => hideReportTrip(item));
    }

    const flattedRecords = Object.values(records).flat();
    const recordsWithDeviceNames = flattedRecords.map(record => {
        const displayName = deviceOrPerson === ItemType.device
            //@ts-ignore
            ? devicesDetails.getIn([record.device, 'name']) || 'Unknown Device'
            //@ts-ignore
            : (people[record.device] || {}).displayName || 'Not Assigned';
        return {
            //@ts-ignore
            ...record,
            deviceName: displayName,
        }
    });



    useEffect(() => {
        if (reportType === 'permissions') {
            setFormattedPermissionRecords(permissionRecords);
        }
    }, []);

    useEffect(() => {
        const fetchPermissions = async () => {
            setIsLoading(true);

            const scrollableElement = document.querySelector<HTMLElement>('.ps__rail-y');

            if (scrollableElement){
                scrollableElement.style.top = '0px';
            }

            if (page > 0 && page <= pages){
                const start = (page - 1) * limit;
                const end = start + limit;

                const records = await getPermissionsForUsers(permissionPersons.slice(start, end));

                setFormattedPermissionRecords(records);
            }

            setIsLoading(false);
        };

        fetchPermissions();
    }, [page]);

    const permissionsNextPage = () => {
        if (page < pages) setPage(page + 1);
    };

    const permissionsPreviousPage = () => {
        if (page > 1) setPage(page - 1);
    }

    const sortedRecords = sortTripsByStartDate(records);

    return (
        <>
            <VisReportData showVisibleReportData={showVisibleReportData} type={reportType as any}/>
            {reportType === 'permissions' ? (
                isLoading ?
                  <Loader /> :
                  (formattedPermissionRecords.length > 0 ? (
                  <div className="permission-report-container">
                      {formattedPermissionRecords.map((permission, index) => (
                        <RenderPermissionReportData
                          displayName={permission.displayName}
                          devices={permission.devices}
                          key={index}
                        />
                      ))}
                      {pages > 1 && <div className="permissions-pagination">
                          {page > 1 && <button onClick={permissionsPreviousPage}>Previous</button>}
                          {page < pages && <button onClick={permissionsNextPage}>Next</button>}
                      </div>}
                  </div>
                ) : (<h4 className="permission-report-tag-title">No permissions for these users</h4> ))
            ) : (
                !isTimeClock ? (
                    keys(sortedRecords).map((k, index) => {
                        const displayName = deviceOrPerson === ItemType.device
                            ? devicesDetails.getIn([k, 'name']) || 'Unknown Device'
                            : (people[k] || {}).displayName || 'Not Assigned';
                        return (
                            <Fragment key={index}>
                                <div className="report-device-section" style={{marginBottom: 10}}>
                                    <NewDeviceReportSections
                                        displayName={displayName}
                                        records={sortedRecords[k] as any}
                                        getReportData={props.getReportData}
                                        showAllRoute={showAllRoute}
                                        showAllTrips={() => showAllTrips(sortedRecords, k)}
                                        showTrip={showReportTrip}
                                        hideAllTrips={() => hideAllReportTripsFor(sortedRecords, k)}
                                    />
                                </div>
                            </Fragment>
                        );
                    })
                ) : (
                    <div className="report-device-section" style={{marginBottom: 10}}>
                        <NewDeviceReportSections
                            records={timeClockRecords(recordsWithDeviceNames)}
                            getReportData={props.getReportData}
                            showAllRoute={showAllRoute}
                            showTrip={showReportTrip}
                            isTimeClock={isTimeClock}
                        />
                    </div>
                )
            )}
        </>
    );
}

export default React.memo(TripTravelTablePure);
