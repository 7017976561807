import React, {useEffect, useState} from 'react';
import C, { UserCan } from '../../shared/constants';
import {faChevronDown, faChevronRight} from '@fortawesome/fontawesome-free-solid';
import {Fa} from './fa';
import {changePermission, usersHavePermission} from "../../shared/db/people-db";
import {localStorage} from "../../shared/storage";
import {useRedux} from "../../states/redux-state";

// #region styles
const main: React.CSSProperties = {
    width: '100%',
    margin: '3px 3px 6px',
};
const head: React.CSSProperties = {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '3px 8px',
    backgroundColor: C.darkGray,
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    cursor: 'pointer',
};
const body: React.CSSProperties = {
    width: '100%',
    padding: '5px 10px',
    border: `1px solid ${C.lightGray}`,
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 4,
};
const toggle: React.CSSProperties = {
    color: '#fff',
    marginRight: 10,
    cursor: 'pointer',
};
const title: React.CSSProperties = {
    flex: '1 1 0%',
    color: '#fff',
    fontWeight: 'bold',
};
const table: React.CSSProperties = {
    width: '200px'
};
const key: React.CSSProperties = {
    fontWeight: 'bold',
    marginBottom: 15
};
const checkboxLabel: React.CSSProperties = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: '5px 0'
};
// #endregion

export const AllUsersFeatures = () => {
    const user = useRedux((s) => s.auth.user);
    const [collapsed, setCollapsed] = useState(true);
    const [reports, setReports] = useState(false);
    const [tags, setTags] = useState(false);
    const [tappedIn, setTappedIn] = useState(false);
    const [filterReportsByPeople, setFilterReportsByPeople] = useState<boolean>(false);
    const [ maintenance, setMaintenance ] = useState(false);
    const [dvir, setDvir] = useState<boolean>(false);

    const toggleCollapsed = () => setCollapsed(!collapsed);

    useEffect(() => {
        const clientId = localStorage.get('active-client');
        usersHavePermission(clientId, 'see-tags').then((res) => setTags(res));
        usersHavePermission(clientId, 'see-all-reports').then((res) => setReports(res));
        usersHavePermission(clientId, 'see-tapped-people').then((res) => setTappedIn(res));
        usersHavePermission(clientId, 'see-maintenance').then(res => setMaintenance(res));
        usersHavePermission(clientId, UserCan.FILTER_REPORTS_BY_PEOPLE).then(res => setFilterReportsByPeople(res));
        usersHavePermission(clientId, 'see-dvir').then(res => setDvir(res));
    }, []);

    return (
        <div style={{...main}}>
            <div style={{...head}} onClick={toggleCollapsed}>
                <Fa icon={ collapsed ? faChevronRight : faChevronDown } style={{...toggle}} />
                <span style={{...title}}>All Users Features</span>
            </div>
            <div style={{...body}}>
                {!collapsed && (
                    <div>
                        <h6 style={{...key}}>Show these features for all users:</h6>
                        <div style={{...table}}>
                            <span style={{...checkboxLabel}}>
                                Scheduled Reports
                                <input
                                  type="checkbox"
                                  name='Scheduled reports'
                                  checked={reports}
                                  onChange={() => setReports(!reports)}
                                  onClick={changePermission(user, 'see-all-reports', !reports)}
                                />
                            </span>
                            <span style={{...checkboxLabel}}>
                                Maintenance
                                <input
                                  type="checkbox"
                                  name='Maintenance'
                                  checked={maintenance}
                                  onChange={() => setMaintenance(!maintenance)}
                                  onClick={changePermission(user, 'see-maintenance', !maintenance)}
                                />
                            </span>
                            <span style={{...checkboxLabel}}>
                                Tags
                                <input
                                  type="checkbox"
                                  name='Tags'
                                  checked={tags}
                                  onChange={() => setTags(!tags)}
                                  onClick={changePermission(user, 'see-tags', !tags)}
                                />
                            </span>
                            <span style={{...checkboxLabel}}>
                                Tapped In
                                <input
                                  type="checkbox"
                                  name='Tapped In'
                                  checked={tappedIn}
                                  onChange={() => setTappedIn(!tappedIn)}
                                  onClick={changePermission(user, 'see-tapped-people', !tappedIn)}
                                />
                            </span>
                            <span style={{...checkboxLabel}}>
                                Filter by people
                                <input
                                  type="checkbox"
                                  name='Filter by people'
                                  checked={filterReportsByPeople}
                                  onChange={() => setFilterReportsByPeople(!filterReportsByPeople)}
                                  onClick={changePermission(user, UserCan.FILTER_REPORTS_BY_PEOPLE, !filterReportsByPeople)}
                                />
                            </span>
                            <span style={{...checkboxLabel}}>
                                DVIR
                                <input
                                  type="checkbox"
                                  name='DVIR'
                                  checked={dvir}
                                  onChange={() => setDvir(!dvir)}
                                  onClick={changePermission(user, 'see-dvir', !dvir)}
                                />
                            </span>
                        </div>
                    </div>)
                }
            </div>
        </div>
    );
};
