import React, {useState} from 'react';
import { connect, useDispatch } from 'react-redux';
import { path, pipe } from 'ramda';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import '../ReportPageFilters/ReportPageFilters.scss';
import { iFullStoreState } from '../../../../shared/interfaces';
import { Button, ButtonSize, ButtonStyles } from '../../../Button';
import { DaysChooser, RangeDateChooser } from '.././DateChoosers';
import { FilterType, IProps, IPropsFromStore, IState } from '../ReportPageFilters/ReportPageFilters.interfaces';
import {
	alertsSelector,
	deviceSelector,
	labelsSelector,
	personSelector,
	tagsSelector,
	typeSelector,
} from '.././ReportSelectors';
import C, {SECONDS_IN_DAY} from '../../../../shared/constants';
import { Collapsible } from '../../../general/Collapsible';
import { GMapModalAC } from '../../../../stores/reducers/gMapModal/AC';
import { Actions as ReportActions } from '../../../../stores/reducers/report-reducers';
import { ReportPageFilterSection } from '.././ReportPageFilterSection';
import { isMobile } from '../../../../shared/helpers';
import { lEmpty } from '../../../../shared/helpers';
import { BaseComponent } from '../../../../shared/BaseComponent';
import { ReactComponent as BookmarkSvg } from '../../../../assets/svg/bookmarks.svg';
import { ReactComponent as CalendarToday } from '../../../../assets/svg/calendar_today.svg';
import { ReactComponent as DevicesSvg } from '../../../../assets/svg/devices.svg';
import { ReactComponent as LayerSvg } from '../../../../assets/svg/layers.svg';
import { ReactComponent as PeopleSvg } from '../../../../assets/svg/people.svg';
import { ReactComponent as WarningAmberSvg } from '../../../../assets/svg/warning_amber.svg';
import { useRedux } from '../../../../states/redux-state';
import { Actions as GeneralActions } from '../../../../stores/reducers/general-reducers';
import instance from '../../../../api/instance';
import { localStorage } from '../../../../shared/storage';
import { openDashboard } from '../../../../stores/reducers/dashboardInfo/AC';
import LongReportModal from '../../../LongReportModal';

export const checkingSelectedFiltersCount = ({ currentFilters }) => {
	const filtersItemCount = Object.entries(currentFilters).reduce((acc, [key, value]) => ({
		...acc,
		[key]: Object.keys(value).length
	}), {});

	return filtersItemCount
}

const mapStateToProps = (state: iFullStoreState): IPropsFromStore => ({
	visDevices: deviceSelector(state),
	visTypes: typeSelector(state),
	visPeople: personSelector(state),
	visTags: tagsSelector(state),
	visAlerts: alertsSelector(state),
	visLabels: labelsSelector(state),
	dates: state.report.details.dates,
	reportKey: state.report.details.searchKey,
	dateError: state.report.dateError,
	labels: state.general.tripLabels,
	devicesDetails: state.devicesData.devicesDetails,
	people: state.general.people,
	tags: state.general.tags,
	filterStrs: state.report.filterFilters,
	currentFilters: state.report.details.filters,
	dataFromSelectedArea: state.report.details.dataFromSelectedArea,
});

class ReportPageFiltersDatepicker extends BaseComponent<IProps, IState> {

	state = {
		multiDate: this.props.dates.length !== 1,
		deviceFilter: '',
		startKey: this.props.reportKey,
		startAndEndDateChange: {},
	}


	__countStartEndDateSafe = () => {
		const { dates } = this.props;
		if (dates.length === 0) return null;

		let startDate, endDate;
		if (this.state.multiDate) {
			startDate = moment.min(dates.map(d => d.startDate));
			endDate = moment.max(dates.map(d => d.endDate));
		} else {
			startDate = dates[0].startDate;
			endDate = dates[0].endDate;
		}

		return { startDate, endDate };
	}

	handleClearAll = () => {
		this.props.dispatch(ReportActions.RESET_ALL_FILTERS());
		this.props.dispatch(ReportActions.RESET_DATES());
		this.setState({ multiDate: true });
	};

	debugRender = () => {
		const { visDevices, dispatch, visPeople, visTypes, dateError, visTags, visAlerts, visLabels, reportKey, currentFilters, devicesDetails, people, tags, labels, dates } = this.props;

		const { multiDate } = this.state;

		const dateDisplay = this.__countStartEndDateSafe();

		const countOfSelectedFilters = checkingSelectedFiltersCount({ currentFilters });

		return (
			<div className="report-filter-wraper">
				<PerfectScrollbar>
					{/* <ModalHeader title="Report filters" /> */}
					<div className="report-filters" style={{ display: 'flex', flexWrap: 'nowrap', flexDirection: 'column' }}>
						<div className="report-filters-header">
							<h3>Report filters in the selected area</h3>
						</div>
						{/* Device Filter Selector */}
						<ReportPageFilterSection
							disabled={!lEmpty(currentFilters[FilterType.TAGS])}
							Icon={DevicesSvg}
							title="Devices"
							visible={visDevices}
							filterType={FilterType.DEVICES}
							countOfSelectedFilters={countOfSelectedFilters}
						/>

						{/* Person filter Selector */}
						<ReportPageFilterSection
							disabled={!lEmpty(currentFilters[FilterType.TAGS])}
							Icon={PeopleSvg}
							visible={visPeople}
							title="People"
							filterType={FilterType.PEOPLE}
							countOfSelectedFilters={countOfSelectedFilters}
						/>

						{/* Tag Filter Selector */}
						<ReportPageFilterSection
							Icon={LayerSvg}
							disabled={!lEmpty(currentFilters[FilterType.PEOPLE]) || !lEmpty(currentFilters[FilterType.DEVICES])}
							title="Tags"
							visible={visTags}
							filterType={FilterType.TAGS}
							countOfSelectedFilters={countOfSelectedFilters}
						/>

						{/* Alerts filter selector */}
						<ReportPageFilterSection
							Icon={WarningAmberSvg}
							title="Alerts"
							RedirectToStatic
							visible={visAlerts}
							filterType={FilterType.ALERTS}
							countOfSelectedFilters={countOfSelectedFilters}
						/>

						{/* Values filter selector */}
						{/* <ReportPageFilterSection
                        Icon={BookmarkSvg}
                        title="Values"
                        hasSelectionsGrid
                        filterType={FilterType.VALUES}
                    /> */}

						{/* Labels */}
						<ReportPageFilterSection
							Icon={BookmarkSvg}
							title="Labels"
							visible={visLabels}
							filterType={FilterType.LABELS}
							countOfSelectedFilters={countOfSelectedFilters}
						/>
						<div className="date-choser-filter-section report-filter-section">
							<Collapsible
								isCollapsed={false}
								className="report-filter"
								headerClassName="report-filter-header"
								bodyClassName="report-filter-body"
								scrollIntoView
								header={
									<div className="report-filter-labels-header">
										<i className="report-filter-icon"><CalendarToday /></i>
										<span>
											Date/Range {
												dateDisplay && dateDisplay.startDate && dateDisplay.endDate && (
													<span className="date weight-normal">
														({dateDisplay.startDate.format("MM/DD/YYYY")} - {dateDisplay.endDate.format("MM/DD/YYYY")})
													</span>
												)
											}
										</span>
									</div>
								}
							>
								<div>
									<div className={`date-filter-tabs`}>
										<span
											onClick={() => { dispatch(ReportActions.RESET_DATES()); this.setState({ multiDate: true }) }}
											className={`date-tab ${multiDate ? "active" : ""}`}>
											Date
										</span>
										<span
											onClick={() => { dispatch(ReportActions.RESET_DATES()); this.setState({ multiDate: false }) }}
											className={`date-tab ${!multiDate ? "active" : ""}`}>
											Range
										</span>
									</div>
									{!dateError ? <div style={{ height: 3 }} /> : <div style={{ marginTop: 8, color: 'darkred', textAlign: 'center', marginBottom: -25, paddingBottom: 8 }}>{dateError}</div>}

									<div style={{ marginTop: 15 }}>
										{multiDate
											? <DaysChooser
												dates={dates}
												remove={pipe(ReportActions.REMOVE_DATE_GROUP_IN_DATES, dispatch)}
												dispatch={dispatch}
												startDateChange={el => this.setState(function (prevState) {
													return { startAndEndDateChange: { ...prevState.startAndEndDateChange, startOfDateChange: el } }
												})}
												endDateChange={el => this.setState(function (prevState) {
													return { startAndEndDateChange: { ...prevState.startAndEndDateChange, endOfDateChange: el } }
												})}
											/>
											: <RangeDateChooser
												style={dateError ? { borderColor: 'darkred' } : {}}
												initialStart={path([0, 'startDate'])(dates)}
												initialEnd={path([0, 'endDate'])(dates)}
												calendarHideable={false}
												calendarTop
												startDateChange={el => this.setState(function (prevState) {
													return { startAndEndDateChange: { ...prevState.startAndEndDateChange, startOfDateChange: el } }
												})}
												endDateChange={el => this.setState(function (prevState) {
													return { startAndEndDateChange: { ...prevState.startAndEndDateChange, endOfDateChange: el } }
												})}
											/>
										}
									</div>
								</div>
							</Collapsible>
						</div>
					</div>
				</PerfectScrollbar>
				<FiltersActions handleClearAll={this.handleClearAll} multiDate={multiDate} startAndEndDateChange={this.state.startAndEndDateChange} dataFromSelectedArea={this.props.dataFromSelectedArea} dates={dates} filters={currentFilters}/>
			</div>
		);
	}
}
export default connect(mapStateToProps)(ReportPageFiltersDatepicker);

const FiltersActions = (props) => {
	const dispatch = useDispatch();
	const history = useHistory();
	const [isLongReport, setIsLongReport] = useState(false);
	const [popupHandlers, setPopupHandlers] = useState<{
		handleProceed: (() => void) | null;
		handleClose: (() => void) | null;
	}>({ handleProceed: null, handleClose: null });

	const userFromHttp = localStorage.get('login-init-user');
	const firebase_id = userFromHttp?.uid;

	const {
		reportType,
	} = useRedux(s => ({
		reportType: s.report.details.reportTypeFromRedux,
	}));

	const showPopup = () => {
		return new Promise((resolve) => {
			setIsLongReport(true);

			const handlePopupProceed = () => {
				setIsLongReport(false);
				resolve(true);
			}

			const handlePopupClose = () => {
				setIsLongReport(false);
				resolve(false);
			}

			setPopupHandlers({ handleProceed: handlePopupProceed, handleClose: handlePopupClose });
		})
	}

	const request = async (startDate, endDate) => {
		const URL = `/api/location/search?firebaseId=${firebase_id}`;

		const body = {
			filters: {
				date: {
					startDate: startDate ? startDate : new Date(),
					endDate: endDate ? endDate : new Date(),
				}
			},
			fence: {
				...props.dataFromSelectedArea
			},
		}

		const reportTypeFromRedux = reportType ? reportType : 'travel';

		history.push(`/reports/new/${reportTypeFromRedux}`);

		await instance.post(URL, body)
			.then(response => {
				const { data } = response;

				const tripPaths = new Set();

				let uniqueTripPaths;

				if (data?.length > 0) {
					data.forEach(item => {
						const matches = item.firebase_trip_path?.match(/trips\/([^\/]+)/);
						if (matches) {
							tripPaths.add(matches[1]);
						}
					});

					uniqueTripPaths = Array.from(tripPaths).length !== 0 ? Array.from(tripPaths) : ['0'];
				} else {
					uniqueTripPaths = ['0'];
				};

				const unique = {
					uniqueTripPath: uniqueTripPaths,
					isSelectedAreaMode: true,
				}

				dispatch(ReportActions.SET_TRIPS_FROM_SELECTED_AREA(unique));

				return data
			})
			.catch(({ message }) => {
				toast.error(message, { position: toast.POSITION.TOP_RIGHT });
			});
	}

	const _clearRecords = () => {
		dispatch({ type: 'REPORT_SET_DISPLAY_RECORDS', displayRecords: {} });
	}

	const handleClose = () => {
		dispatch(GMapModalAC.hideModal());
	}

	const handleApply = async () => {
		const startDate = props.startAndEndDateChange.startOfDateChange;
		const endDate = props.startAndEndDateChange.endOfDateChange;
		const startDateIsValid = moment.isMoment(startDate) && startDate.isValid();
		const endDateIsValid = moment.isMoment(endDate) && endDate.isValid();

		if(startDateIsValid && endDateIsValid){
			const devicesLength = props.filters.device ? Object.keys(props.filters.device).length : 0;
			const shouldShowPopup = (endDate.diff(startDate, 'seconds') > SECONDS_IN_DAY * 7) || devicesLength >= 25;

			if (shouldShowPopup) {
				const shouldProceed = await showPopup();

				if (!shouldProceed) {
					return;
				}
			}

			request(startDate, endDate);
		}

		_clearRecords();

		if (startDate || endDate) {
			dispatch(ReportActions.SET_START_DATE(startDate));
			dispatch(ReportActions.SET_END_DATE(endDate));
		}

		dispatch(GMapModalAC.hideModal());
		dispatch(GeneralActions.HIDE_SEARCH);
		(isMobile || window['cordova']) && dispatch(openDashboard());
	}

	return (
		<div className="report-filters-actions">
			{isLongReport && <LongReportModal
				onClose={popupHandlers.handleClose}
				onProceed={popupHandlers.handleProceed}
			/>}
			{!(isMobile || window['cordova']) &&
				<div>
					<Button
						styleType={ButtonStyles.GRAY_INACTIVE}
						size={ButtonSize.MD}
						onClick={props.handleClearAll}>
						Clear all
					</Button>
				</div>
			}
			<div className={(isMobile || window['cordova']) ? 'w100' : ''}>
				<Button
					styleType={ButtonStyles.WHITE_GRAY}
					size={(isMobile || window['cordova']) ? ButtonSize.LG : ButtonSize.MD}
					onClick={handleClose}>
					Cancel
				</Button>
				<Button
					style={{ marginLeft: "5px" }}
					styleType={ButtonStyles.ORANGE_WHITE}
					size={(isMobile || window['cordova']) ? ButtonSize.LG : ButtonSize.MD}
					onClick={handleApply}
				>
					Apply
				</Button>
			</div>
		</div>
	)
}