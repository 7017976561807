import React from "react";
import Modal from "../Modal";
import styles from './LongReportModal.module.scss';

const LongReportModal = ({onClose, onProceed}) => {
  return (
    <Modal>
      <div className={styles.longReportModal}>
        <h3>Long Report Detected</h3>
        <p>The date range or number of devices selected may cause a delay in this report to be displayed. For faster results try and select a narrow range of units or dates.</p>
        <h5>Do you wish to continue?</h5>
        <div className={styles.buttons}>
          <button onClick={onProceed}>Yes</button>
          <button onClick={onClose}>No</button>
        </div>
        <button className={styles.closeButton} style={{fontSize: '25px'}} onClick={onClose}>&#x2715;</button>
      </div>
    </Modal>
  )
}

export default LongReportModal;
