import React, {CSSProperties as css, useEffect, useState} from 'react';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { is } from 'immutable';
import { Link } from 'react-router-dom';
import { addIndex, equals, map, pathOr, pipe, splitEvery } from 'ramda';

import C from '../../../shared/constants';
import DashboardBlock from '../../DashboardBlock';
import ModalHeader from '../../menus/modal/modal-header';
import { FormatAddress } from './device-list';
import {
  iDeviceDetails,
  iDevicePing,
  iFullStoreState,
  ItemType,
} from '../../../shared/interfaces';
import {DevicesDetailsContainer} from "../../../stores/reducers/devicesData";
import { FaFav, FaPer } from '../../elements/fa';
import { PersonBox } from '../../general/person-box';
import { fitText } from '../../../shared/fit-text';
import { useFirebase } from '../../../states/firebase-state';
import { useRedux } from '../../../states/redux-state';
import { withSentry } from '../../../shared/hoc/withSentry';
import { setShowedDevices } from '../../../shared/helpers';
import GoogleMap from 'react-google-maps/lib/components/GoogleMap';
import SvgIcon from '../../SvgIcon/SvgIcon';
import {DeviceTile} from "./DeviceTile";

const DevicesPage = () => {
  enum SortOptions {
    ASC = 'asc',
    DESC = 'desc',
    LAST_REPORT_ASC = 'last_report_asc',
    LAST_REPORT_DESC = 'last_report_desc',
  }
  const sortOptions = [
    { value: SortOptions.ASC, label: 'Device Name ASC' },
    { value: SortOptions.DESC, label: 'Device Name DESC' },
    { value: SortOptions.LAST_REPORT_ASC, label: 'Last Report ASC'},
    { value: SortOptions.LAST_REPORT_DESC, label: 'Last Report DESC'},
  ];
  const [sortOption, setSortOption] = useState(sortOptions[0]);

  const handleSortChange = (selectedOption) => setSortOption(selectedOption);

  const devices = useSelector<iFullStoreState, DevicesDetailsContainer>(
    (s) => s.devicesData.devicesDetails,
    (l, r) => is(l, r)
  );

  const devicesArray = devices.valueSeq().toArray();

  const devicesLastPingsRaw = useRedux((s) => s.devicesData.devicesLastPing);

  const compareByNameAscending = (a, b) => {
    let nameA = a.name.toLowerCase(), nameB = b.name.toLowerCase();
    if (nameA < nameB) return -1; // sort string ascending
    return 0; // default return value (no sorting)
  }

  const pageTitle = process.env.REACT_APP_DEVICES_TAB_NAME || 'Devices';

  const compareByNameDescending = (a, b) => {
    let nameA = a.name.toLowerCase(), nameB = b.name.toLowerCase();
    if (nameA < nameB) return 1; // sort string descending
    if (nameA > nameB) return -1; // sort string descending
    return 0; // default return value (no sorting)
  };

  const compareByLastReport = (a, b) => {
    const aLastPing = devicesLastPingsRaw.get(a.id);
    const bLastPing = devicesLastPingsRaw.get(b.id);
    const aTime = aLastPing && aLastPing.time ? aLastPing.time.unix() : null;
    const bTime = bLastPing && bLastPing.time ? bLastPing.time.unix() : null;

    if (sortOption.value === SortOptions.LAST_REPORT_DESC) {
      if (aTime !== null && bTime !== null) return aTime - bTime;
      if (aTime !== null) return -1;
      if (bTime !== null) return 1;
      return 0;
    } else {
      if (aTime !== null && bTime !== null) return bTime - aTime;
      if (aTime !== null) return -1;
      if (bTime !== null) return 1;
      return 0;
    }
  }

  const sortArray = () => {
    let sorted;

    if(sortOption.value === SortOptions.DESC) {
      sorted = devicesArray.sort(compareByNameDescending)
    }
    else if (sortOption.value === SortOptions.ASC) {
      sorted = devicesArray.sort(compareByNameAscending)
    }
    else {
      sorted = devicesArray.sort(compareByLastReport)
    }

    return sorted.map(({ id }) => id);;
  }

  const sortedArray = sortArray();

  const dispatch = useDispatch();

  const devicesLastPings = devicesLastPingsRaw
    .valueSeq()
    .toArray();

  const mapRef = useSelector<
    iFullStoreState,
    React.RefObject<GoogleMap> | null
  >((state) => state.gmap.mapRef());

  let isRenderedMap = useSelector<iFullStoreState, boolean>(
    (state) => state.gmap.isRendered
  );

  const devicesMarker = useRedux((s) => s.devicesData.devicesLastPing)
    .valueSeq()
    .toArray()
    .filter((it) => it !== null);

  useEffect(() => {
    let markerBounds = new google.maps.LatLngBounds();
    devicesMarker.forEach((marker) => {
      markerBounds.extend(
        new google.maps.LatLng(
          marker.coordinates.location.lat,
          marker.coordinates.location.lng
        )
      );
    });
    mapRef?.current?.fitBounds(markerBounds);
  }, []);

  useEffect(() => {
    if (!isRenderedMap) {
      let markerBounds = new google.maps.LatLngBounds();
      devicesMarker.forEach((marker) => {
        markerBounds.extend(
          new google.maps.LatLng(
            marker.coordinates.location.lat,
            marker.coordinates.location.lng
          )
        );
      });
      mapRef?.current?.fitBounds(markerBounds);
    }
    setShowedDevices(devicesLastPings, dispatch);
  }, [devicesLastPings, isRenderedMap]);

  console.log('rendering devices')
  return (
    <DashboardBlock>
      <ModalHeader
          title={pageTitle}
          showSelect={true}
          options={sortOptions}
          option={sortOption}
          handleChange={handleSortChange}
          selectDescription='Sort by:'
      />

      <div
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          alignContent: 'space-between',
          flexDirection: 'row',
        }}
      >
        {pipe(
          map((deviceId) => <DeviceTile key={deviceId} deviceId={deviceId} />),
          splitEvery(2),
          addIndex(map)((g, idx) => (
            <div key={idx} style={{ display: 'flex', flexWrap: 'wrap' }}>
              {g}
            </div>
          ))
        )(sortedArray)}
      </div>
    </DashboardBlock>
  );
};

export default withSentry(DevicesPage);



// todo: move this to shared path
export const formatWhenLast = (time?: moment.Moment) => {
  if (!time) return false;

  const now = moment();

  const lastActiveDays = now.diff(time, 'days');
  const lastActiveHours = Math.floor(now.diff(time, 'hours'));
  const lastActiveMinutes = now.diff(time, 'minutes');

  let ago = '';

  if (lastActiveDays)
    ago = `${lastActiveDays} Day${lastActiveDays === 1 ? '' : 's'} ago`;
  else if (lastActiveHours)
    ago = `${lastActiveHours} Hour${lastActiveHours === 1 ? '' : 's'} ago`;
  else if (lastActiveMinutes)
    ago = `${lastActiveMinutes} Minute${lastActiveMinutes === 1 ? '' : 's'}`;

  return ago;
};
